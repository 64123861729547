import axios from 'axios';

import config from '@/config';

import type { SocketAttachmentSearchPayload, SocketMessageAttachment } from '@/interfaces/shared/Socket';
import type { UnsafePaginated } from '@/interfaces/shared/UnsafePaginated';

export default (payload: SocketAttachmentSearchPayload):
Promise<UnsafePaginated<SocketMessageAttachment>> => (
  axios.get<UnsafePaginated<SocketMessageAttachment>>('/chat/attachment/search', {
    baseURL: config.versionedSocketApiUrl(),
    params: payload,
  }).then((res) => res.data)
);
