import { ToastType } from '@/interfaces/shared/Toast';

import type { Toastable, ToastableButton } from '@/interfaces/shared/Toast';

export default class Toast implements Toastable {
  public content: string;

  public type = ToastType.info;

  public ttlSeconds = 5;

  public closable = false;

  public buttons: ToastableButton[] = [];

  constructor(content = '') {
    this.content = content;
  }

  withContent(content: string): Toast {
    this.content = content;

    return this;
  }

  withButton(label: string, action: () => void): Toast {
    this.buttons.push({
      enabled: true,
      label,
      action,
    });

    return this;
  }

  withType(type: ToastType): Toast {
    this.type = type;

    return this;
  }

  withSeconds(ttlSeconds: number): Toast {
    this.ttlSeconds = ttlSeconds;

    return this;
  }

  withInfiniteLifetime(): Toast {
    this.withSeconds(0);

    return this;
  }

  withCloseButton(): Toast {
    this.closable = true;

    return this;
  }

  withoutCloseButton(): Toast {
    this.closable = false;

    return this;
  }
}
