import adaptAttachment from '@/adapter/attachment';
import {
  MessageType,
  SystemMessageType,
} from '@/interfaces/chat/Message';

import type {
  Message,
  MessageAttachment,
  MessageBase,
  MessageFromSystem,
  MessageFromUser,
  SystemMessageData,
  SystemMessageDataUnknown,
} from '@/interfaces/chat/Message';
import type {
  SocketMessage,
  SocketMessageAttachment,
  SocketMessageFromSystem,
  SocketMessageFromUser,
  SocketSystemMessageData,
} from '@/interfaces/shared/Socket';

const adapter = {
  fromSocket: (message?: SocketMessage): Message => {
    const type = adapter.typeFromString(message?.type);

    if (type === MessageType.system) {
      return adapter.systemMessageFromSocket(message);
    }

    return adapter.userMessageFromSocket(message);
  },

  systemMessageFromSocket: (message?: SocketMessageFromSystem): MessageFromSystem => ({
    ...adapter.baseMessageFromSocket(message),
    type: MessageType.system,
    data: adapter.systemDataFromSocket(message?.data),
  }),

  userMessageFromSocket: (message?: SocketMessageFromUser): MessageFromUser => ({
    ...adapter.baseMessageFromSocket(message),
    type: MessageType.user,
    userId: message?.userId || '',
    parentId: message?.parentId || '',
    content: message?.content || '',
    answerCount: message?.answerCount || 0,
    attachments: adapter.attachmentsFromSocket(message?.attachments),
    reactions: message?.reactions || {},
  }),

  baseMessageFromSocket: (message?: SocketMessage): MessageBase => ({
    id: message?.id || '',
    roomId: message?.chatId || '',
    sent: message?.sentTs || 0,
    received: message?.receivedTs || 0,
    sortTs: message?.sortTs || message?.receivedTs || 0,
    sortTsUpdate: message?.sortTs || message?.receivedTs || 0,
    edited: message?.edited || false,
    deleted: message?.deleted || false,
    countsAsNotification: message?.countsAsNotification || false,
  }),

  typeFromString: (type?: string): MessageType => (
    MessageType[<keyof typeof MessageType> type] || MessageType.user
  ),

  attachmentsFromSocket: (attachments?: SocketMessageAttachment[]): MessageAttachment[] => {
    if (!Array.isArray(attachments)) {
      return [];
    }

    return attachments
      .map(adaptAttachment.fromSocket)
      .filter((attachment) => attachment?.id);
  },

  systemDataFromSocket: (data?: SocketSystemMessageData): SystemMessageData => {
    const type = adapter.systemMessageTypeFromString(data?.type);

    if (type === SystemMessageType.usersAddedOrRemoved) {
      return {
        type: SystemMessageType.usersAddedOrRemoved,
        addedUserIds: adapter.systemUserIdsFromSocket(data?.addedUserIds),
        removedUserIds: adapter.systemUserIdsFromSocket(data?.removedUserIds),
      };
    }

    if (type === SystemMessageType.chatAvatarChanged) {
      return {
        type: SystemMessageType.chatAvatarChanged,
        hasAvatar: data?.hasAvatar || false,
      };
    }

    if (type === SystemMessageType.chatTitleChanged) {
      return {
        type: SystemMessageType.chatTitleChanged,
        title: data?.title || '',
      };
    }

    if (type === SystemMessageType.jobApprovalRejected) {
      return {
        type: SystemMessageType.jobApprovalRejected,
        rejectingUserId: data?.rejectingUserId || '',
        jobId: data?.jobId || '',
      };
    }

    if (type === SystemMessageType.jobApprovalRequested) {
      return {
        type: SystemMessageType.jobApprovalRequested,
        requestingUserId: data?.requestingUserId || '',
        jobId: data?.jobId || '',
      };
    }

    if (type === SystemMessageType.newsCommentAdded) {
      return {
        type: SystemMessageType.newsCommentAdded,
        commentingUserId: data?.commentingUserId || '',
        news: data?.news || '',
      };
    }

    return adapter.systemDataUnknown();
  },

  systemMessageTypeFromString: (subtype?: string): SystemMessageType => (
    SystemMessageType[<keyof typeof SystemMessageType> subtype]
      || SystemMessageType.unknown
  ),

  systemUserIdsFromSocket: (userIds?: string[]): string[] => {
    if (Array.isArray(userIds)) {
      return userIds.map((userId) => `${userId}`);
    }

    return [];
  },

  systemDataUnknown: (): SystemMessageDataUnknown => ({
    type: SystemMessageType.unknown,
  }),
};

export default adapter;
