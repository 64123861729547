import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import LoginView from '@/views/LoginView.vue';

import type { Route, RouteConfig } from 'vue-router';

Vue.use(VueRouter);

export enum View {
  home = 'HomeView',
  login = 'LoginView',
  changes = 'ChangesView',
  settings = 'SettingsView',
  chat = 'ChatView',
  infocenter = 'InfocenterView',
  jobList = 'JobListView',
  jobDetails = 'JobDetailsView',
  newsArticle = 'NewsArticleView',
  newsCategory = 'NewsCategoryView',
  newsList = 'NewsListView',
  unknown = 'NotFoundView',
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: {
      name: View.home,
    },
  },
  {
    path: '/login',
    name: View.login,
    component: LoginView,
    meta: {
      disableAuth: true,
      isLoginPage: true,
      isFullHeight: true,
    },
  },
  {
    path: '/home',
    name: View.home,
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta: {
      redirectableAfterLogin: true,
    },
  },
  {
    path: '/changes',
    name: View.changes,
    component: () => import(/* webpackChunkName: "changes" */ '../views/ChangesView.vue'),
    meta: {
      redirectableAfterLogin: true,
    },
  },
  {
    path: '/settings',
    name: View.settings,
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
    meta: {
      redirectableAfterLogin: true,
    },
  },
  {
    path: '/:chatView(chats|groups|teams)/:roomId?/:roomView(files|invite|participants)?',
    name: View.chat,
    component: () => import(/* webpackChunkName: "chat" */ '../views/ChatView.vue'),
    props: true,
    meta: {
      redirectableAfterLogin: true,
      isFullHeight: true,
    },
  },
  {
    path: '/news',
    name: View.newsList,
    component: () => import(/* webpackChunkName: "news-list" */ '../views/NewsListView.vue'),
    props: true,
    meta: {
      redirectableAfterLogin: true,
    },
  },
  {
    path: '/news/category/:topic/:category?',
    name: View.newsCategory,
    component: () => import(/* webpackChunkName: "news-category" */ '../views/NewsCategoryView.vue'),
    props: true,
    meta: {
      redirectableAfterLogin: true,
    },
  },
  {
    path: '/news/article/:news?',
    name: View.newsArticle,
    component: () => import(/* webpackChunkName: "news-article" */ '../views/NewsArticleView.vue'),
    props: true,
    meta: {
      redirectableAfterLogin: true,
    },
  },
  {
    path: '/infocenter/:menuItemHash',
    name: View.infocenter,
    component: () => import(/* webpackChunkName: "infocenter" */ '../views/InfocenterView.vue'),
    props: true,
    meta: {
      redirectableAfterLogin: true,
      isFullHeight: true,
    },
  },
  {
    path: '/jobs',
    name: View.jobList,
    component: () => import(/* webpackChunkName: "job-list" */ '../views/JobListView.vue'),
    props: true,
    meta: {
      redirectableAfterLogin: true,
    },
  },
  {
    path: '/jobs/:jobDetailHash',
    name: View.jobDetails,
    component: () => import(/* webpackChunkName: "job-details" */ '../views/JobDetailsView.vue'),
    props: true,
    meta: {
      redirectableAfterLogin: true,
    },
  },
  {
    path: '*',
    name: View.unknown,
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFoundView.vue'),
    meta: {
      disableAuth: true,
      redirectableAfterLogin: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, pos): { x: number; y: number } | void => {
    if (to.name !== from.name) {
      return { x: 0, y: 0 };
    }

    return pos;
  },
});

let redirectPathAfterLogin: string | undefined;

export const saveRedirectPathAfterLogin = (route: Route): void => {
  if (route.meta?.redirectableAfterLogin) {
    redirectPathAfterLogin = route.fullPath;
  }
};

export const getRedirectPathAfterLogin = (): string => redirectPathAfterLogin || '/';

export const getLoginPath = (): string => '/login';

router.beforeEach((to, from, next) => {
  const token = store.getters['user/token'];
  const currentUser = store.getters['user/currentUser'];

  if (to.name === View.chat && (!currentUser || currentUser.isChatDisabled)) {
    next({ name: View.home });
    return;
  }

  // Redirect to login if necessary
  if (token === undefined && !to.meta?.disableAuth) {
    saveRedirectPathAfterLogin(to);
    next(getLoginPath());
    return;
  }

  // Prevent access to login if necessary
  if (token !== undefined && to.meta?.isLoginPage) {
    next(getRedirectPathAfterLogin());
    return;
  }

  next();
});

router.afterEach((to) => {
  store.dispatch('layout/setIsFullHeight', to.meta?.isFullHeight || false);
});

export default router;
