
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';

import UserNavigationMenu from '@/components/header/UserNavigationMenu.vue';
import UserStatusMenu from '@/components/header/UserStatusMenu.vue';
import UserBadge from '@/components/shared/badge/UserBadge.vue';
import Spinner from '@/components/shared/Spinner.vue';
import StatusIcon from '@/components/shared/StatusIcon.vue';
import { Status } from '@/interfaces/shared/User';
import store from '@/store';
import menu from '@/utils/menu';

import type { ChatView } from '@/interfaces/chat/Chat';
import type { Room, RoomType, RoomUser } from '@/interfaces/chat/Room';
import type { Tokens } from '@/interfaces/shared/User';

@Component({
  components: {
    Spinner,
    StatusIcon,
    UserBadge,
    UserNavigationMenu,
    UserStatusMenu,
  },
})
export default class UserInfo extends Vue {
  @Getter('showMobileSearch', { namespace: 'chat' }) public showMobileSearch!: boolean;

  @Getter('currentChatView', { namespace: 'chat' }) public currentChatView?: ChatView;

  @Getter('currentRoom', { namespace: 'chat/room' }) public currentRoom?: Room;

  @Getter('creatingRoom', { namespace: 'chat/room' }) public creatingRoom?: RoomType;

  @Getter('currentUser', { namespace: 'user' }) public user?: RoomUser;

  @Getter('token', { namespace: 'user' }) public token?: Tokens;

  @Mutation('setShowMobileSearch', { namespace: 'chat' }) public setShowMobileSearch!: (showMobileSearch: boolean) => void;

  @Action('logout', { namespace: 'user' }) public logout!: () => Promise<void>;

  @Watch('showMobileSearch')
  public onShowMobileSearchChanged(showMobileSearch: boolean): void {
    if (showMobileSearch) {
      this.closeMenus();
    }
  }

  public get profileMenuButtonColor(): string {
    if (this.menu.profile.visible) {
      return 'text-primary';
    }

    switch (this.user?.status) {
      case Status.available:
        return 'text-body';
      case Status.absent:
        return 'text-warning';
      case Status.doNotDisturb:
        return 'text-error';
      default:
        return 'text-gray-700';
    }
  }

  public menu = {
    navigation: menu({
      onOpen: () => {
        this.menu.profile.close();
        store.commit('chat/setShowMobileSearch', false);
      },

      onClose: () => {
        this.focus(this.$refs.navigationMenuButton);
      },
    }),

    profile: menu({
      onOpen: () => {
        this.menu.navigation.close();
        store.commit('chat/setShowMobileSearch', false);
      },

      onClose: () => {
        this.menu.status.close();
      },
    }),

    status: menu({
      onClose: () => {
        this.focus(this.$refs.statusMenuButton);
      },
    }),
  };

  private focus(element: unknown): void {
    this.$nextTick(() => {
      if (element instanceof HTMLElement) {
        element.focus();
      }
    });
  }

  public onLogout(): void {
    this.logout();
    this.closeMenus();
  }

  public closeMenus(): void {
    this.menu.navigation.close();
    this.menu.profile.close();
  }
}
