import PushJs from 'push.js';

import { NewMessageEventBus } from '@/events/message';
import { MessageType, SystemMessageType } from '@/interfaces/chat/Message';
import { Status } from '@/interfaces/shared/User';
import router from '@/router';
import store from '@/store';
import { htmlToText } from '@/utils/html-to-text';
import RoomFormatter from '@/utils/room-formatter';

import type { Message, MessageFromUser, MessageFromSystem } from '@/interfaces/chat/Message';
import type { Room, RoomUser } from '@/interfaces/chat/Room';

const sendNotification = (room: Room, title: string, body: string): void => {
  PushJs.create(title, {
    vibrate: true,
    body,
    icon: '/img/notification-icon.png',
    onClick: () => {
      window.focus();

      const url = RoomFormatter.getChatViewUrl(room.id, room.type);

      router.push(url).catch(() => {
        // Do nothing, already on page
      });
    },
  });
};

const handleUserMessage = (message: MessageFromUser, room: Room): void => {
  const user: RoomUser | undefined = store.getters['user/user'](message.userId);
  const status: Status | undefined = store.getters['user/currentUser']?.status;

  if (status === Status.doNotDisturb) {
    return;
  }

  let title = '';

  if (user?.name) {
    title = [
      room.title,
      user.name,
    ].filter(Boolean).join(' - ');
  } else {
    title = RoomFormatter.getName(room);
  }

  const body = htmlToText(message.content);

  sendNotification(room, title, body);
};

const handleSystemMessage = (message: MessageFromSystem, room: Room): void => {
  const typeToBodyMap = {
    [SystemMessageType.unknown]: undefined,
    [SystemMessageType.usersAddedOrRemoved]: '👥 Gruppe geändert',
    [SystemMessageType.chatTitleChanged]: '✏️ Chat-Name geändert',
    [SystemMessageType.chatAvatarChanged]: '🎨 Chat-Bild geändert',
    [SystemMessageType.jobApprovalRejected]: '🔏 Stellenanzeige abgelehnt',
    [SystemMessageType.jobApprovalRequested]: '🔐 Stellenanzeigen-Freigabe',
    [SystemMessageType.newsCommentAdded]: '📰 News-Kommentar',
  };

  const body = typeToBodyMap[message.data.type];

  if (body) {
    sendNotification(room, RoomFormatter.getName(room), body);
  }
};

NewMessageEventBus.$on('new-message', ({ message, room }: { message: Message; room: Room }) => {
  if (message.type === MessageType.user) {
    handleUserMessage(message, room);
  }

  if (message.type === MessageType.system) {
    handleSystemMessage(message, room);
  }
});
