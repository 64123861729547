import axios from 'axios';

import store from '@/store';

import type { Tokens } from '@/interfaces/shared/User';

export default (): void => {
  axios.interceptors.request.use((axiosConfig) => {
    const auth: Tokens | undefined = store.getters['user/token'];

    if (auth?.token) {
      axiosConfig.headers.Authorization = `Bearer ${auth.token}`;
    }

    return axiosConfig;
  });
};
