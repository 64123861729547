import type { Bridge } from '@/bridge/app/Bridge';

declare global {
  interface Window {
    WKWebViewJavascriptBridge: any;
    WKWVJBCallbacks: any;
    webkit: any;
  }
}

export const isIos = (): boolean => !!window.webkit?.messageHandlers;

class IosBridge implements Bridge {
  private wkBridge: any | null = null;

  private initialized: Promise<void>;

  constructor() {
    let setInitialized: () => void = () => undefined;

    this.initialized = new Promise((resolve) => {
      setInitialized = resolve;
    });

    const callback = (bridge: any): void => {
      this.wkBridge = bridge;
      setInitialized();
    };

    if (window.WKWebViewJavascriptBridge) {
      callback(window.WKWebViewJavascriptBridge);
    } else if (window.WKWVJBCallbacks) {
      window.WKWVJBCallbacks.push(callback);
    } else {
      window.WKWVJBCallbacks = [callback];
      window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
    }
  }

  public os = (): 'android'|'ios'|'browser' => 'ios';

  public on(event: string): void {
    this.wkBridge?.callHandler('iosBridge.on', { event });
  }

  public open(url: string): void {
    this.wkBridge?.callHandler('iosBridge.open', { url });
  }

  public subscribeToTopics(topics: string[]): void {
    this.wkBridge?.callHandler('iosBridge.subscribeToTopics', { topics });
  }

  public unsubscribeFromTopics(topics: string[]): void {
    this.wkBridge?.callHandler('iosBridge.unsubscribeFromTopics', { topics });
  }

  public setStorage(value: string): void {
    this.wkBridge?.callHandler('iosBridge.setStorage', { value });
  }

  public async getStorage(): Promise<string | null> {
    await this.initialized;

    return new Promise((resolve) => {
      this.wkBridge?.callHandler('iosBridge.getStorage', null, (response: unknown) => {
        resolve(typeof response === 'string' ? response : null);
      });
    });
  }
}

export const openIosBridge = (): IosBridge => new IosBridge();
