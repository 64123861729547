const htmlToText = (html: string): string => {
  const div = document.createElement('div');

  div.innerHTML = html;

  return div.textContent || div.innerText || '';
};

export {
  htmlToText,
};

export default htmlToText;
