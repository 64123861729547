import adaptAttachment from '@/adapter/attachment';
import search from '@/http/chat/attachment/search';

import type { MessageAttachment } from '@/interfaces/chat/Message';
import type { Paginated } from '@/interfaces/shared/Paginated';
import type { SocketAttachmentSearchPayload } from '@/interfaces/shared/Socket';
import type { RootState, AttachmentState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): AttachmentState => ({
});

const AttachmentModule: Module<AttachmentState, RootState> = {
  namespaced: true,
  state: initialState,

  mutations: {
    clear(state: AttachmentState): void {
      Object.assign(state, initialState());
    },
  },

  actions: {
    async clear(
      { commit }: ActionContext<AttachmentState, RootState>,
    ): Promise<void> {
      commit('clear');
    },

    async searchAttachments(
      context: ActionContext<AttachmentState, RootState>,
      payload: SocketAttachmentSearchPayload,
    ): Promise<Paginated<MessageAttachment>> {
      // TODO: Caching?
      const result = await search(payload);

      if (result?.entries?.length === undefined || result?.total === undefined) {
        return {
          total: 0,
          entries: [],
        };
      }

      return {
        total: result.total,
        entries: result.entries.map((entry) => (
          adaptAttachment.fromSocket(entry)
        )),
      };
    },
  },
};

export default AttachmentModule;
