import axios from 'axios';

import config from '@/config';

import type { MessageReactionUpdate, MessageSent } from '@/interfaces/chat/Message';
import type {
  SocketMessage,
  SocketMessageAnswersPayload,
  SocketMessageListPayload,
} from '@/interfaces/shared/Socket';
import type { UnsafePaginated } from '@/interfaces/shared/UnsafePaginated';

export default {
  loadMessages: (payload: SocketMessageListPayload):
  Promise<UnsafePaginated<SocketMessage>> => (
    axios.get<UnsafePaginated<SocketMessage>>(`${config.versionedSocketApiUrl()}/message/list`, {
      params: payload,
    }).then((res) => res.data)
  ),

  loadAnswers: (payload: SocketMessageAnswersPayload):
  Promise<UnsafePaginated<SocketMessage>> => (
    axios.get<UnsafePaginated<SocketMessage>>(`${config.versionedSocketApiUrl()}/message/answers`, {
      params: payload,
    }).then((res) => res.data)
  ),

  sendMessage: (message: MessageSent): Promise<void> => (
    axios.post<void>(`${config.versionedSocketApiUrl()}/message`, message)
      .then((res) => res.data)
  ),

  deleteMessage: (messageId: string): Promise<void> => (
    axios.delete<void>(`${config.versionedSocketApiUrl()}/message/${messageId}`)
      .then((res) => res.data)
  ),

  toggleReaction: (payload: MessageReactionUpdate): Promise<void> => (
    axios.post<void>(`${config.versionedSocketApiUrl()}/message/${payload.messageId}/toggle-reaction`, {
      reaction: payload.reaction,
    }).then((res) => res.data)
  ),
};
