import type { Toastable } from '@/interfaces/shared/Toast';
import type { ToastState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): ToastState => ({
  toasts: [],
});

const ToastModule: Module<ToastState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    toasts(state: ToastState): Toastable[] {
      return state.toasts;
    },
  },

  mutations: {
    clear(state: ToastState): void {
      Object.assign(state, initialState());
    },

    addToast(state: ToastState, payload: Toastable): void {
      state.toasts = [...state.toasts, payload];
    },

    removeToast(state: ToastState, payload: Toastable): void {
      state.toasts = state.toasts.filter((toast) => toast !== payload);
    },
  },

  actions: {
    async clear({ commit }: ActionContext<ToastState, RootState>): Promise<void> {
      commit('clear');
    },

    async addToast(
      { commit, dispatch }: ActionContext<ToastState, RootState>,
      payload: Toastable,
    ): Promise<void> {
      commit('addToast', payload);

      if (payload.ttlSeconds) {
        setTimeout(() => {
          dispatch('removeToast', payload);
        }, payload.ttlSeconds * 1000);
      }
    },

    async removeToast(
      { commit }: ActionContext<ToastState, RootState>,
      payload: Toastable,
    ): Promise<void> {
      commit('removeToast', payload);
    },
  },
};

export default ToastModule;
