
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import { RoomView } from '@/interfaces/chat/Room';
import RoomFormatter from '@/utils/room-formatter';

import type { ChatView } from '@/interfaces/chat/Chat';
import type { Room } from '@/interfaces/chat/Room';

@Component
export default class RoomInfo extends Vue {
  @Getter('currentChatView', { namespace: 'chat' }) public currentChatView!: ChatView;

  @Getter('currentRoom', { namespace: 'chat/room' }) public currentRoom!: Room;

  @Getter('currentRoomView', { namespace: 'chat/room' }) public currentRoomView!: RoomView;

  @Mutation('setEditingRoom', { namespace: 'modal' }) public setEditingRoom!: (room?: Room) => void;

  @Mutation('setLeavingRoom', { namespace: 'modal' }) public setLeavingRoom!: (room?: Room) => void;

  @Mutation('setClosingRoom', { namespace: 'modal' }) public setClosingRoom!: (room?: Room) => void;

  public RoomView = RoomView;

  public showMenu = false;

  public get canAccessParticipants(): boolean {
    return RoomFormatter.userCanAccessParticipants(this.currentRoom);
  }

  public get canClose(): boolean {
    return RoomFormatter.userCanClose(this.currentRoom);
  }

  public get canEdit(): boolean {
    return RoomFormatter.userCanEdit(this.currentRoom);
  }

  public get canInvite(): boolean {
    return RoomFormatter.userCanInvite(this.currentRoom);
  }

  public get canLeave(): boolean {
    return RoomFormatter.userCanLeave(this.currentRoom);
  }

  public toggleMenu(): void {
    if (this.showMenu) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  public openMenu(): void {
    this.showMenu = true;
  }

  public closeMenu(): void {
    this.showMenu = false;
  }

  public showChatPage(): void {
    this.showPage(`/${this.currentChatView}/${this.currentRoom.id}`);
  }

  public showFilesPage(): void {
    this.showPage(`/${this.currentChatView}/${this.currentRoom.id}/${RoomView.files}`);
  }

  public showInvitePage(): void {
    this.showPage(`/${this.currentChatView}/${this.currentRoom.id}/${RoomView.invite}`);
  }

  public showParticipantsPage(): void {
    this.showPage(`/${this.currentChatView}/${this.currentRoom.id}/${RoomView.participants}`);
  }

  public editRoom(): void {
    this.setEditingRoom(this.currentRoom);
    this.closeMenu();
  }

  public leaveRoom(): void {
    this.setLeavingRoom(this.currentRoom);
    this.closeMenu();
  }

  public closeRoom(): void {
    this.setClosingRoom(this.currentRoom);
    this.closeMenu();
  }

  private showPage(page: string): void {
    this.$router.push(page).catch(() => {
      // Do nothing, already on page
    });
    this.closeMenu();
  }
}
