import Vue from 'vue';

import date from '@/utils/date';

Vue.filter('date', (
  value: number | Date | undefined,
  format?: Intl.DateTimeFormatOptions,
  separator?: string,
) => {
  if (value === undefined) {
    return '';
  }

  let formattedDate = date.format(value, format);

  if (separator) {
    formattedDate = formattedDate.replace(', ', separator);
  }

  return formattedDate;
});
