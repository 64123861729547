import axios from 'axios';

import config from '@/config';

import type { SocketUser } from '@/interfaces/shared/Socket';

export default (userId: string): Promise<SocketUser> => (
  axios.get<SocketUser>('/user', {
    baseURL: config.versionedSocketApiUrl(),
    params: {
      userId,
    },
  }).then((res) => res.data)
);
