
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import AvatarBadge from '@/components/shared/badge/AvatarBadge.vue';

import type { RoomUser } from '@/interfaces/chat/Room';
import type { MaybeAvatar } from '@/interfaces/shared/Avatar';

@Component({
  components: {
    AvatarBadge,
  },
})
export default class UserBadge extends Vue {
  @Prop() public user?: RoomUser;

  @Prop({ default: 0 }) public notifications!: number;

  @Prop() public hideStatus?: boolean;

  @Getter('userAvatar', { namespace: 'avatar' }) public getUserAvatar!: (user: RoomUser) => MaybeAvatar;

  @Action('loadUserAvatar', { namespace: 'avatar' }) public loadUserAvatar!: (user: RoomUser) => Promise<MaybeAvatar>;

  @Watch('user', { deep: true, immediate: true })
  public onUserChanged(): void {
    if (this.user) {
      this.loadUserAvatar(this.user);
    }
  }

  public get avatar(): MaybeAvatar {
    if (!this.user) {
      return undefined;
    }

    return this.getUserAvatar(this.user);
  }
}
