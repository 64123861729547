import { DisconnectReason, SystemEvent } from '@/interfaces/shared/Socket';
import socket from '@/socket';

import type { Tokens } from '@/interfaces/shared/User';
import type { SocketState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): SocketState => ({
  connected: false,
  disconnectReason: undefined,
});

const SocketModule: Module<SocketState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    connected(state: SocketState): boolean {
      return state.connected;
    },

    disconnectReason(state: SocketState): DisconnectReason | undefined {
      return state.disconnectReason;
    },
  },

  mutations: {
    clear(state: SocketState): void {
      Object.assign(state, initialState());
    },

    connected(state: SocketState): void {
      state.connected = true;
      state.disconnectReason = undefined;
    },

    disconnected(state: SocketState, payload: DisconnectReason): void {
      state.connected = false;
      state.disconnectReason = payload;
    },
  },

  actions: {
    async clear({ commit }: ActionContext<SocketState, RootState>): Promise<void> {
      commit('clear');
    },

    async connect(
      { dispatch }: ActionContext<SocketState, RootState>,
      payload: Tokens,
    ): Promise<void> {
      await dispatch('disconnect');

      return new Promise((resolve, reject) => {
        socket.io.opts.query = {
          token: payload.token,
        };

        socket
          .once('connect', () => resolve())
          .once('connect_error', () => reject());

        socket.connect();
      });
    },

    async disconnect(): Promise<void> {
      socket.disconnect();
    },

    async [SystemEvent.connect](
      { commit }: ActionContext<SocketState, RootState>,
    ): Promise<void> {
      commit('connected');
    },

    async [SystemEvent.connectionError](
      { commit }: ActionContext<SocketState, RootState>,
    ): Promise<void> {
      commit('disconnected', DisconnectReason.connectionError);
    },

    async [SystemEvent.connectionTimeout](
      { commit }: ActionContext<SocketState, RootState>,
    ): Promise<void> {
      commit('disconnected', DisconnectReason.connectionTimeout);
    },

    async [SystemEvent.reconnectFailed](
      { commit }: ActionContext<SocketState, RootState>,
    ): Promise<void> {
      commit('disconnected', DisconnectReason.forceReauthentication);
    },
  },
};

export default SocketModule;
