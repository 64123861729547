import axios from 'axios';

import config from '@/config';

import type { LoadCommentsPayload } from '@/interfaces/news/Comments';
import type { NewsArticleComment, NewsArticleDetailItem } from '@/interfaces/news/News';

const baseUrl = config.versionedServerUrl();

export default {
  loadArticle: (hash: string): Promise<Partial<NewsArticleDetailItem>> => (
    axios.get<Partial<NewsArticleDetailItem>>(`${baseUrl}/news/article/${hash}`)
      .then((res) => res.data)
  ),

  bookmarkArticle: (hash: string): Promise<void> => (
    axios.post<void>(`${baseUrl}/news/article/${hash}/bookmark`)
      .then((res) => res.data)
  ),

  unbookmarkArticle: (hash: string): Promise<void> => (
    axios.delete<void>(`${baseUrl}/news/article/${hash}/bookmark`)
      .then((res) => res.data)
  ),

  likeArticle: (hash: string): Promise<number> => (
    axios.post<{ likes: number }>(`${baseUrl}/news/article/${hash}/like`)
      .then((response) => response.data?.likes || 0)
  ),

  unlikeArticle: (hash: string): Promise<number> => (
    axios.delete<{ likes: number }>(`${baseUrl}/news/article/${hash}/like`)
      .then((response) => response.data?.likes || 0)
  ),

  markArticleRead: (hash: string): Promise<void> => (
    axios.post<void>(`${baseUrl}/news/article/${hash}/read`)
      .then((res) => res.data)
  ),

  forceArticleRead: (hash: string): Promise<void> => (
    axios.post<void>(`${baseUrl}/news/article/${hash}/read-marker`)
      .then((response) => response.data)
  ),

  forceArticleUnread: (hash: string): Promise<void> => (
    axios.delete<void>(`${baseUrl}/news/article/${hash}/read-marker`)
      .then((response) => response.data)
  ),

  loadArticleComments: (payload: LoadCommentsPayload):
  Promise<Partial<NewsArticleComment>[]> => (
    axios.get<Partial<NewsArticleComment>[]>(`${baseUrl}/news/article/${payload.articleHash}/comments`, {
      params: {
        offset: payload.offset,
        limit: payload.limit,
      },
    }).then((res) => res.data)
  ),

  addArticleComment: (articleHash: string, comment: string):
  Promise<Partial<NewsArticleComment>> => (
    axios.post<Partial<NewsArticleComment>>(`${baseUrl}/news/article/${articleHash}/comments`, {
      comment,
    }).then((res) => res.data)
  ),

  editArticleComment: (commentHash: string, comment: string):
  Promise<Partial<NewsArticleComment>> => (
    axios.put<Partial<NewsArticleComment>>(`${baseUrl}/news/comments/${commentHash}`, {
      comment,
    }).then((res) => res.data)
  ),

  removeArticleComment: (commentHash: string): Promise<Partial<NewsArticleComment>> => (
    axios.delete<Partial<NewsArticleComment>>(`${baseUrl}/news/comments/${commentHash}`)
      .then((res) => res.data)
  ),
};
