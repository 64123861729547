
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';

import type { Tokens } from '@/interfaces/shared/User';

@Component
export default class JobsState extends Vue {
  @Getter('token', { namespace: 'user' }) public tokenForJobsState?: Tokens;

  @Mutation('setCurrentJobHash', { namespace: 'jobs' }) public setCurrentJobHash!: (hash?: string) => void;

  @Action('fetchUnreadCount', { namespace: 'jobs' }) public fetchJobsUnreadCount!: () => Promise<void>;

  @Watch('$route.params.jobDetailHash', { immediate: true })
  public onJobDetailHashChanged(hash?: string): void {
    this.setCurrentJobHash(hash);
  }

  @Watch('tokenForJobsState', { immediate: true })
  public onTokenForJobsStateChanged(): void {
    if (this.tokenForJobsState) {
      this.registerJobCountUpdates();
    } else {
      this.unregisterJobCountUpdates();
    }
  }

  private jobSeenFetchInterval?: number;

  private registerJobCountUpdates(): void {
    this.unregisterJobCountUpdates();

    const oneHourInMillis = 3600000;

    this.jobSeenFetchInterval = window.setInterval(() => {
      this.safeFetchJobsUnreadCount();
    }, oneHourInMillis);

    this.safeFetchJobsUnreadCount();
  }

  private unregisterJobCountUpdates(): void {
    if (this.jobSeenFetchInterval) {
      clearInterval(this.jobSeenFetchInterval);
    }
  }

  private safeFetchJobsUnreadCount(): void {
    this.fetchJobsUnreadCount().catch(() => {
      // Do nothing
    });
  }
}
