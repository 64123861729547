
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import StatusIcon from '@/components/shared/StatusIcon.vue';
import { Status } from '@/interfaces/shared/User';

import type { RoomUser } from '@/interfaces/chat/Room';

@Component({
  components: {
    StatusIcon,
  },
})
export default class UserStatusMenu extends Vue {
  @Getter('currentUser', { namespace: 'user' }) public user?: RoomUser;

  @Action('changeStatus', { namespace: 'user' }) public changeStatus!: (status: Status) => Promise<void>;

  public Status = Status;

  public mounted(): void {
    const { backButton } = this.$refs;

    if (backButton instanceof HTMLElement) {
      backButton.focus();
    }
  }

  public onChangeStatus(status: Status): void {
    if (this.user?.status !== status) {
      this.changeStatus(status);
    }

    this.$emit('close');
  }

  public onBack(): void {
    this.$emit('back');
  }
}
