
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

import { ChatView } from '@/interfaces/chat/Chat';
import { RoomType, RoomView } from '@/interfaces/chat/Room';

@Component
export default class ChatState extends Vue {
  @Mutation('setCurrentChatView', { namespace: 'chat' }) public setCurrentChatView!: (chatView?: ChatView) => void;

  @Mutation('setCurrentRoomId', { namespace: 'chat/room' }) public setCurrentRoomId!: (roomId?: string) => void;

  @Mutation('setCurrentRoomView', { namespace: 'chat/room' }) public setCurrentRoomView!: (roomView?: RoomView) => void;

  @Mutation('setCreatingRoom', { namespace: 'chat/room' }) public setCreatingRoom!: (roomType?: RoomType) => void;

  @Mutation('triggerMessageReorder', { namespace: 'chat/message' }) public triggerMessageReorder!: (roomId: string) => void;

  @Mutation('clearRoomNotifications', { namespace: 'chat/room' }) public clearRoomNotifications!: (roomId: string) => void;

  @Watch('$route.params.chatView', { immediate: true })
  public onChatViewChanged(chatView?: string): void {
    const view: ChatView | undefined = ChatView[<keyof typeof ChatView> chatView] || undefined;

    this.setCurrentChatView(view);
  }

  @Watch('$route.params.roomId', { immediate: true })
  public onRoomIdChanged(roomId?: string, lastRoomId?: string): void {
    if (roomId === 'new-chat' || roomId === 'new-group') {
      this.setCurrentRoomId(undefined);
      this.setCreatingRoom(roomId === 'new-chat' ? RoomType.single : RoomType.group);
    } else {
      this.setCreatingRoom(undefined);
      this.setCurrentRoomId(roomId);

      if (roomId && roomId !== lastRoomId) {
        this.$nextTick(() => {
          this.clearRoomNotifications(roomId);
          this.triggerMessageReorder(roomId);
        });
      }
    }
  }

  @Watch('$route.params.roomView', { immediate: true })
  public onRoomViewChanged(roomView?: string): void {
    const view: RoomView | undefined = RoomView[<keyof typeof RoomView> roomView] || undefined;

    this.setCurrentRoomView(view);
  }
}
