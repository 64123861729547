import adapter from '@/adapter/news';
import http from '@/http/news/article';

import type {
  AddCommentPayload,
  EditCommentPayload,
  IncrementCommentCountPayload,
  RemoveCommentPayload,
} from '@/interfaces/news/Comments';
import type { NewsArticleComment } from '@/interfaces/news/News';
import type { RootState, NewsCommentsState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): NewsCommentsState => ({
  isLoading: false,
  isSending: false,
  hasError: false,
  comments: {},
  page: 0,
});

const NewsCommentsModule: Module<NewsCommentsState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    isLoading: (state: NewsCommentsState): boolean => state.isLoading,

    isSending: (state: NewsCommentsState): boolean => state.isSending,

    hasError: (state: NewsCommentsState): boolean => state.hasError,

    getComments: (state: NewsCommentsState): NewsArticleComment[] => (
      Object.values(state.comments)
        .filter((comment): comment is NewsArticleComment => !!comment)
        .sort((comment1, comment2) => (
          comment1.createdAt - comment2.createdAt
        ))
    ),
  },

  mutations: {
    clear(state: NewsCommentsState): void {
      Object.assign(state, initialState());
    },

    setLoading(state: NewsCommentsState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

    setHasError(state: NewsCommentsState, hasError: boolean): void {
      state.hasError = hasError;
    },

    setSending(state: NewsCommentsState, isSending: boolean): void {
      state.isSending = isSending;
    },

    setComments(state: NewsCommentsState, comments: NewsArticleComment[]): void {
      comments.forEach((comment) => {
        state.comments[comment.hash] = comment;
      });

      state.comments = {
        ...state.comments,
      };
    },

    setComment(state: NewsCommentsState, comment: NewsArticleComment): void {
      state.comments = {
        ...state.comments,
        [comment.hash]: comment,
      };
    },

    removeComment(state: NewsCommentsState, payload: RemoveCommentPayload): void {
      delete state.comments[payload.commentHash];

      state.comments = {
        ...state.comments,
      };
    },

    clearComments(state: NewsCommentsState): void {
      state.page = 0;
      state.comments = {};
    },

    setPage(state: NewsCommentsState, page: number): void {
      state.page = page;
    },
  },

  actions: {
    async clear({ commit }: ActionContext<NewsCommentsState, RootState>): Promise<void> {
      commit('clear');
    },

    async loadComments(
      { commit, state }: ActionContext<NewsCommentsState, RootState>,
      articleHash: string,
    ): Promise<void> {
      commit('setLoading', true);
      commit('setHasError', false);

      await http.loadArticleComments({
        articleHash,
        limit: 8,
        offset: state.page * 8,
      })
        .then((response) => {
          commit('setPage', state.page + 1);
          commit('setComments', adapter.commentsFromServer(response));
        })
        .catch(() => {
          commit('setHasError', true);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },

    async addComment(
      { commit }: ActionContext<NewsCommentsState, RootState>,
      payload: AddCommentPayload,
    ): Promise<void> {
      commit('setSending', true);

      await http.addArticleComment(payload.articleHash, payload.comment)
        .then((response) => {
          const comment = adapter.commentFromServer(response);

          commit('setComment', comment);

          const commentCountPayload: IncrementCommentCountPayload = {
            articleHash: payload.articleHash,
            commentHash: comment.hash,
            increment: 1,
          };

          commit('news/article/incrementCommentCount', commentCountPayload, { root: true });
        })
        .finally(() => {
          commit('setSending', false);
        });
    },

    async editComment(
      { commit }: ActionContext<NewsCommentsState, RootState>,
      payload: EditCommentPayload,
    ): Promise<void> {
      commit('setSending', true);

      await http.editArticleComment(payload.commentHash, payload.comment)
        .then((response) => {
          commit('setComment', adapter.commentFromServer(response));
        })
        .finally(() => {
          commit('setSending', false);
        });
    },

    async removeComment(
      { commit }: ActionContext<NewsCommentsState, RootState>,
      payload: RemoveCommentPayload,
    ): Promise<void> {
      commit('setSending', true);

      await http.removeArticleComment(payload.commentHash)
        .then((response) => {
          commit('setComment', adapter.commentFromServer(response));
        })
        .finally(() => {
          commit('setSending', false);
        });
    },
  },
};

export default NewsCommentsModule;
