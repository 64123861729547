export enum SystemEvent {
  connect = 'SOCKET_connect',
  connectionError = 'SOCKET_connect_error',
  connectionTimeout = 'SOCKET_connect_timeout',
  disconnect = 'SOCKET_disconnect',
  reconnectAttempt = 'SOCKET_reconnect_attempt',
  reconnectFailed = 'SOCKET_reconnect_failed',
}

export enum SocketAuthEmission {
  tokenRefresh = 'client.user.token_refresh',
}

export enum SocketChatEmission {
  tutorialShown = 'client.chat.tutorial.shown',
  typing = 'client.chat.typing',
}

export enum SocketUserEmission {
  updateStatus = 'client.user.update.status',
}

export enum SocketChatEvent {
  messageUpdate = 'SOCKET_server.chat.message.update',
  roomDelete = 'SOCKET_server.chat.delete',
  roomTyping = 'SOCKET_server.chat.typing',
  roomUpdate = 'SOCKET_server.chat.update',
  roomsUpdate = 'SOCKET_server.chats.update',
  userUpdate = 'SOCKET_server.user.update',
  usersUpdate = 'SOCKET_server.users.update',
  tutorialShowCount = 'SOCKET_server.chat.tutorial.show_count',
}

export enum DisconnectReason {
  connectionError = 'connectionError',
  connectionTimeout = 'connectionTimeout',
  forceReauthentication = 'forceReauthentication',
}

export interface SocketUser {
  id?: string;
  email?: string;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
  notifications?: number;
  hasAvatar?: boolean;
  isDisabled?: boolean;
  isChatDisabled?: boolean;
}

export interface SocketUserSearchPayload {
  excludeUsersFromChat?: string;
  search?: string;
  limit: number;
  offset: number;
}

export interface SocketRoom {
  id?: string;
  type?: string;
  title?: string;
  userIds?: string[];
  lastReadMessageTime?: number;
  lastMessage?: string;
  lastMessageTime?: number;
  lastMessageUserId?: string;
  notifications?: number;
  hasAvatar?: boolean;
}

export interface SocketRoomTyping {
  chatId?: string;
  userId?: string;
}

export interface SocketRoomReceiptPayload {
  roomId: string;
  receiptTs: number;
}

export interface SocketMessageAttachment {
  id?: string;
  filename?: string;
  hasPreview?: boolean;
  createTs?: number;
  mimeType?: string;
}

export interface SocketMessageFromUser {
  id?: string;
  type?: string;
  parentId?: string;
  userId?: string;
  chatId?: string;
  content?: string;
  sentTs?: number;
  receivedTs?: number;
  sortTs?: number;
  edited?: boolean;
  deleted?: boolean;
  countsAsNotification?: boolean;
  answerCount?: number;
  reactions?: {
    [emoji: string]: number | undefined;
  };
  attachments?: SocketMessageAttachment[];
}

export interface SocketSystemMessageData {
  type?: string;
  hasAvatar?: boolean;
  title?: string;
  addedUserIds?: string[];
  removedUserIds?: string[];
  commentingUserId?: string;
  rejectingUserId?: string;
  requestingUserId?: string;
  news?: string;
  jobId?: string;
}

export interface SocketMessageFromSystem {
  id?: string;
  type?: string;
  chatId?: string;
  data?: SocketSystemMessageData;
  sentTs?: number;
  receivedTs?: number;
  sortTs?: number;
  edited?: boolean;
  deleted?: boolean;
  countsAsNotification?: boolean;
}

export type SocketMessage = SocketMessageFromUser | SocketMessageFromSystem;

export interface SocketMessageListPayload {
  chat: string;
  limit: number;
  loadOlderThan?: number;
}

export interface SocketMessageAnswersPayload {
  message: string;
}

export interface SocketAttachmentSearchPayload {
  chat: string;
  limit: number;
  offset: number;
  search?: string;
}

export interface SocketMessageReactions {
  [emoji: string]: number | undefined;
}
