
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Badge extends Vue {
  @Prop({ default: 'bg-secondary' }) public color!: string;

  @Prop({ default: 'Nachricht/en' }) public srOnlySuffix!: string;

  @Prop() public number!: number;

  @Prop({ default: 99 }) public max!: number;

  public get displayNumber(): string {
    if (this.max && this.number > this.max) {
      return `${this.max}+`;
    }

    return `${this.number}`;
  }
}
