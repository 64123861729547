import axios from 'axios';

import config from '@/config';

import type { AxiosProgressEvent } from 'axios';

export default {
  uploadFile: (
    roomId: string,
    file: File,
    onUploadProgress: (event: AxiosProgressEvent) => void,
  ): Promise<{id: string}> => {
    const formData = new FormData();

    formData.append('roomId', roomId);
    formData.append('file', file);

    return axios.post(
      `${config.versionedServerUrl()}/chat/file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      },
    ).then((res) => res.data);
  },

  cancelUploads: (ids: string[]): Promise<void> => {
    const params = new FormData();

    ids.forEach((id) => {
      params.append('ids[]', id);
    });

    return axios.post<void>(
      `${config.versionedServerUrl()}/chat/file/cancel`,
      params,
    ).then((res) => res.data);
  },
};
