import adapter from '@/adapter/news';
import http from '@/http/news/article';

import type { IncrementCommentCountPayload } from '@/interfaces/news/Comments';
import type {
  NewsArticleBaseItem,
  NewsArticleDetailItem,
  SetArticleBookmarkedPayload,
  SetArticleLikedPayload,
  SetArticleReadPayload,
} from '@/interfaces/news/News';
import type { RootState, NewsArticleState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): NewsArticleState => ({
  isLoading: false,
  hasError: false,
  article: undefined,
});

const NewsDetailsModule: Module<NewsArticleState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    isLoading: (state: NewsArticleState): boolean => state.isLoading,

    hasError: (state: NewsArticleState): boolean => state.hasError,

    article: (state: NewsArticleState): NewsArticleDetailItem | undefined => state.article,
  },

  mutations: {
    clear(state: NewsArticleState): void {
      Object.assign(state, initialState());
    },

    setLoading(state: NewsArticleState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

    setHasError(state: NewsArticleState, hasError: boolean): void {
      state.hasError = hasError;
    },

    setArticle(state: NewsArticleState, article?: NewsArticleDetailItem): void {
      state.article = article;
    },

    setArticleBookmarked(state: NewsArticleState, payload: SetArticleBookmarkedPayload): void {
      if (state.article?.hash === payload.hash) {
        state.article = {
          ...state.article,
          bookmarked: payload.bookmarked,
        };
      }
    },

    setArticleLiked(state: NewsArticleState, payload: SetArticleLikedPayload): void {
      if (state.article?.hash === payload.hash) {
        state.article = {
          ...state.article,
          likes: payload.likes,
          liked: payload.liked,
        };
      }
    },

    setArticleRead(state: NewsArticleState, payload: SetArticleReadPayload): void {
      if (state.article?.hash === payload.hash) {
        state.article = {
          ...state.article,
          read: payload.read,
        };
      }
    },

    incrementCommentCount(state: NewsArticleState, payload: IncrementCommentCountPayload): void {
      if (state.article?.hash === payload.articleHash) {
        state.article = {
          ...state.article,
          comments: state.article.comments + payload.increment,
        };
      }
    },
  },

  actions: {
    async clear({ commit }: ActionContext<NewsArticleState, RootState>): Promise<void> {
      commit('clear');
    },

    async loadArticle(
      { commit, state }: ActionContext<NewsArticleState, RootState>,
      articleHash: string,
    ): Promise<void> {
      if (state.isLoading) {
        return;
      }

      commit('setLoading', true);
      commit('setHasError', false);
      commit('setArticle', undefined);

      await http.loadArticle(articleHash)
        .then((response) => {
          commit('setArticle', adapter.detailsItemFromServer(response));
        })
        .catch(() => {
          commit('setHasError', true);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },

    async markArticleRead(
      { dispatch }: ActionContext<NewsArticleState, RootState>,
      article: NewsArticleBaseItem,
    ): Promise<void> {
      await http.markArticleRead(article.hash);

      dispatch('news/setArticleRead', { hash: article.hash, read: true }, { root: true });
      dispatch('news/unreadCount/loadUnreadCountIfSet', { categoryHash: article.category.hash }, { root: true });
    },

    async forceArticleRead(
      { dispatch }: ActionContext<NewsArticleState, RootState>,
      article: NewsArticleBaseItem,
    ): Promise<void> {
      await http.forceArticleRead(article.hash);

      dispatch('news/setArticleRead', { hash: article.hash, read: true }, { root: true });
      dispatch('news/unreadCount/loadUnreadCountIfSet', { categoryHash: article.category.hash }, { root: true });
    },

    async forceArticleUnread(
      { dispatch }: ActionContext<NewsArticleState, RootState>,
      article: NewsArticleBaseItem,
    ): Promise<void> {
      await http.forceArticleUnread(article.hash);

      dispatch('news/setArticleRead', { hash: article.hash, read: false }, { root: true });
      dispatch('news/unreadCount/loadUnreadCountIfSet', { categoryHash: article.category.hash }, { root: true });
    },

    async likeArticle(
      { dispatch }: ActionContext<NewsArticleState, RootState>,
      hash: string,
    ): Promise<void> {
      const likes = await http.likeArticle(hash);

      const payload: SetArticleLikedPayload = {
        hash,
        likes,
        liked: true,
      };

      dispatch('news/setArticleLiked', payload, { root: true });
    },

    async unlikeArticle(
      { dispatch }: ActionContext<NewsArticleState, RootState>,
      hash: string,
    ): Promise<void> {
      const likes = await http.unlikeArticle(hash);

      const payload: SetArticleLikedPayload = {
        hash,
        likes,
        liked: false,
      };

      dispatch('news/setArticleLiked', payload, { root: true });
    },

    async bookmarkArticle(
      { dispatch }: ActionContext<NewsArticleState, RootState>,
      hash: string,
    ): Promise<void> {
      await http.bookmarkArticle(hash);

      const payload: SetArticleBookmarkedPayload = {
        hash,
        bookmarked: true,
      };

      dispatch('news/setArticleBookmarked', payload, { root: true });
    },

    async unbookmarkArticle(
      { dispatch }: ActionContext<NewsArticleState, RootState>,
      hash: string,
    ): Promise<void> {
      await http.unbookmarkArticle(hash);

      const payload: SetArticleBookmarkedPayload = {
        hash,
        bookmarked: false,
      };

      dispatch('news/setArticleBookmarked', payload, { root: true });
    },
  },
};

export default NewsDetailsModule;
