import { MenuItemType } from '@/interfaces/menu/Menu';
import { View } from '@/router';

import type { MenuItem } from '@/interfaces/menu/Menu';
import type { RawLocation } from 'vue-router';

export const mapToTarget = (item: MenuItem): RawLocation => {
  switch (item.type) {
    case MenuItemType.home:
      return { name: View.home };

    case MenuItemType.news:
      if (item.data.topic) {
        const params: Record<string, string> = {
          topic: item.data.topic,
        };

        if (item.data.category) {
          params.category = item.data.category;
        }

        return {
          name: View.newsCategory,
          params,
        };
      }

      return { name: View.newsList };

    case MenuItemType.chat:
      return {
        name: View.chat,
        params: {
          chatView: 'chats',
        },
      };

    case MenuItemType.external:
    case MenuItemType.internal:
      return item.data.link || '';

    case MenuItemType.infocenter:
      return {
        name: View.infocenter,
        params: {
          menuItemHash: item.data.hash || '',
        },
      };

    case MenuItemType.jobcenter:
      return { name: View.jobList };

    default:
      return '';
  }
};

export default {
  mapToTarget,
};
