import axios from 'axios';

import config from '@/config';

import type { ServerMenu } from '@/interfaces/menu/Menu';

export default {
  loadMenu: (): Promise<ServerMenu> => (
    axios.get<ServerMenu>(`${config.versionedServerUrl()}/menu`)
      .then((res) => res.data)
  ),
};
