import type { LayoutState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): LayoutState => ({
  isFullHeight: false,
});

const LayoutModule: Module<LayoutState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    isFullHeight(state: LayoutState): boolean {
      return state.isFullHeight;
    },
  },

  mutations: {
    clear(state: LayoutState): void {
      Object.assign(state, initialState());
    },

    setIsFullHeight(state: LayoutState, isFullHeight: boolean): void {
      state.isFullHeight = isFullHeight;
    },
  },

  actions: {
    async clear({ commit }: ActionContext<LayoutState, RootState>): Promise<void> {
      commit('clear');
    },

    async setIsFullHeight(
      { commit }: ActionContext<LayoutState, RootState>,
      isFullHeight: boolean,
    ): Promise<void> {
      commit('setIsFullHeight', isFullHeight);
    },
  },
};

export default LayoutModule;
