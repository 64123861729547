import axios from 'axios';

import config from '@/config';

import type { NewsSearchOptions } from '@/interfaces/news/Filter';
import type { NewsArticleListItem } from '@/interfaces/news/News';
import type { AxiosResponse } from 'axios';

export default {
  loadTopNews: (): Promise<Partial<NewsArticleListItem>> => (
    axios.get<Partial<NewsArticleListItem>>(
      `${config.versionedServerUrl()}/news/top-news`,
    ).then((res) => res.data)
  ),

  loadNews: (
    searchParameters?: NewsSearchOptions,
  ): Promise<AxiosResponse<Partial<NewsArticleListItem>[]>> => {
    const topic = searchParameters?.topic;
    const category = searchParameters?.category;
    const params = {
      ...searchParameters,
      topics: topic ? [topic] : undefined,
      categories: category ? [category] : undefined,
    };

    delete params.topic;
    delete params.category;

    return axios.get(`${config.versionedServerUrl()}/news`, {
      params,
    });
  },
};
