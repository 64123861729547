import adaptJobs from '@/adapter/jobs';
import http from '@/http/jobs/list';

import type {
  JobBaseItem,
  JobListItem,
  JobsLoadOptions,
  SetJobReadPayload,
} from '@/interfaces/jobs/Jobs';
import type { JobsListState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): JobsListState => ({
  isLoading: false,
  hasError: false,
  hasMore: false,
  jobs: {},
  offset: 0,
  limit: 12,
});

const JobsListModule: Module<JobsListState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    isLoading: (state: JobsListState): boolean => state.isLoading,

    hasError: (state: JobsListState): boolean => state.hasError,

    hasMore: (state: JobsListState): boolean => state.hasMore,

    jobs: (state: JobsListState): JobListItem[] => (
      Object.values(state.jobs)
        .filter((job): job is JobListItem => !!job)
        .sort((job1, job2) => (
          job2.publishDate - job1.publishDate
        ))
    ),
  },

  mutations: {
    clear(state: JobsListState): void {
      Object.assign(state, initialState());
    },

    setLoading(state: JobsListState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

    setHasError(state: JobsListState, hasError: boolean): void {
      state.hasError = hasError;
    },

    setHasMore(state: JobsListState, hasMore: boolean): void {
      state.hasMore = hasMore;
    },

    resetPaging(state: JobsListState): void {
      const initial = initialState();

      state.jobs = initial.jobs;
      state.offset = initial.offset;
      state.limit = initial.limit;
    },

    setNextPage(state: JobsListState): void {
      state.offset += state.limit;
    },

    addJobs(state: JobsListState, jobs: JobListItem[]): void {
      jobs.forEach((job) => {
        state.jobs[job.hash] = job;
      });

      state.jobs = {
        ...state.jobs,
      };
    },

    setJobRead(state: JobsListState, payload: SetJobReadPayload): void {
      const job = state.jobs[payload.hash];

      if (job) {
        job.read = payload.read;

        state.jobs = {
          ...state.jobs,
        };
      }
    },
  },

  actions: {
    async clear({ commit }: ActionContext<JobsListState, RootState>): Promise<void> {
      commit('clear');
    },

    async loadJobs(
      { commit, state }: ActionContext<JobsListState, RootState>,
      parameters?: JobsLoadOptions,
    ): Promise<void> {
      if (state.isLoading) {
        return;
      }

      commit('setLoading', true);
      commit('setHasError', false);

      if (parameters?.updateHasMore) {
        commit('setHasMore', false);
      }

      if (parameters?.resetPaging) {
        commit('resetPaging');
      }

      await http.loadJobs({
        offset: state.offset,
        limit: state.limit,
        ...parameters?.parameters,
      })
        .then((response) => {
          if (parameters?.updateHasMore) {
            commit('setHasMore', response.headers['x-pagination-hasmore'] === '1');
          }

          const jobs = adaptJobs.listFromServer(response.data);

          commit('addJobs', jobs);

          if (jobs.length) {
            commit('setNextPage');
          }
        })
        .catch(() => {
          commit('setHasError', true);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },

    async setJobRead(
      { commit }: ActionContext<JobsListState, RootState>,
      job: JobBaseItem,
    ): Promise<void> {
      commit('setJobRead', job);
    },
  },
};

export default JobsListModule;
