export enum DraftFileUploadFailReason {
  intranetRequired = 'intranetRequired',
  uploadSizeExceeded = 'uploadSizeExceeded',
  unknown = 'unknown',
}

export interface DraftUpload {
  file: File;
  status: {
    failed: boolean;
    failReason?: DraftFileUploadFailReason;
    progress: number;
    reference?: string;
  };
}

export interface Draft {
  uploads?: DraftUpload[];
  message?: string;
}

export interface Drafts {
  [roomId: string]: Draft;
}

export interface DraftMessageUpdated {
  draftKey: string;
  roomId: string;
  message: string;
}

export interface DraftFilesAdded {
  draftKey: string;
  roomId: string;
  files: File[];
}

export interface DraftFilesRemoved {
  draftKey: string;
  files: File[];
}

export interface DraftFileUploadFailed {
  draftKey: string;
  file: File;
  reason?: DraftFileUploadFailReason;
}

export interface DraftFileUploadProgressed {
  draftKey: string;
  file: File;
  progress: number;
}

export interface DraftFileUploadReferenced {
  draftKey: string;
  file: File;
  reference: string;
}

export interface DraftCleared {
  draftKey: string;
  cancelFiles: boolean;
}
