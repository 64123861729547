
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import ToastEntry from '@/components/shared/toast/ToastEntry.vue';

import type { Toastable } from '@/interfaces/shared/Toast';

@Component({
  components: {
    ToastEntry,
  },
})
export default class ToastList extends Vue {
  @Getter('toasts', { namespace: 'toast' }) public toasts!: Toastable[];
}
