import axios from 'axios';

import config from '@/config';

import type { LoginResponse } from '@/interfaces/shared/User';

export default (refreshToken: string): Promise<Partial<LoginResponse>> => (
  axios.post<Partial<LoginResponse>>(
    `${config.server.baseUrl}/auth/refresh-token`,
    {
      refreshToken,
    },
  ).then((response) => response.data)
);
