import adaptMenu from '@/adapter/menu';
import http from '@/http/menu/menu';
import {
  MenuArea,
  MenuItemType,
  SubmenuItemType,
} from '@/interfaces/menu/Menu';

import type {
  Menu,
  MenuItem,
} from '@/interfaces/menu/Menu';
import type { MenuState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): MenuState => ({
  isLoaded: false,
  isLoading: false,
  hasError: false,
  menu: {
    main: [],
    footer: [],
    social: [],
  },
  flatMenuItems: [],
});

const MenuModule: Module<MenuState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    isLoading(state: MenuState): boolean {
      return state.isLoading;
    },

    isLoaded(state: MenuState): boolean {
      return state.isLoaded;
    },

    hasError(state: MenuState): boolean {
      return state.hasError;
    },

    menu(state: MenuState): Menu {
      return state.menu;
    },

    flatMenuItems(state: MenuState): MenuItem[] {
      return state.flatMenuItems;
    },
  },

  mutations: {
    clear(state: MenuState): void {
      Object.assign(state, initialState());
    },

    setLoading(state: MenuState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

    setLoaded(state: MenuState, isLoaded: boolean): void {
      state.isLoaded = isLoaded;
    },

    setHasError(state: MenuState, hasError: boolean): void {
      state.hasError = hasError;
    },

    setMenu(state: MenuState, menu: Menu): void {
      state.menu = menu;

      const items: MenuItem[] = [];

      Object.values(MenuArea).forEach((area) => {
        menu[area].forEach((entry) => {
          if (entry.type === SubmenuItemType.submenu) {
            entry.items.forEach((item) => {
              items.push(item);
            });
          } else {
            items.push(entry);
          }
        });
      });

      state.flatMenuItems = items;
    },
  },

  actions: {
    async clear({ commit }: ActionContext<MenuState, RootState>): Promise<void> {
      commit('clear');
    },

    async reset({ dispatch }: ActionContext<MenuState, RootState>): Promise<void> {
      await dispatch('clear');
      await dispatch('load');
    },

    async load({ commit, dispatch, state }: ActionContext<MenuState, RootState>): Promise<void> {
      if (!state.isLoading && !state.isLoaded) {
        commit('setLoaded', false);
        commit('setLoading', true);
        commit('setHasError', false);

        http.loadMenu()
          .then((response) => {
            const menu = adaptMenu.fromServer(response);

            commit('setMenu', menu);
            dispatch('updateUnreadCountForMenu', menu);
          })
          .catch(() => {
            commit('setHasError', true);
          })
          .finally(() => {
            commit('setLoaded', true);
            commit('setLoading', false);
          });
      }
    },

    async updateUnreadCountForMenu(
      { dispatch }: ActionContext<MenuState, RootState>,
      menu: Menu,
    ): Promise<void> {
      await dispatch('news/unreadCount/clear', {}, {
        root: true,
      });

      const setUnreadCountForMenuItem = (menuItem: MenuItem): void => {
        const { data } = menuItem;

        if (menuItem.type !== MenuItemType.news || !data.category || !data.showNewsBadge) {
          return;
        }

        dispatch('news/unreadCount/loadUnreadCount', { categoryHash: data.category }, { root: true });
      };

      Object.values(MenuArea).forEach((area) => {
        menu[area].forEach((item) => {
          if (item.type === SubmenuItemType.submenu) {
            item.items.forEach((subItem) => setUnreadCountForMenuItem(subItem));
          } else {
            setUnreadCountForMenuItem(item);
          }
        });
      });
    },
  },
};

export default MenuModule;
