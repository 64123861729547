import adaptContent from '@/adapter/content';
import adaptNews from '@/adapter/news';
import http from '@/http/news/topics';

import type { Content } from '@/interfaces/contents/Content';
import type { NewsCategory, NewsTopic, NewsTopics } from '@/interfaces/news/News';
import type { RootState, NewsTopicsState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): NewsTopicsState => ({
  isLoading: false,
  isLoaded: false,
  hasError: false,
  topics: {},
});

const NewsCategoriesModule: Module<NewsTopicsState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    isLoading(state: NewsTopicsState): boolean {
      return state.isLoading;
    },

    isLoaded(state: NewsTopicsState): boolean {
      return state.isLoaded;
    },

    hasError(state: NewsTopicsState): boolean {
      return state.hasError;
    },

    getTopics(state: NewsTopicsState): NewsTopic[] {
      return Object.values(state.topics);
    },

    getTopic(state: NewsTopicsState): (hash: string) => NewsTopic | undefined {
      return (hash: string) => state.topics[hash];
    },
  },

  mutations: {
    clear(state: NewsTopicsState): void {
      Object.assign(state, initialState());
    },

    setLoading(state: NewsTopicsState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

    setLoaded(state: NewsTopicsState, isLoaded: boolean): void {
      state.isLoaded = isLoaded;
    },

    setHasError(state: NewsTopicsState, hasError: boolean): void {
      state.hasError = hasError;
    },

    addTopics(state: NewsTopicsState, topics: NewsTopics): void {
      const mergedTopics = state.topics;

      Object.keys(topics).forEach((hash) => {
        const topic = topics[hash];

        mergedTopics[hash] = {
          ...mergedTopics[hash],
          ...topic,
        };
      });

      state.topics = {
        ...mergedTopics,
      };
    },
  },

  actions: {
    async clear({ commit }: ActionContext<NewsTopicsState, RootState>): Promise<void> {
      commit('clear');
    },

    async addTopics(
      { commit }: ActionContext<NewsTopicsState, RootState>,
      topics: Partial<NewsCategory>[],
    ): Promise<void> {
      commit('addTopics', adaptNews.topicsFromServer(topics));
    },

    async loadCategoryContents(
      context: ActionContext<NewsTopicsState, RootState>,
      hash: string,
    ): Promise<Content[]> {
      const response = await http.loadCategoryContents(hash);

      return adaptContent.contentsFromServer(response);
    },

    async loadTopics(
      { commit, dispatch }: ActionContext<NewsTopicsState, RootState>,
    ): Promise<void> {
      commit('setLoading', true);
      commit('setLoaded', false);
      commit('setHasError', false);

      try {
        const response = await http.loadTopics();
        await dispatch('addTopics', response);

        commit('setLoaded', true);
      } catch {
        commit('setHasError', true);
      } finally {
        commit('setLoading', false);
      }
    },
  },
};

export default NewsCategoriesModule;
