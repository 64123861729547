import loadAvatar from '@/http/avatar';
import AvatarFormatter from '@/utils/avatar-formatter';
import { blobToDataUrl } from '@/utils/image-loader';

import type { RoomUser, TeamRoom } from '@/interfaces/chat/Room';
import type {
  AvatarAddedToQueue,
  AvatarLoaded,
  Avatars,
  AvatarUpdated,
  MaybeAvatar,
} from '@/interfaces/shared/Avatar';
import type { AvatarsGetters, AvatarsState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const getRoomKey = (id: string): string => `room/${id}`;
const getUserKey = (id: string): string => `user/${id}`;

const initialState = (): AvatarsState => ({
  avatars: {},
  queue: {},
});

const AvatarModule: Module<AvatarsState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    avatars(state: AvatarsState): Avatars {
      return state.avatars;
    },

    avatar(state: AvatarsState, getters: AvatarsGetters): (key: string) => MaybeAvatar {
      return (key: string) => getters.avatars[key];
    },

    roomAvatar(state: AvatarsState, getters: AvatarsGetters): (room: TeamRoom) => MaybeAvatar {
      return (room: TeamRoom) => getters.avatar(getRoomKey(room.id));
    },

    userAvatar(state: AvatarsState, getters: AvatarsGetters): (user: RoomUser) => MaybeAvatar {
      return (user: RoomUser) => getters.avatar(getUserKey(user.id));
    },

    queue(state: AvatarsState): (key: string) => Promise<MaybeAvatar> {
      return (key: string) => state.queue[key];
    },
  },

  mutations: {
    clear(state: AvatarsState): void {
      Object.assign(state, initialState());
    },

    saveAvatar(state: AvatarsState, payload: AvatarUpdated): void {
      state.avatars = {
        ...state.avatars,
        [payload.key]: payload.avatar,
      };
    },

    addAvatarToQueue(state: AvatarsState, payload: AvatarAddedToQueue): void {
      state.queue = {
        ...state.queue,
        [payload.key]: payload.promise,
      };
    },

    removeAvatarFromQueue(state: AvatarsState, key: string): void {
      delete state.queue[key];

      state.queue = {
        ...state.queue,
      };
    },

    clearRoom(state: AvatarsState, room: TeamRoom): void {
      delete state.avatars[getRoomKey(room.id)];

      state.avatars = {
        ...state.avatars,
      };
    },

    clearUser(state: AvatarsState, user: RoomUser): void {
      delete state.avatars[getUserKey(user.id)];

      state.avatars = {
        ...state.avatars,
      };
    },
  },

  actions: {
    async clear({ commit }: ActionContext<AvatarsState, RootState>): Promise<void> {
      commit('clear');
    },

    loadAvatar(
      { commit }: ActionContext<AvatarsState, RootState>,
      payload: AvatarLoaded,
    ): Promise<MaybeAvatar> {
      const promise = payload.loader(payload.id)
        .then((response) => blobToDataUrl(response))
        .then((avatar) => {
          commit('saveAvatar', {
            key: payload.key,
            avatar,
          });

          return avatar;
        })
        .catch(() => {
          const failedAvatar = AvatarFormatter.getFailedAvatar();

          commit('saveAvatar', {
            key: payload.key,
            avatar: failedAvatar,
          });

          return failedAvatar;
        });

      commit('addAvatarToQueue', {
        key: payload.key,
        promise,
      });

      promise.then(() => {
        commit('removeAvatarFromQueue', payload.key);
      });

      return promise;
    },

    loadRoomAvatar(
      { commit, dispatch, getters }: ActionContext<AvatarsState, RootState>,
      room: TeamRoom,
    ): Promise<MaybeAvatar> {
      const typedGetters: AvatarsGetters = getters;

      const { id } = room;
      const key = getRoomKey(id);

      if (!room.hasAvatar) {
        const noAvatar = AvatarFormatter.getNoAvatar();

        commit('saveAvatar', {
          key,
          avatar: noAvatar,
        });

        return Promise.resolve(noAvatar);
      }

      const avatar = typedGetters.roomAvatar(room);

      if (AvatarFormatter.isCached(avatar)) {
        return Promise.resolve(avatar);
      }

      const queuedRequest = typedGetters.queue(key);

      if (queuedRequest) {
        return queuedRequest;
      }

      const typesafeLoadAvatar = (payload: AvatarLoaded): Promise<MaybeAvatar> => dispatch('loadAvatar', payload);

      return typesafeLoadAvatar({
        id,
        key,
        loader: loadAvatar.forRoom,
      });
    },

    loadUserAvatar(
      { commit, dispatch, getters }: ActionContext<AvatarsState, RootState>,
      user: RoomUser,
    ): Promise<MaybeAvatar> {
      const typedGetters: AvatarsGetters = getters;
      const { id } = user;
      const key = getUserKey(id);

      if (!user.hasAvatar) {
        const noAvatar = AvatarFormatter.getNoAvatar();

        commit('saveAvatar', {
          key,
          avatar: noAvatar,
        });

        return Promise.resolve(noAvatar);
      }

      const avatar = typedGetters.userAvatar(user);

      if (AvatarFormatter.isCached(avatar)) {
        return Promise.resolve(avatar);
      }

      const queuedRequest = typedGetters.queue(key);

      if (queuedRequest) {
        return queuedRequest;
      }

      const typesafeLoadAvatar = (payload: AvatarLoaded): Promise<MaybeAvatar> => dispatch('loadAvatar', payload);

      return typesafeLoadAvatar({
        id,
        key,
        loader: loadAvatar.forUser,
      });
    },

    refreshRoom(
      { commit, dispatch }: ActionContext<AvatarsState, RootState>,
      room: TeamRoom,
    ): void {
      commit('clearRoom', room);
      dispatch('loadRoomAvatar', room);
    },

    refreshUser(
      { commit, dispatch }: ActionContext<AvatarsState, RootState>,
      user: RoomUser,
    ): void {
      commit('clearUser', user);
      dispatch('loadUserAvatar', user);
    },
  },
};

export default AvatarModule;
