export enum MenuArea {
  main = 'main',
  footer = 'footer',
  social = 'social',
}

export enum MenuItemType {
  chat = 'chat',
  external = 'external',
  home = 'home',
  infocenter = 'infocenter',
  internal = 'internal',
  jobcenter = 'jobcenter',
  news = 'news',
}

export enum SubmenuItemType {
  submenu = 'submenu',
}

export interface MenuItem {
  type: MenuItemType;
  title: string;
  active: boolean;
  icon?: string;
  data: {
    link?: string | undefined;
    hash?: string | undefined;
    category?: string | undefined;
    topic?: string | undefined;
    layout?: string | undefined;
    showNewsBadge?: boolean | undefined;
  };
}

export interface Submenu {
  title: string;
  type: SubmenuItemType.submenu;
  active: boolean;
  items: MenuItem[];
}

export interface Menu {
  [MenuArea.main]: Array<MenuItem|Submenu>;
  [MenuArea.footer]: Array<MenuItem|Submenu>;
  [MenuArea.social]: Array<MenuItem|Submenu>;
}

export interface ServerMenuItem {
  title?: string;
  type?: string;
  icon?: string;
  data?: {
    [key: string]: string | undefined;
  };
}

export interface ServerSubmenu {
  title?: string;
  type?: string;
  items?: ServerMenuItem[];
}

export interface ServerMenu {
  [MenuArea.main]?: Array<ServerMenuItem|ServerSubmenu>;
  [MenuArea.footer]?: Array<ServerMenuItem|ServerSubmenu>;
  [MenuArea.social]?: Array<ServerMenuItem|ServerSubmenu>;
}
