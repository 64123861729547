
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class NotificationState extends Vue {
  @Getter('hasNotifications', { namespace: 'chat/room' }) public hasNotifications!: boolean;

  @Watch('hasNotifications', { immediate: true })
  public onHasNotificationsChanged(newValue: boolean, oldValue: boolean): void {
    if (newValue === oldValue) {
      return;
    }

    if (this.hasNotifications) {
      this.startTitleAndFaviconChange();
    } else {
      this.stopTitleAndFaviconChange();
    }
  }

  private hasNotificationsTitle = 'Neue Nachrichten';

  private faviconId = 'favicon';

  private documentTitle = document.title;

  private documentFavicon?: string;

  private documentTitleInterval?: number;

  private documentTitleChangeMillis = 2000;

  private documentTitleChanged = false;

  private startTitleAndFaviconChange(): void {
    this.setFavicon();

    this.documentTitleInterval = window.setInterval(() => {
      if (this.documentTitleChanged) {
        this.unsetTitle();
      } else {
        this.setTitle();
      }

      this.documentTitleChanged = !this.documentTitleChanged;
    }, this.documentTitleChangeMillis);
  }

  private stopTitleAndFaviconChange(): void {
    this.unsetFavicon();

    clearInterval(this.documentTitleInterval);
    this.unsetTitle();
  }

  private setTitle(): void {
    this.documentTitle = document.title;
    document.title = this.hasNotificationsTitle;
  }

  private unsetTitle(): void {
    document.title = this.documentTitle;
  }

  private setFavicon(): void {
    const faviconElement = this.getFaviconElement();
    const newFavicon = faviconElement?.dataset.notificationFavicon;

    if (faviconElement && newFavicon) {
      this.documentFavicon = faviconElement.getAttribute('href') || undefined;
      faviconElement.setAttribute('href', newFavicon);
    }
  }

  private unsetFavicon(): void {
    const faviconElement = this.getFaviconElement();

    if (faviconElement && this.documentFavicon) {
      faviconElement.setAttribute('href', this.documentFavicon);
    }
  }

  private getFaviconElement(): HTMLLinkElement | undefined {
    const element = document.getElementById(this.faviconId);

    if (element instanceof HTMLLinkElement) {
      return element;
    }

    return undefined;
  }
}
