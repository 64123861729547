import axios from 'axios';

import config from '@/config';

export default {
  forRoom: (id: string): Promise<Blob> => (
    axios.get<Blob>(
      `${config.versionedServerUrl()}/chat/room/avatar`,
      {
        responseType: 'blob',
        params: { id },
      },
    ).then((res) => res.data)
  ),

  forUser: (id: string): Promise<Blob> => (
    axios.get<Blob>(
      `${config.versionedServerUrl()}/user/avatar`,
      {
        responseType: 'blob',
        params: { id },
      },
    ).then((res) => res.data)
  ),
};
