import http from '@/http/jobs/count';
import details from '@/store/jobs/details';
import list from '@/store/jobs/list';

import type { JobBaseItem } from '@/interfaces/jobs/Jobs';
import type { JobsState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const modules = {
  details,
  list,
};

const initialState = (): JobsState => ({
  currentJobHash: undefined,
  unreadCount: 0,
});

const JobsModule: Module<JobsState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    currentJobHash(state: JobsState): string | undefined {
      return state.currentJobHash;
    },

    unreadCount(state: JobsState): number {
      return state.unreadCount;
    },
  },

  mutations: {
    clear(state: JobsState): void {
      Object.assign(state, initialState());
    },

    setCurrentJobHash(state: JobsState, hash: string): void {
      state.currentJobHash = hash;
    },

    setUnreadCount(state: JobsState, unreadCount: number): void {
      state.unreadCount = unreadCount;
    },
  },

  actions: {
    async clear({ commit, dispatch }: ActionContext<JobsState, RootState>): Promise<void> {
      commit('clear');

      Object.keys(modules).forEach((module) => (
        dispatch(`${module}/clear`)
      ));
    },

    async fetchUnreadCount(
      { commit }: ActionContext<JobsState, RootState>,
    ): Promise<void> {
      const unreadCount = await http.getUnreadCount();

      commit('setUnreadCount', unreadCount);
    },

    async markJobRead(
      { dispatch }: ActionContext<JobsState, RootState>,
      job: JobBaseItem,
    ): Promise<void> {
      await http.markJobRead(job.hash);

      await dispatch('jobs/list/setJobRead', { hash: job.hash, read: true }, { root: true });
      await dispatch('fetchUnreadCount');
    },

    async forceJobRead(
      { dispatch }: ActionContext<JobsState, RootState>,
      job: JobBaseItem,
    ): Promise<void> {
      await http.forceJobRead(job.hash);

      await dispatch('jobs/list/setJobRead', { hash: job.hash, read: true }, { root: true });
      await dispatch('fetchUnreadCount');
    },

    async forceJobUnread(
      { dispatch }: ActionContext<JobsState, RootState>,
      job: JobBaseItem,
    ): Promise<void> {
      await http.forceJobUnread(job.hash);

      await dispatch('jobs/list/setJobRead', { hash: job.hash, read: false }, { root: true });
      await dispatch('fetchUnreadCount');
    },
  },

  modules,
};

export default JobsModule;
