import { getAppBridge } from '@/bridge/app/BridgeProvider';
import { LoginLocalState } from '@/interfaces/shared/User';

interface BridgeStorageState {
  [LoginLocalState.userId]?: string | undefined;
  [LoginLocalState.token]?: string | undefined;
  [LoginLocalState.refreshToken]?: string | undefined;
}

const bridge = getAppBridge();

const storageKeys = [
  LoginLocalState.userId,
  LoginLocalState.token,
  LoginLocalState.refreshToken,
];

export default {
  async save(): Promise<void> {
    const state: BridgeStorageState = {};

    storageKeys.forEach((key) => {
      state[key] = localStorage.getItem(key) || undefined;
    });

    let payload = '';

    try {
      payload = JSON.stringify(state);
    } catch (error) {
      payload = '{}';
    }

    bridge.setStorage(payload);
  },

  async load(): Promise<void> {
    const payload = await bridge.getStorage();
    let state: BridgeStorageState = {};

    try {
      state = JSON.parse(payload || '{}');
    } catch (error) {
      state = {};
    }

    storageKeys.forEach((key) => {
      const value = state[key];

      if (value) {
        localStorage.setItem(key, value);
      }
    });
  },
};
