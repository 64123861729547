
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import RoomInfo from '@/components/chat/RoomInfo.vue';
import RoomName from '@/components/chat/RoomName.vue';
import RoomBadge from '@/components/shared/badge/RoomBadge.vue';

import type { ChatView } from '@/interfaces/chat/Chat';
import type { Room } from '@/interfaces/chat/Room';

@Component({
  components: {
    RoomBadge,
    RoomInfo,
    RoomName,
  },
})
export default class ChatHeader extends Vue {
  @Getter('currentChatView', { namespace: 'chat' }) public currentChatView?: ChatView;

  @Getter('currentRoom', { namespace: 'chat/room' }) public currentRoom?: Room;
}
