import { RoomType } from '@/interfaces/chat/Room';
import store from '@/store';

import type { Room, RoomUser } from '@/interfaces/chat/Room';

export default class RoomFormatter {
  public static minimumForUserCount = 2;

  public static userCanAccessParticipants(room: Room): boolean {
    if (room.type === RoomType.single) {
      return false;
    }

    return this.userIsInRoom(room);
  }

  public static userCanClose(room: Room): boolean {
    if (room.type !== RoomType.group) {
      return false;
    }

    return !this.userIsInRoom(room);
  }

  public static userCanEdit(room: Room): boolean {
    if (room.type !== RoomType.group) {
      return false;
    }

    return this.userIsInRoom(room);
  }

  public static userCanInvite(room: Room): boolean {
    if (room.type !== RoomType.group) {
      return false;
    }

    return this.userIsInRoom(room);
  }

  public static userCanLeave(room: Room): boolean {
    if (room.type !== RoomType.group) {
      return false;
    }

    return this.userIsInRoom(room);
  }

  public static userCanParticipate(room: Room): boolean {
    return this.userIsInRoom(room);
  }

  public static userIsInRoom(room: Room): boolean {
    const userId = RoomFormatter.getCurrentUserId();

    return userId !== undefined && room.userIds.includes(userId);
  }

  public static getName(room: Room, limit?: number): string {
    if (room.title) {
      return room.title;
    }

    return RoomFormatter.getUserNames(room, limit).join(', ');
  }

  public static getUserNames(room: Room, limit?: number): string[] {
    if (room.type === RoomType.team) {
      return [room.title];
    }

    let users;

    if (room.type === RoomType.single) {
      users = RoomFormatter.getOtherUsers(room);
    } else {
      users = RoomFormatter.getUsers(room);
    }

    return users
      .slice(0, limit)
      .map((user) => user.name);
  }

  public static getOtherUsers(room: Room): RoomUser[] {
    const currentUserId = this.getCurrentUserId();

    return RoomFormatter
      .getUsers(room)
      .filter((user) => currentUserId !== user.id);
  }

  public static getUsers(room: Room): RoomUser[] {
    return room.userIds
      .map((userId): RoomUser => store.getters['user/user'](userId))
      .sort((user1, user2) => user1.name.localeCompare(user2.name));
  }

  public static getActiveUsers(room: Room): RoomUser[] {
    return RoomFormatter.getUsers(room)
      .filter((user) => !user.isDisabled && !user.isChatDisabled);
  }

  public static hasMultipleActiveUsers(room?: Room): boolean {
    if (!room) {
      return false;
    }
    return RoomFormatter.getActiveUsers(room).length > 1;
  }

  public static getAdditionalUserCount(room: Room, limit = 99): number {
    if (room.type === RoomType.single || room.type === RoomType.team) {
      return 0;
    }

    return Math.min(limit, Math.max(0, room.userIds.length - RoomFormatter.minimumForUserCount));
  }

  public static getChatViewUrl(roomId: string, roomType: RoomType): string {
    const prefix = {
      [RoomType.single]: 'chats',
      [RoomType.group]: 'groups',
      [RoomType.team]: 'teams',
    };

    return `/${prefix[roomType] || 'chats'}/${roomId}`;
  }

  private static getCurrentUserId(): string | undefined {
    return store.getters['user/currentUser']?.id;
  }
}
