import type {
  Anchor,
  DefaultImage,
  ImageResource,
  ImageSource,
  Content,
} from '@/interfaces/contents/Content';

const adapter = {
  contentsFromServer: (contents?: Content[]): Content[] => {
    // TODO: Adapt content individually?
    const safeContents = (Array.isArray(contents) ? contents : []);

    return safeContents.map((safeContent) => {
      const content = safeContent;

      if (content.type === 'anchorlist') {
        content.anchors = safeContents.filter((anchor): anchor is Anchor => anchor.type === 'anchor');
      }

      return content;
    });
  },

  imageFromServer: (image?: Partial<ImageResource>): ImageResource => ({
    alt: image?.alt || '',
    copyright: image?.copyright || '',
    img: adapter.defaultImageFromServer(image?.img),
    sources: adapter.sourcesFromServer(image?.sources),
    title: image?.title || '',
  }),

  defaultImageFromServer: (image?: Partial<DefaultImage>): DefaultImage => ({
    height: image?.height || 0,
    src: image?.src || '',
    width: image?.width || 0,
    visible: false,
  }),

  sourcesFromServer: (sources?: Partial<ImageSource>[]): ImageSource[] => (
    Array.isArray(sources)
      ? sources.map((source) => adapter.sourceFromServer(source))
      : []
  ),

  sourceFromServer: (source?: Partial<ImageSource>): ImageSource => ({
    height: source?.height || 0,
    media: source?.media || '',
    srcset: source?.srcset || '',
    type: source?.type || '',
    width: source?.width || 0,
    visible: false,
  }),
};

export default adapter;
