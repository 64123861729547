import axios from 'axios';

import config from '@/config';

import type { SocketUser, SocketUserSearchPayload } from '@/interfaces/shared/Socket';
import type { UnsafePaginated } from '@/interfaces/shared/UnsafePaginated';

export default (payload: SocketUserSearchPayload):
  Promise<UnsafePaginated<SocketUser>> => (
  axios.get<UnsafePaginated<SocketUser>>('/user/search', {
    baseURL: config.versionedSocketApiUrl(),
    params: payload,
  }).then((res) => res.data)
);
