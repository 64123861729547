import adapter from '@/adapter/jobs';
import http from '@/http/jobs/details';

import type { JobDetailItem } from '@/interfaces/jobs/Jobs';
import type { RootState, JobsDetailState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): JobsDetailState => ({
  isLoading: false,
  hasError: false,
  job: undefined,
});

const JobsDetailsModule: Module<JobsDetailState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    isLoading: (state: JobsDetailState): boolean => state.isLoading,

    hasError: (state: JobsDetailState): boolean => state.hasError,

    job: (state: JobsDetailState): JobDetailItem | undefined => state.job,
  },

  mutations: {
    clear(state: JobsDetailState): void {
      Object.assign(state, initialState());
    },

    setLoading(state: JobsDetailState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

    setHasError(state: JobsDetailState, hasError: boolean): void {
      state.hasError = hasError;
    },

    setJob(state: JobsDetailState, job?: JobDetailItem): void {
      state.job = job;
    },
  },

  actions: {
    async clear({ commit }: ActionContext<JobsDetailState, RootState>): Promise<void> {
      commit('clear');
    },

    async loadJob(
      { commit, state }: ActionContext<JobsDetailState, RootState>,
      jobHash: string,
    ): Promise<void> {
      if (state.isLoading) {
        return;
      }

      commit('setLoading', true);
      commit('setHasError', false);
      commit('setJob', undefined);

      await http.loadJob(jobHash)
        .then((response) => {
          commit('setJob', adapter.detailsItemFromServer(response));
        })
        .catch(() => {
          commit('setHasError', true);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
  },
};

export default JobsDetailsModule;
