
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

@Component
export default class NewsState extends Vue {
  @Mutation('setCurrentNewsHash', { namespace: 'news' }) public setCurrentNewsHash!: (id?: string) => void;

  @Watch('$route.params.news', { immediate: true })
  public onNewsHashChanged(hash?: string): void {
    this.setCurrentNewsHash(hash);
  }
}
