import numberAdapter from '@/adapter/number';
import { RoomType } from '@/interfaces/chat/Room';

import type { Room } from '@/interfaces/chat/Room';
import type { SocketRoom } from '@/interfaces/shared/Socket';

const adapter = {
  fromSocket: (room?: SocketRoom): Room => {
    const type = adapter.typeFromString(room?.type);

    return {
      id: room?.id || '',
      userIds: room?.userIds || [],
      type,
      title: room?.title || '',
      initials: adapter.initials(room?.title),
      hasAvatar: room?.hasAvatar || false,
      notifications: numberAdapter.numberOrUndefined(room?.notifications),
      lastMessage: room?.lastMessage || '',
      lastMessageTime: room?.lastMessageTime || 0,
      lastMessageUserId: room?.lastMessageUserId || '',
      lastReadMessageTime: numberAdapter.numberOrUndefined(room?.lastReadMessageTime),
    };
  },

  initials: (title?: string): string | undefined => {
    if (!title) {
      return undefined;
    }

    const initials = title.match(/\b\w/g) || [];

    if (initials.length >= 2) {
      return `${adapter.initial(initials.shift())}${adapter.initial(initials.pop())}`;
    }

    return undefined;
  },

  initial: (name?: string): string => {
    const initial = (name || '').split('').shift() || '';

    return initial.toUpperCase();
  },

  typeFromString: (type?: string): RoomType => (
    RoomType[<keyof typeof RoomType> type] || RoomType.single
  ),
};

export default adapter;
