import type { Bridge } from '@/bridge/app/Bridge';

class WebBridge implements Bridge {
  os = (): 'android'|'ios'|'browser' => 'browser';

  on = (): void => undefined;

  open = (url: string): void => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: url,
    }).click();
  };

  subscribeToTopics = (): void => undefined;

  unsubscribeFromTopics = (): void => undefined;

  setStorage = (): void => undefined;

  getStorage = (): Promise<string | null> => Promise.resolve(null);
}

export const openWebBridge = (): WebBridge => new WebBridge();

export default openWebBridge;
