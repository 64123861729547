import type { Status } from '@/interfaces/shared/User';

export enum RoomType {
  group = 'group',
  single = 'single',
  team = 'team',
}

export enum RoomView {
  files = 'files',
  invite = 'invite',
  messages = 'messages',
  participants = 'participants',
}

export interface RoomUser {
  id: string;
  email?: string;
  name: string;
  firstName: string;
  lastName: string;
  initials: string;
  notifications?: number;
  status: Status;
  isDeleted: boolean;
  isDisabled: boolean;
  isChatDisabled: boolean
  hasAvatar: boolean;
}

export interface RoomUsers {
  [userId: string]: RoomUser | undefined;
}

interface BaseRoom {
  id: string;
  title?: string;
  initials?: string;
  userIds: string[];
  lastReadMessageTime?: number;
  lastMessage?: string;
  lastMessageUserId?: string;
  lastMessageTime?: number;
  notifications?: number;
}

export interface SingleRoom extends BaseRoom {
  type: RoomType.single;
}

export interface GroupRoom extends BaseRoom {
  type: RoomType.group;
}

export interface TeamRoom extends BaseRoom {
  type: RoomType.team;
  title: string;
  hasAvatar: boolean;
}

export type Room = SingleRoom | GroupRoom | TeamRoom;

export interface Rooms {
  [roomId: string]: Room | undefined;
}

export interface SingleRoomCreated {
  userIds: string[];
}

export interface GroupRoomCreated {
  userIds: string[];
  title?: string;
}

export interface GroupRoomEdited {
  id: string;
  title?: string;
}

export interface GroupRoomInvited {
  id: string;
  userIds: string[];
}

export interface RoomTypingStart {
  userId: string;
  roomId: string;
  timeout: number;
}

export interface RoomTypingEnd {
  userId: string;
  roomId: string;
}
