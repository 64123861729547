import http from '@/http/news/unread-count';

import type { RootState, NewsUnreadCountState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): NewsUnreadCountState => ({
  categories: {},
});

const UnreadCountModule: Module<NewsUnreadCountState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    count(state: NewsUnreadCountState): (categoryHash: string) => number | undefined {
      return (categoryHash) => state.categories[categoryHash];
    },
  },

  mutations: {
    clear(state: NewsUnreadCountState): void {
      Object.assign(state, initialState());
    },

    update(
      state: NewsUnreadCountState,
      { categoryHash, count }: { categoryHash: string, count: number },
    ): void {
      state.categories = {
        ...state.categories,
        [categoryHash]: count,
      };
    },
  },

  actions: {
    async clear(
      { commit }: ActionContext<NewsUnreadCountState, RootState>,
    ): Promise<void> {
      commit('clear');
    },

    async loadUnreadCountIfSet(
      { dispatch, state }: ActionContext<NewsUnreadCountState, RootState>,
      { categoryHash }: { categoryHash: string },
    ): Promise<void> {
      if (state.categories[categoryHash] === undefined) {
        return;
      }

      await dispatch('loadUnreadCount', { categoryHash });
    },

    async loadUnreadCount(
      { commit }: ActionContext<NewsUnreadCountState, RootState>,
      { categoryHash }: { categoryHash: string },
    ): Promise<void> {
      commit('update', { categoryHash, count: 0 });

      const count = await http.loadUnreadCount(categoryHash);

      commit('update', { categoryHash, count });
    },
  },
};

export default UnreadCountModule;
