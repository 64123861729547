export enum ToastType {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export interface ToastableButton {
  enabled: boolean;
  label: string;
  action: () => void;
}

export interface Toastable {
  type: ToastType;
  content: string;
  ttlSeconds: number;
  closable: boolean;
  buttons: ToastableButton[];
}
