
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { RoomType } from '@/interfaces/chat/Room';

@Component
export default class CreateChatRoomHeader extends Vue {
  @Getter('creatingRoom', { namespace: 'chat/room' }) public creatingRoom?: RoomType;

  public get creatingSingleRoom(): boolean {
    return this.creatingRoom === RoomType.single;
  }

  public get creatingGroupRoom(): boolean {
    return this.creatingRoom === RoomType.group;
  }
}
