import adaptMessage from '@/adapter/message';
import http from '@/http/chat/message';
import { MessageType } from '@/interfaces/chat/Message';
import {
  SocketChatEvent,
} from '@/interfaces/shared/Socket';

import type {
  Message,
  MessageFromUser,
  MessageReactionUpdate,
  Messages,
  MessageSent,
} from '@/interfaces/chat/Message';
import type { Paginated } from '@/interfaces/shared/Paginated';
import type {
  SocketMessage,
  SocketMessageAnswersPayload,
  SocketMessageListPayload,
} from '@/interfaces/shared/Socket';
import type { MessageGetters, MessageState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const sortByDate = (message1: Message, message2: Message): number => (
  message1.sortTs - message2.sortTs
);

const initialState = (): MessageState => ({
  messages: {},
  answers: {},
  loadedRoomIds: new Set<string>(),
  loadedAnswerParentIds: new Set<string>(),
  sendingMessage: false,
});

const MessageModule: Module<MessageState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    allMessages(state: MessageState): Messages {
      return state.messages;
    },

    messages(state: MessageState, getters: MessageGetters): (roomId?: string) => Message[] {
      return (roomId?: string): Message[] => {
        if (!roomId) {
          return [];
        }

        const messages = getters.allMessages[roomId];

        if (!messages) {
          return [];
        }

        return Object.values(messages).sort(sortByDate);
      };
    },

    allAnswers(state: MessageState): Messages {
      return state.answers;
    },

    answers(state: MessageState, getters: MessageGetters): (parentId?: string) => Message[] {
      return (parentId?: string): Message[] => {
        if (!parentId) {
          return [];
        }

        const answers = getters.allAnswers[parentId];

        if (!answers) {
          return [];
        }

        return Object.values(answers).sort(sortByDate);
      };
    },

    sendingMessage(state: MessageState): boolean {
      return state.sendingMessage;
    },
  },

  mutations: {
    clear(state: MessageState): void {
      Object.assign(state, initialState());
    },

    updateAnswer(state: MessageState, message: MessageFromUser): void {
      if (!message.parentId) {
        return;
      }

      const oldMessage = state.answers?.[message.parentId]?.[message.id];
      const sortTs = oldMessage?.sortTs || message.sortTs;
      const sortTsUpdate = message.sortTs;

      state.answers = {
        ...state.answers,
        [message.parentId]: {
          ...state.answers[message.parentId],
          [message.id]: {
            ...message,
            sortTs,
            sortTsUpdate,
          },
        },
      };
    },

    updateMessage(state: MessageState, message: Message): void {
      const oldMessage = state.messages?.[message.roomId]?.[message.id];
      const sortTs = oldMessage?.sortTs || message.sortTs;
      const sortTsUpdate = message.sortTs;

      state.messages = {
        ...state.messages,
        [message.roomId]: {
          ...state.messages[message.roomId],
          [message.id]: {
            ...message,
            sortTs,
            sortTsUpdate,
          },
        },
      };
    },

    triggerMessageReorder(state: MessageState, roomId: string): void {
      const messages = state.messages[roomId] || {};

      Object.keys(messages).forEach((messageId) => {
        const message = messages[messageId];
        const answers = state.answers[messageId] || {};

        if (message) {
          message.sortTs = message.sortTsUpdate;
        }

        Object.keys(answers).forEach((answerId) => {
          const answer = messages[answerId];

          if (answer) {
            answer.sortTs = answer.sortTsUpdate;
          }
        });
      });

      state.messages = {
        ...state.messages,
      };

      state.answers = {
        ...state.answers,
      };
    },

    setRoomLoaded(state: MessageState, roomId: string): void {
      state.loadedRoomIds.add(roomId);
    },

    setAnswersLoaded(state: MessageState, parentId: string): void {
      state.loadedAnswerParentIds.add(parentId);
    },

    setSendingMessage(state: MessageState, sendingMessage: boolean): void {
      state.sendingMessage = sendingMessage;
    },
  },

  actions: {
    async clear(
      { commit }: ActionContext<MessageState, RootState>,
    ): Promise<void> {
      commit('clear');
    },

    async toggleReaction(
      context: ActionContext<MessageState, RootState>,
      payload: MessageReactionUpdate,
    ): Promise<void> {
      return http.toggleReaction(payload).then(() => undefined);
    },

    async sendMessage(
      context: ActionContext<MessageState, RootState>,
      message: MessageSent,
    ): Promise<void> {
      return http.sendMessage(message).then(() => undefined);
    },

    async deleteMessage(
      context: ActionContext<MessageState, RootState>,
      messageId: string,
    ): Promise<void> {
      return http.deleteMessage(messageId).then(() => undefined);
    },

    async loadMessages(
      { commit, state }: ActionContext<MessageState, RootState>,
      payload: SocketMessageListPayload,
    ): Promise<Paginated<Message>> {
      if (state.loadedRoomIds.has(payload.chat)) {
        return {
          total: 0,
          entries: [],
        };
      }

      const result = await http.loadMessages(payload);

      if (result?.entries?.length === undefined || result?.total === undefined) {
        return {
          total: 0,
          entries: [],
        };
      }

      const entries = result.entries.map((entry) => (
        adaptMessage.fromSocket(entry)
      ));

      entries.forEach((entry) => {
        commit('updateMessage', entry);
      });

      const { total } = result;

      if (entries.length >= total) {
        commit('setRoomLoaded', payload.chat);
      }

      return {
        total,
        entries,
      };
    },

    async loadAnswers(
      { commit, state }: ActionContext<MessageState, RootState>,
      payload: SocketMessageAnswersPayload,
    ): Promise<Paginated<Message>> {
      if (state.loadedAnswerParentIds.has(payload.message)) {
        return {
          total: 0,
          entries: [],
        };
      }

      const result = await http.loadAnswers(payload);

      if (result.entries?.length === undefined || result.total === undefined) {
        return {
          total: 0,
          entries: [],
        };
      }

      const entries = result.entries.map((entry) => (
        adaptMessage.fromSocket(entry)
      ));

      entries.forEach((entry) => {
        commit('updateAnswer', entry);
      });

      const { total } = result;

      if (entries.length >= total) {
        commit('setAnswersLoaded', payload.message);
      }

      return {
        total,
        entries,
      };
    },

    async [SocketChatEvent.messageUpdate](
      { commit, dispatch, getters }: ActionContext<MessageState, RootState>,
      message?: SocketMessage,
    ): Promise<void> {
      const typedGetters: MessageGetters = getters;
      const updatedMessage = adaptMessage.fromSocket(message);
      const roomMessages = typedGetters.allMessages[updatedMessage.roomId];
      let isNewAnswer = false;

      if (updatedMessage.type === MessageType.user && updatedMessage.parentId) {
        const parentMessage = roomMessages?.[updatedMessage.parentId];

        if (parentMessage?.type === MessageType.user) {
          const allAnswers = typedGetters.allAnswers[parentMessage.id];
          isNewAnswer = !allAnswers?.[updatedMessage.id];

          if (isNewAnswer) {
            parentMessage.answerCount = (parentMessage.answerCount || 0) + 1;
            commit('updateMessage', parentMessage);
          }
        }

        commit('updateAnswer', updatedMessage);
      } else {
        commit('updateMessage', updatedMessage);
      }

      if (updatedMessage.type === MessageType.user) {
        commit('chat/room/unsetUserTyping', {
          userId: updatedMessage.userId,
          roomId: updatedMessage.roomId,
        }, {
          root: true,
        });

        const isAnswer = !!updatedMessage.parentId;
        const isNewMessage = !isAnswer && !roomMessages?.[updatedMessage.id];

        if (isNewMessage || isNewAnswer) {
          dispatch('user/newMessage', updatedMessage, {
            root: true,
          });
        }
      }

      if (updatedMessage.type === MessageType.system && updatedMessage.countsAsNotification) {
        dispatch('user/newMessage', updatedMessage, {
          root: true,
        });
      }
    },
  },
};

export default MessageModule;
