var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeSubmenu),expression:"closeSubmenu"}],on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;$event.preventDefault();return _vm.onClose()}}},_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[(item.type === _vm.SubmenuItemType.submenu)?_c('div',{class:_vm.dropdownClass},[_c('button',{staticClass:"relative overflow-hidden",class:{
          [_vm.activeColor]: item === _vm.openedSubmenu || item.active,
          [_vm.inactiveColor]: item !== _vm.openedSubmenu,
          [_vm.menuButtonClass]: true,
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleSubmenu(item)}}},[(_vm.showChevrons)?_c('mdicon',{staticClass:"block w-6 h-6 mr-3",attrs:{"name":item === _vm.openedSubmenu ? 'chevronUp' : 'chevronDown',"width":24,"height":24}}):_vm._e(),(_vm.getNotificationCount(item))?_c('Badge',{staticClass:"absolute right-0 top-1/2 -translate-y-1/2 mr-1 lg:-mt-2 lg:-mr-2",attrs:{"number":_vm.getNotificationCount(item)}}):_vm._e(),_c('span',{staticClass:"relative inline-block break-words"},[_vm._v(_vm._s(item.title))])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item === _vm.openedSubmenu),expression:"item === openedSubmenu"}],class:_vm.menuClass},_vm._l((item.items),function(subitem,subitemIndex){return _c('NavigationItem',{key:subitemIndex,class:{
            [_vm.subitemClass]: true,
          },attrs:{"item":subitem,"active-color":_vm.activeColor,"inactive-color":_vm.inactiveColor,"show-chevrons":_vm.showChevrons,"is-submenu":true},on:{"activeChange":function($event){return _vm.updateActiveState(item)}},nativeOn:{"click":function($event){return _vm.onClose()}}})}),1)]):_c('NavigationItem',{class:{
        [_vm.itemClass]: true,
      },attrs:{"item":item,"active-color":_vm.activeColor,"inactive-color":_vm.inactiveColor,"show-chevrons":_vm.showChevrons},nativeOn:{"click":function($event){return _vm.onClose()}}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }