export const blobToDataUrl = (blob: Blob): Promise<string> => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (): void => {
      resolve(<string> reader.result);
    };

    reader.onerror = (): void => {
      reject();
    };

    reader.readAsDataURL(blob);
  })
);

export default {
  blobToDataUrl,
};
