
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Renderless extends Vue {
  private renderlessTemplateString = '';

  public render(): string {
    return this.renderlessTemplateString;
  }
}
