
import { Component, Mixins } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import ChatHeader from '@/components/header/ChatHeader.vue';
import CreateChatRoomHeader from '@/components/header/CreateChatRoomHeader.vue';
import DefaultHeader from '@/components/header/DefaultHeader.vue';
import SocketErrors from '@/components/shared/errors/SocketErrors.vue';
import ToastList from '@/components/shared/toast/ToastList.vue';
import ChatState from '@/mixins/state/ChatState.vue';
import DragState from '@/mixins/state/DragState.vue';
import JobsState from '@/mixins/state/JobsState.vue';
import LayoutState from '@/mixins/state/LayoutState.vue';
import NewsState from '@/mixins/state/NewsState.vue';
import NotificationState from '@/mixins/state/NotificationState.vue';
import UserState from '@/mixins/state/UserState.vue';

import type { Room, RoomType } from '@/interfaces/chat/Room';

@Component({
  components: {
    ChatHeader,
    CreateChatRoomHeader,
    DefaultHeader,
    SocketErrors,
    ToastList,
  },
})
export default class App extends Mixins(
  ChatState,
  DragState,
  JobsState,
  LayoutState,
  NewsState,
  NotificationState,
  UserState,
) {
  @Getter('currentRoom', { namespace: 'chat/room' }) public currentRoom?: Room;

  @Getter('creatingRoom', { namespace: 'chat/room' }) public creatingRoom?: RoomType;
}
