import type { MaybeAvatar } from '@/interfaces/shared/Avatar';

export default class AvatarFormatter {
  public static isCached(avatar: MaybeAvatar): boolean {
    return avatar !== undefined && avatar !== AvatarFormatter.getNoAvatar();
  }

  public static getFailedAvatar(): MaybeAvatar {
    return null;
  }

  public static getNoAvatar(): MaybeAvatar {
    return '';
  }
}
