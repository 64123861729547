export interface MenuOptions {
  onOpen?: () => void;
  onClose?: () => void;
}

export interface Menu {
  visible: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
}

export default (options?: MenuOptions): Menu => {
  const menu = {
    visible: false,

    toggle: (): void => {
      if (menu.visible) {
        menu.close();
      } else {
        menu.open();
      }
    },

    open: (): void => {
      if (menu.visible) {
        return;
      }

      menu.visible = true;
      options?.onOpen?.();
    },

    close: (): void => {
      if (!menu.visible) {
        return;
      }

      menu.visible = false;
      options?.onClose?.();
    },
  };

  return menu;
};
