import axios from 'axios';

import config from '@/config';

import type { JobListItem, JobsSearchParameters } from '@/interfaces/jobs/Jobs';
import type { AxiosResponse } from 'axios';

export default {
  loadJobs: (
    params?: JobsSearchParameters,
  ): Promise<AxiosResponse<Partial<JobListItem>[]>> => (
    axios.get<Partial<JobListItem>[]>(`${config.versionedServerUrl()}/jobs`, {
      params,
    })
  ),
};
