/**
 * Events that can be emitted to the app
 */
export enum Events {
  /**
   * App was closed.
   */
  Close = 'close',

  /**
   * Login was successful.
   */
  Login = 'login',

  /**
   * Logout was performed.
   */
  Logout = 'logout',
}

export default Events;
