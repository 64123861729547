import axios from 'axios';

import config from '@/config';

import type { LoginRequest, LoginResponse } from '@/interfaces/shared/User';

export default (payload: LoginRequest): Promise<Partial<LoginResponse>> => (
  axios.post<Partial<LoginResponse>>(
    `${config.server.baseUrl}/auth/login-check`,
    payload,
  ).then((response) => response.data)
);
