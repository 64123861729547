
import { Component, Vue, Prop } from 'vue-property-decorator';

import { Status, statusList } from '@/interfaces/shared/User';

@Component
export default class StatusIcon extends Vue {
  @Prop() public status!: Status;

  @Prop() public showNotifications!: boolean;

  public Status = Status;

  public get isOffline(): boolean {
    return this.status === Status.offline || !statusList.includes(this.status);
  }

  public get statusClass(): string {
    switch (this.status) {
      case Status.available:
        return 'text-success';
      case Status.absent:
        return 'text-warning';
      case Status.doNotDisturb:
        return 'text-error';
      default:
        return 'text-gray-700';
    }
  }
}
