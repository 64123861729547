
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import NavigationItem from '@/components/header/NavigationItem.vue';
import Badge from '@/components/shared/badge/Badge.vue';
import {
  MenuItemType,
  SubmenuItemType,
} from '@/interfaces/menu/Menu';

import type {
  MenuItem,
  Submenu,
} from '@/interfaces/menu/Menu';

@Component({
  components: {
    Badge,
    NavigationItem,
  },
})
export default class Navigation extends Vue {
  @Prop() public items!: Array<MenuItem|Submenu>;

  @Prop({ default: 'text-secondary' }) public activeColor!: string;

  @Prop({ default: 'text-primary' }) public inactiveColor!: string;

  @Prop({ default: 'flex w-full p-4 text-left border-t border-gray-400' }) public menuButtonClass!: string;

  @Prop({ default: '' }) public dropdownClass!: string;

  @Prop({ default: '' }) public menuClass!: string;

  @Prop({ default: 'flex w-full p-4 pl-5 text-left border-t border-gray-400' }) public subitemClass!: string;

  @Prop({ default: 'flex w-full p-4 text-left border-t border-gray-400' }) public itemClass!: string;

  @Prop({ default: false }) public showChevrons!: boolean;

  @Prop({ default: '' }) public classes!: string;

  @Getter('allNotifications', { namespace: 'chat/room' }) public chatNotifications!: number;

  @Getter('unreadCount', { namespace: 'jobs' }) public jobsNotifications!: number;

  @Getter('count', { namespace: 'news/unreadCount' }) public getNewsUnreadCount!: (categoryHash: string) => number | undefined;

  public SubmenuItemType = SubmenuItemType;

  public openedSubmenu: Submenu | null = null;

  public toggleSubmenu(submenu: Submenu): void {
    if (this.openedSubmenu === submenu) {
      this.openedSubmenu = null;
    } else {
      this.openedSubmenu = submenu;
    }
  }

  public updateActiveState = (submenuInput: Submenu): void => {
    const submenu = submenuInput;

    submenu.active = submenu.items.some((item) => item.active);
  };

  public onClose(): void {
    this.closeSubmenu();
    this.$emit('close');
  }

  public closeSubmenu(): void {
    this.openedSubmenu = null;
  }

  public getNotificationCount(submenu: Submenu): number {
    return this.getChatNotificationCount(submenu)
      + this.getJobsNotificationCount(submenu)
      + this.getNewsNotificationCount(submenu);
  }

  private getChatNotificationCount(submenu: Submenu): number {
    if (submenu.items.some((item) => item.type === MenuItemType.chat)) {
      return this.chatNotifications;
    }

    return 0;
  }

  private getJobsNotificationCount(submenu: Submenu): number {
    if (submenu.items.some((item) => item.type === MenuItemType.jobcenter)) {
      return this.jobsNotifications;
    }

    return 0;
  }

  private getNewsNotificationCount(submenu: Submenu): number {
    return submenu.items.reduce((count, item) => {
      if (item.type === MenuItemType.news && item.data.category && item.data.showNewsBadge) {
        return count + (this.getNewsUnreadCount(item.data.category) || 0);
      }

      return count;
    }, 0);
  }
}
