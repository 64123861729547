
import { Component, Mixins, Prop } from 'vue-property-decorator';

import Navigation from '@/components/header/Navigation.vue';
import Spinner from '@/components/shared/Spinner.vue';
import { MenuArea } from '@/interfaces/menu/Menu';
import HasMenu from '@/mixins/HasMenu.vue';

import type { MenuItem, Submenu } from '@/interfaces/menu/Menu';

@Component({
  components: {
    Navigation,
    Spinner,
  },
})
export default class MainMenu extends Mixins(HasMenu) {
  @Prop({ default: MenuArea.main }) public area!: MenuArea;

  public get menuItems(): Array<MenuItem|Submenu> {
    return this.menu[this.area] || [];
  }
}
