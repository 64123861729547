const date = {
  millisPerDay: 1000 * 60 * 60 * 24,

  toDate: (input: number | Date): Date => {
    if (input instanceof Date) {
      return input;
    }

    return new Date(input);
  },

  isSameDay: (input1: number | Date, input2: number | Date): boolean => {
    const date1 = date.toDate(input1);
    const date2 = date.toDate(input2);

    return date1.getFullYear() === date2.getFullYear()
      && date1.getMonth() === date2.getMonth()
      && date1.getDate() === date2.getDate();
  },

  getDayDifference: (input1: number | Date, input2: number | Date): number => {
    const date1 = date.toDate(input1);
    const date2 = date.toDate(input2);

    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return Math.abs(Math.floor((utc2 - utc1) / date.millisPerDay));
  },

  getYear: (input: number | Date): number => date.toDate(input).getFullYear(),

  getStartOfWeek: (weekNumber: number, year: number): Date => {
    if (weekNumber < 1 || weekNumber > 53) {
      throw new RangeError('ISO 8601 weeks are numbered from 1 to 53');
    }

    const simpleWeekDefinition = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    const dayOfWeek = simpleWeekDefinition.getDay();
    const isoWeekStart = simpleWeekDefinition;

    // Get the Monday past, and add a week if the day was Friday, Saturday or Sunday.
    isoWeekStart.setDate(simpleWeekDefinition.getDate() - dayOfWeek + 1);
    if (dayOfWeek > 4) {
      isoWeekStart.setDate(isoWeekStart.getDate() + 7);
    }

    // The latest possible ISO week starts on December 28 of the current year.
    if (isoWeekStart.getFullYear() > year
      || (isoWeekStart.getFullYear() === year
        && isoWeekStart.getMonth() === 11
        && isoWeekStart.getDate() > 28)) {
      throw new RangeError(`${year} has no ISO week ${weekNumber}`);
    }

    return isoWeekStart;
  },

  getEndOfWeek: (weekNumber: number, year: number): Date => {
    const startDate = date.getStartOfWeek(weekNumber, year);
    const endDate = new Date(startDate);

    endDate.setDate(endDate.getDate() + 6);

    return endDate;
  },

  format: (input: number | Date | undefined, format?: Intl.DateTimeFormatOptions): string => (
    Intl.DateTimeFormat('de', format).format(input)
  ),
};

export default date;
