
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

import Renderless from '@/mixins/Renderless.vue';

@Component({
  mixins: [
    Renderless,
  ],
})
export default class VariableWatcher extends Vue {
  @Prop() public variable!: unknown;

  @Watch('variable', { immediate: true })
  public onVariableChanged(variable: unknown): void {
    this.$emit('change', variable);
  }
}
