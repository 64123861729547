import type { Emoji } from '@/interfaces/chat/Emoji';
import type { EmojiState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

/* Reference names by using https://emojipedia.org */
const initialState = (): EmojiState => ({
  chatEmojis: [{
    icon: '🙂',
    name: 'slightly_smiling_face',
  }, {
    icon: '😂',
    name: 'joy',
  }, {
    icon: '😜',
    name: 'winking_face_with_tongue',
  }, {
    icon: '😍',
    name: 'heart_eyes',
  }, {
    icon: '😘',
    name: 'kissing_heart',
  }, {
    icon: '😭',
    name: 'sob',
  }, {
    icon: '😉',
    name: 'wink',
  }, {
    icon: '🙈',
    name: 'see_no_evil',
  }, {
    icon: '🙏',
    name: 'folded_hands',
  }, {
    icon: '👍',
    name: 'thumbsup',
  }, {
    icon: '👎',
    name: 'thumbsdown',
  }, {
    icon: '🚀',
    name: 'rocket',
  }, {
    icon: '🎉',
    name: 'tada',
  }, {
    icon: '🦄',
    name: 'unicorn',
  }, {
    icon: '🏁',
    name: 'checkered_flag',
  }, {
    icon: '❤️',
    name: 'heart',
  }, {
    icon: '✔️',
    name: 'check_mark',
  }, {
    icon: '❌',
    name: 'cross_mark',
  }, {
    icon: '💡',
    name: 'bulb',
  }, {
    icon: '💩',
    name: 'poop',
  }, {
    icon: '💶',
    name: 'euro',
  }, {
    icon: '🌈',
    name: 'rainbow',
  }, {
    icon: '⭐',
    name: 'star',
  }, {
    icon: '🍕',
    name: 'pizza',
  }, {
    icon: '🎂',
    name: 'birthday_cake',
  }, {
    icon: '☕',
    name: 'coffee',
  }],

  reactionEmojis: [{
    icon: '👍',
    name: 'thumbsup',
  }, {
    icon: '👎',
    name: 'thumbsdown',
  }, {
    icon: '🙂',
    name: 'slightly_smiling_face',
  }, {
    icon: '🙁',
    name: 'slightly_frowning_face',
  }, {
    icon: '❤️',
    name: 'heart',
  }],
});

const EmojiModule: Module<EmojiState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    chatEmojis(state: EmojiState): Emoji[] {
      return state.chatEmojis;
    },

    reactionEmojis(state: EmojiState): Emoji[] {
      return state.reactionEmojis;
    },
  },

  mutations: {
    clear(state: EmojiState): void {
      Object.assign(state, initialState());
    },
  },

  actions: {
    async clear({ commit }: ActionContext<EmojiState, RootState>): Promise<void> {
      commit('clear');
    },
  },
};

export default EmojiModule;
