
import { Component, Vue } from 'vue-property-decorator';

import MainMenu from '@/components/header/MainMenu.vue';
import UserInfo from '@/components/header/UserInfo.vue';

@Component({
  components: {
    MainMenu,
    UserInfo,
  },
})
export default class DefaultHeader extends Vue {
}
