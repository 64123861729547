import adaptUser from '@/adapter/user';
import { getAppBridge } from '@/bridge/app/BridgeProvider';
import bridgeStorage from '@/bridge/app/BridgeStorage';
import { Events } from '@/bridge/app/Events';
import UserTopic from '@/bridge/app/topics/UserTopic';
import login from '@/http/login';
import refreshToken from '@/http/refresh-token';
import fetchById from '@/http/user/fetch-by-id';
import search from '@/http/user/search';
import {
  SocketAuthEmission,
  SocketChatEvent,
  SocketUserEmission,
  SystemEvent,
} from '@/interfaces/shared/Socket';
import {
  LoginError,
  LoginLocalState,
  Status,
} from '@/interfaces/shared/User';
import socket from '@/socket';

import type { MessageFromUser } from '@/interfaces/chat/Message';
import type { RoomUser, RoomUsers } from '@/interfaces/chat/Room';
import type { Paginated } from '@/interfaces/shared/Paginated';
import type {
  SocketUser,
  SocketUserSearchPayload,
} from '@/interfaces/shared/Socket';
import type {
  LoginRequest,
  LoginResponse,
  Tokens,
} from '@/interfaces/shared/User';
import type { RootState, UserState, UserGetters } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const unknownUserReference: RoomUser = {
  id: '',
  name: 'Unbekannter Nutzer',
  firstName: 'Unbekannter',
  lastName: 'Nutzer',
  initials: 'UN',
  status: Status.offline,
  isDeleted: true,
  isDisabled: true,
  isChatDisabled: false,
  hasAvatar: false,
};

const initialState = (): UserState => {
  const initialToken = localStorage.getItem(LoginLocalState.token);
  const initialRefreshToken = localStorage.getItem(LoginLocalState.refreshToken);

  return {
    currentUserId: localStorage.getItem(LoginLocalState.userId) || undefined,
    token: initialToken && initialRefreshToken ? {
      token: initialToken,
      refreshToken: initialRefreshToken,
    } : undefined,
    loginError: undefined,
    users: {},
  };
};

const UserModule: Module<UserState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    users(state: UserState): RoomUsers {
      return state.users;
    },

    currentUser(state: UserState, getters: UserGetters): RoomUser | undefined {
      if (!state.currentUserId) {
        return undefined;
      }

      return getters.users[state.currentUserId];
    },

    user(state: UserState, getters: UserGetters): (id: string) => RoomUser {
      return (id: string) => getters.users[id] || unknownUserReference;
    },

    token(state: UserState): Tokens | undefined {
      return state.token;
    },

    loginError(state: UserState): LoginError | undefined {
      return state.loginError;
    },
  },

  mutations: {
    clear(state: UserState): void {
      Object.assign(state, initialState());
    },

    login(state: UserState, payload: LoginResponse): void {
      state.currentUserId = payload.id;

      state.token = {
        token: payload.token,
        refreshToken: payload.refreshToken,
      };

      localStorage.setItem(LoginLocalState.refreshToken, payload.refreshToken);
      localStorage.setItem(LoginLocalState.token, payload.token);
      localStorage.setItem(LoginLocalState.userId, payload.id);
    },

    logout(state: UserState): void {
      state.currentUserId = undefined;
      state.token = undefined;

      localStorage.removeItem(LoginLocalState.refreshToken);
      localStorage.removeItem(LoginLocalState.token);
      localStorage.removeItem(LoginLocalState.userId);
    },

    loginError(state: UserState, error: LoginError): void {
      state.loginError = error;
    },

    clearLoginError(state: UserState): void {
      state.loginError = undefined;
    },

    updateUser(state: UserState, user: RoomUser): void {
      state.users = {
        ...state.users,
        [user.id]: user,
      };
    },
  },

  actions: {
    async clear({ commit }: ActionContext<UserState, RootState>): Promise<void> {
      commit('clear');
    },

    async login(
      { commit, dispatch }: ActionContext<UserState, RootState>,
      payload: LoginRequest,
    ): Promise<void> {
      commit('clearLoginError');

      const promise: Promise<Partial<LoginResponse>> = login(payload);

      await dispatch('handleAuthentication', promise);
    },

    async refreshToken(
      { dispatch, getters }: ActionContext<UserState, RootState>,
    ): Promise<void> {
      const typedGetters: UserGetters = getters;
      const token = typedGetters.token?.refreshToken;

      if (!token) {
        throw new Error('No refresh token set');
      }

      const promise: Promise<Partial<LoginResponse>> = refreshToken(token);

      await dispatch('handleAuthentication', promise);

      if (socket.connected) {
        socket.emit(SocketAuthEmission.tokenRefresh, {
          token: typedGetters.token?.token,
        });
      }
    },

    async handleAuthentication(
      { commit, state }: ActionContext<UserState, RootState>,
      promise: Promise<Partial<LoginResponse>>,
    ): Promise<void> {
      await promise
        .then((response) => {
          const userLogin: LoginResponse | undefined = adaptUser.loginFromServer(response);

          if (!userLogin) {
            throw new Error('Expected login payload to be valid');
          }

          commit('login', userLogin);

          bridgeStorage.save();

          getAppBridge().on(Events.Login);

          if (state.currentUserId) {
            getAppBridge().subscribeToTopics([UserTopic.generate(state.currentUserId)]);
          }
        })
        .catch((error) => {
          const errors: {[key: string]: LoginError | undefined } = {
            401: LoginError.credentials,
            412: LoginError.twoFactorAuthRequired,
            428: LoginError.twoFactorAuthInactive,
            other: LoginError.other,
          };

          commit('loginError', errors[error.response?.status] || errors.other);
        });
    },

    async logout(
      { commit, dispatch, state }: ActionContext<UserState, RootState>,
    ): Promise<void> {
      const oldUserId = state.currentUserId;

      commit('logout');

      bridgeStorage.save();

      getAppBridge().on(Events.Logout);

      if (oldUserId) {
        getAppBridge().unsubscribeFromTopics([UserTopic.generate(oldUserId)]);
      }

      await dispatch('clearAllStates', undefined, {
        root: true,
      });
    },

    async searchUsers(
      { commit }: ActionContext<UserState, RootState>,
      payload: SocketUserSearchPayload,
    ): Promise<Paginated<RoomUser>> {
      const result = await search(payload);

      if (result.entries?.length === undefined || result.total === undefined) {
        return {
          total: 0,
          entries: [],
        };
      }

      const { total } = result;
      const entries = result.entries.map((entry) => (
        adaptUser.fromSocket(entry)
      ));

      entries.forEach((entry) => {
        commit('updateUser', entry);
      });

      return {
        total,
        entries,
      };
    },

    async fetchUserIfNeeded(
      { getters, commit }: ActionContext<UserState, RootState>,
      userId: string,
    ): Promise<void> {
      const typedGetters: UserGetters = getters;

      if (typedGetters.users[userId]) {
        return;
      }

      commit('updateUser', {
        ...unknownUserReference,
        id: userId,
      });

      let response;

      try {
        response = await fetchById(userId);
      } catch (error) {
        return;
      }

      if (!response) {
        return;
      }

      commit('updateUser', adaptUser.fromSocket(response));
    },

    async changeStatus(
      context: ActionContext<UserState, RootState>,
      status: Status,
    ): Promise<void> {
      socket.emit(SocketUserEmission.updateStatus, {
        status,
      });
    },

    async [SocketChatEvent.userUpdate](
      { commit, dispatch, getters }: ActionContext<UserState, RootState>,
      payload?: SocketUser,
    ): Promise<void> {
      const user = adaptUser.fromSocket(payload);
      const { currentUser } = getters;

      if (currentUser && currentUser.id === user.id) {
        if (user.isDisabled) {
          dispatch('logout');
          return;
        }

        dispatch('menu/reset', null, { root: true });
      }

      if (user.id) {
        commit('updateUser', user);
        dispatch('avatar/refreshUser', user, {
          root: true,
        });
      }
    },

    async newMessage(
      { commit, getters }: ActionContext<UserState, RootState>,
      userMessage: MessageFromUser,
    ): Promise<void> {
      const typedGetters: UserGetters = getters;
      const isSameUser = userMessage.userId === typedGetters.currentUser?.id;

      if (isSameUser) {
        commit('chat/room/setRoomMessagesRead', {
          roomId: userMessage.roomId,
          timestamp: userMessage.sortTsUpdate,
        }, {
          root: true,
        });
      } else {
        commit('chat/room/addNotification', {
          message: userMessage,
          amount: 1,
        }, {
          root: true,
        });
      }
    },

    async [SocketChatEvent.usersUpdate](
      { dispatch }: ActionContext<UserState, RootState>,
      payload?: SocketUser[],
    ): Promise<void> {
      if (Array.isArray(payload)) {
        payload.forEach((user) => {
          dispatch(SocketChatEvent.userUpdate, user);
        });
      }
    },

    async [SystemEvent.reconnectAttempt](
      { state }: ActionContext<UserState, RootState>,
    ): Promise<void> {
      if (state.token?.refreshToken) {
        socket.io.opts.query = {
          token: state.token.refreshToken,
        };
      }
    },
  },
};

export default UserModule;
