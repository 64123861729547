
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class LayoutState extends Vue {
  @Getter('isFullHeight', { namespace: 'layout' }) public isFullHeight!: boolean;

  @Watch('isFullHeight', { immediate: true })
  public onIsFullHeightChanged = (isFullHeight: boolean): void => {
      if (isFullHeight) {
        document.body.classList.add('h-full');
      } else {
        document.body.classList.remove('h-full');
      }
    };
}
