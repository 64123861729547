
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import { ToastType } from '@/interfaces/shared/Toast';

import type { Toastable } from '@/interfaces/shared/Toast';

@Component
export default class ToastEntry extends Vue {
  @Prop() public toast!: Toastable;

  @Action('removeToast', { namespace: 'toast' }) public removeToast!: (payload: Toastable) => Promise<void>;

  public ToastType = ToastType;

  public get lines(): string[] {
    return this.toast.content.split('\n');
  }

  public close(): void {
    this.removeToast(this.toast);
  }
}
