
import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import Toast from '@/components/shared/toast/Toast';
import { DisconnectReason } from '@/interfaces/shared/Socket';
import { ToastType } from '@/interfaces/shared/Toast';
import Renderless from '@/mixins/Renderless.vue';

import type { Toastable } from '@/interfaces/shared/Toast';

@Component({
  mixins: [
    Renderless,
  ],
})
export default class SocketErrors extends Vue {
  @Getter('disconnectReason', { namespace: 'socket' }) public socketDisconnectReason?: DisconnectReason;

  @Action('addToast', { namespace: 'toast' }) public addToast!: (payload: Toastable) => Promise<void>;

  @Action('removeToast', { namespace: 'toast' }) public removeToast!: (payload: Toastable) => Promise<void>;

  @Action('logout', { namespace: 'user' }) public logout!: () => Promise<void>;

  private socketDisconnectedToast?: Toastable;

  @Watch('socketDisconnectReason')
  public onSocketDisconnectReasonChanged(): void {
    if (this.socketDisconnectReason) {
      if (this.socketDisconnectReason === DisconnectReason.forceReauthentication) {
        this.showLogoutToast();
        this.logout();
      } else {
        this.showSocketDisconnectedToast();
      }
    } else {
      this.hideSocketDisconnectedToast();
    }
  }

  private showSocketDisconnectedToast(): void {
    this.hideSocketDisconnectedToast();

    this.socketDisconnectedToast = new Toast()
      .withContent('Verbindung zum Chat-Server unterbrochen. Bitte prüfen Sie Ihre Internetverbindung.\nDer Server versucht einen Neuaufbau…')
      .withButton('Seite neu laden', () => window.location.reload())
      .withButton('Ausloggen', () => this.logout())
      .withType(ToastType.error)
      .withInfiniteLifetime();

    this.addToast(this.socketDisconnectedToast);
  }

  private showLogoutToast(): void {
    this.hideSocketDisconnectedToast();

    this.socketDisconnectedToast = new Toast()
      .withContent('Verbindung zum Chat-Server unterbrochen. Bitte melden Sie sich erneut an.')
      .withType(ToastType.error)
      .withSeconds(10)
      .withCloseButton();

    this.addToast(this.socketDisconnectedToast);
  }

  private hideSocketDisconnectedToast(): void {
    if (this.socketDisconnectedToast) {
      this.removeToast(this.socketDisconnectedToast);
    }
  }
}
