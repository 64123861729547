import axios from 'axios';

import config from '@/config';

import type { JobDetailItem } from '@/interfaces/jobs/Jobs';

export default {
  loadJob: (hash: string): Promise<Partial<JobDetailItem>> => (
    axios.get<Partial<JobDetailItem>>(`${config.versionedServerUrl()}/jobs/posting/${hash}`)
      .then((res) => res.data)
  ),
};
