import { MessageType } from '@/interfaces/chat/Message';

import type { MessageFromUser } from '@/interfaces/chat/Message';
import type { Room } from '@/interfaces/chat/Room';
import type { ModalState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): ModalState => ({
  deletingMessage: undefined,
  editingRoom: undefined,
  leavingRoom: undefined,
  closingRoom: undefined,
});

const ModalModule: Module<ModalState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    deletingMessage(state: ModalState): MessageFromUser | undefined {
      return state.deletingMessage;
    },

    editingRoom(state: ModalState): Room | undefined {
      return state.editingRoom;
    },

    leavingRoom(state: ModalState): Room | undefined {
      return state.leavingRoom;
    },

    closingRoom(state: ModalState): Room | undefined {
      return state.closingRoom;
    },
  },

  mutations: {
    clear(state: ModalState): void {
      Object.assign(state, initialState());
    },

    setDeletingMessage(state: ModalState, message?: MessageFromUser): void {
      if (message && message.type !== MessageType.user) {
        throw new Error(`Unsupported type "${message?.type}"`);
      }

      state.deletingMessage = message;
    },

    setEditingRoom(state: ModalState, room?: Room): void {
      state.editingRoom = room;
    },

    setLeavingRoom(state: ModalState, room?: Room): void {
      state.leavingRoom = room;
    },

    setClosingRoom(state: ModalState, room?: Room): void {
      state.closingRoom = room;
    },
  },

  actions: {
    async clear({ commit }: ActionContext<ModalState, RootState>): Promise<void> {
      commit('clear');
    },
  },
};

export default ModalModule;
