
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { getAppBridge } from '@/bridge/app/BridgeProvider';
import { Events } from '@/bridge/app/Events';
import Modal from '@/components/shared/Modal.vue';
import Spinner from '@/components/shared/Spinner.vue';
import { LoginError } from '@/interfaces/shared/User';

import type { LoginRequest } from '@/interfaces/shared/User';

@Component({
  components: {
    Modal,
    Spinner,
  },
})
export default class LoginView extends Vue {
  @Getter('loginError', { namespace: 'user' }) public loginError!: LoginError;

  @Action('login', { namespace: 'user' }) public login!: (payload: LoginRequest) => Promise<void>;

  public LoginError = LoginError;

  public username = '';

  public password = '';

  public token = '';

  public loading = false;

  public get canLogin(): boolean {
    const hasUsernameAndPassword = this.username?.length > 0 && this.password?.length > 0;

    if (this.loginError === LoginError.twoFactorAuthRequired) {
      const hasToken = this.token?.length > 0;

      return hasUsernameAndPassword && hasToken;
    }

    return hasUsernameAndPassword;
  }

  private closableOperatingSystems = [
    'android',
    'ios',
  ];

  public get isClosable(): boolean {
    return this.closableOperatingSystems.includes(getAppBridge().os());
  }

  public onClose = (): void => {
    getAppBridge().on(Events.Close);
  };

  public mounted(): void {
    if (this.$refs.username instanceof HTMLElement) {
      this.$refs.username.focus();
    }
  }

  public onSubmit(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;

    this.login({
      username: this.username,
      password: this.password,
      token: this.token,
    })
      .then(() => {
        this.loading = false;
        this.token = '';

        this.$nextTick(() => {
          if (this.loginError === LoginError.twoFactorAuthRequired) {
            if (this.$refs.token instanceof HTMLElement) {
              this.$refs.token.focus();
            }
          }
        });
      })
      .catch(() => {
        this.loading = false;
        this.token = '';
      });
  }
}
