import config from '@/config';
import http from '@/http/chat/upload';
import {
  DraftFileUploadFailReason,
} from '@/interfaces/chat/Draft';
import DraftUpdate from '@/store/chat/draft/draft-update';

import type {
  Draft,
  DraftCleared,
  Drafts,
  DraftFilesAdded,
  DraftFilesRemoved,
  DraftFileUploadFailed,
  DraftFileUploadProgressed,
  DraftFileUploadReferenced,
  DraftMessageUpdated,
} from '@/interfaces/chat/Draft';
import type { DraftGetters, DraftState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): DraftState => ({
  drafts: {},
});

const DraftModule: Module<DraftState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    drafts(state: DraftState): Drafts {
      return state.drafts;
    },

    draft(state: DraftState, getters: DraftGetters): (draftKey: string) => Draft | undefined {
      return (draftKey: string): Draft | undefined => getters.drafts[draftKey];
    },
  },

  mutations: {
    clear(state: DraftState): void {
      Object.assign(state, initialState());
    },

    setDraftMessage(state: DraftState, payload: DraftMessageUpdated): void {
      state.drafts = new DraftUpdate(state.drafts, payload.draftKey)
        .setMessage(payload.message)
        .build();
    },

    addDraftFiles(state: DraftState, payload: DraftFilesAdded): void {
      state.drafts = new DraftUpdate(state.drafts, payload.draftKey)
        .addFiles(payload.files)
        .build();
    },

    removeDraftFiles(state: DraftState, payload: DraftFilesRemoved): void {
      state.drafts = new DraftUpdate(state.drafts, payload.draftKey)
        .removeFiles(payload.files)
        .build();
    },

    updateDraftFileProgress(state: DraftState, payload: DraftFileUploadProgressed): void {
      state.drafts = new DraftUpdate(state.drafts, payload.draftKey)
        .setUploadProgress(payload.file, payload.progress)
        .build();
    },

    setDraftFileReference(state: DraftState, payload: DraftFileUploadReferenced): void {
      state.drafts = new DraftUpdate(state.drafts, payload.draftKey)
        .setUploadReference(payload.file, payload.reference)
        .build();
    },

    setDraftFileFailure(state: DraftState, payload: DraftFileUploadFailed): void {
      state.drafts = new DraftUpdate(state.drafts, payload.draftKey)
        .setUploadFailed(payload.file, payload.reason)
        .build();
    },

    resetDraftFileUploads(state: DraftState, payload: DraftFilesAdded): void {
      state.drafts = new DraftUpdate(state.drafts, payload.draftKey)
        .resetUploads(payload.files)
        .build();
    },

    clearDraft(state: DraftState, payload: DraftCleared): void {
      delete state.drafts[payload.draftKey];

      state.drafts = {
        ...state.drafts,
      };
    },
  },

  actions: {
    async clear(
      { commit }: ActionContext<DraftState, RootState>,
    ): Promise<void> {
      commit('clear');
    },

    async setDraftMessage(
      { commit }: ActionContext<DraftState, RootState>,
      payload: DraftMessageUpdated,
    ): Promise<void> {
      commit('setDraftMessage', payload);
    },

    async addDraftFiles(
      { commit, dispatch }: ActionContext<DraftState, RootState>,
      payload: DraftFilesAdded,
    ): Promise<void> {
      commit('addDraftFiles', payload);
      dispatch('uploadDraftFiles', payload);
    },

    async uploadDraftFiles(
      { commit }: ActionContext<DraftState, RootState>,
      payload: DraftFilesAdded,
    ): Promise<void> {
      const commitProgress = (file: File, progress: number): void => {
        const data: DraftFileUploadProgressed = {
          draftKey: payload.draftKey,
          file,
          progress,
        };

        commit('updateDraftFileProgress', data);
      };

      const commitReference = (file: File, reference: string): void => {
        const data: DraftFileUploadReferenced = {
          draftKey: payload.draftKey,
          file,
          reference,
        };

        commit('setDraftFileReference', data);
      };

      const commitFailure = (file: File, reason?: DraftFileUploadFailReason): void => {
        const data: DraftFileUploadFailed = {
          draftKey: payload.draftKey,
          file,
          reason,
        };

        commit('setDraftFileFailure', data);
      };

      commit('resetDraftFileUploads', payload);

      payload.files.forEach((file) => {
        if (file.size > config.chat.maxUploadBytes) {
          commitFailure(file, DraftFileUploadFailReason.uploadSizeExceeded);
        } else {
          http.uploadFile(payload.roomId, file, (event) => {
            commitProgress(file, Math.round((event.loaded * 100) / (event.total || 1)));
          })
            .then((response) => {
              commitProgress(file, 100);
              commitReference(file, response.id);
            })
            .catch((error) => {
              const reasonKey = error?.response?.data?.data?.reason;
              const reason = DraftFileUploadFailReason[
                <keyof typeof DraftFileUploadFailReason> reasonKey
              ] || undefined;

              commitFailure(file, reason);
            });
        }
      });
    },

    async removeDraftFiles(
      { commit, getters }: ActionContext<DraftState, RootState>,
      payload: DraftFilesRemoved,
    ): Promise<void> {
      const typedGetters: DraftGetters = getters;
      const draft: Draft | undefined = typedGetters.draft(payload.draftKey);
      const uploads = draft?.uploads || [];

      const cancelReferences = uploads
        .filter((entry) => payload.files.includes(entry.file))
        .map((entry) => entry.status.reference)
        .filter((reference): reference is string => !!reference);

      commit('removeDraftFiles', payload);

      if (cancelReferences?.length) {
        http.cancelUploads(cancelReferences).catch(() => {
          // Upload cancellation failure can be ignored
        });
      }
    },

    async clearDraft(
      { commit, dispatch, getters }: ActionContext<DraftState, RootState>,
      payload: DraftCleared,
    ): Promise<void> {
      const removeFiles = (removePayload: DraftFilesRemoved): Promise<void> => {
        if (!payload.cancelFiles) {
          return Promise.resolve();
        }

        return dispatch('removeDraftFiles', removePayload);
      };

      const typedGetters: DraftGetters = getters;
      const draft: Draft | undefined = typedGetters.draft(payload.draftKey);
      const uploads = draft?.uploads || [];
      const files = uploads
        .filter((entry) => entry.status.reference && entry.file)
        .map((entry) => entry.file);

      removeFiles({
        draftKey: payload.draftKey,
        files,
      }).catch(() => {
        // Upload cancellation failure can be ignored
      }).then(() => {
        commit('clearDraft', payload);
      });
    },
  },
};

export default DraftModule;
