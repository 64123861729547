
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import config from '@/config';
import { getLoginPath, getRedirectPathAfterLogin } from '@/router';

import type { Tokens } from '@/interfaces/shared/User';

@Component
export default class UserState extends Vue {
  @Getter('token', { namespace: 'user' }) public token?: Tokens;

  @Action('connect', { namespace: 'socket' }) public connectSocket!: (payload: Tokens) => Promise<void>;

  @Action('disconnect', { namespace: 'socket' }) public disconnectSocket!: () => Promise<void>;

  @Action('refreshToken', { namespace: 'user' }) public refreshToken!: () => Promise<void>;

  @Watch('token', { immediate: true })
  public onTokenChanged(token?: Tokens): void {
    if (token) {
      this.registerTokenRefresh();
    } else {
      this.unregisterTokenRefresh();
    }

    if (token) {
      if (this.$router.currentRoute.meta?.isLoginPage) {
        // Force login
        this.$router.push(getRedirectPathAfterLogin()).catch(() => {
          // Do nothing, already on page
        });
      }

      this.connectSocket(token).catch(() => {
        // Do nothing, wait for socket-retry
      });
    } else {
      if (!this.$router.currentRoute.meta?.disableAuth) {
        // Force logout
        this.$router.push(getLoginPath()).catch(() => {
          // Do nothing, already on page
        });
      }

      this.disconnectSocket().catch(() => {
        // Do nothing
      });
    }
  }

  private tokenRefreshTimeout: number | undefined = undefined;

  private registerTokenRefresh(): void {
    this.unregisterTokenRefresh();

    this.tokenRefreshTimeout = window.setTimeout(() => {
      this.refreshToken();
    }, config.server.tokenExpireSeconds * 1000);
  }

  private unregisterTokenRefresh(): void {
    if (this.tokenRefreshTimeout) {
      clearTimeout(this.tokenRefreshTimeout);
    }
  }
}
