import { NewsOverviewLayoutOption } from '@/interfaces/news/Layout';
import article from '@/store/news/article';
import breaking from '@/store/news/breaking';
import comments from '@/store/news/comments';
import home from '@/store/news/home';
import random from '@/store/news/random';
import search from '@/store/news/search';
import top from '@/store/news/top';
import topics from '@/store/news/topics';
import unreadCount from '@/store/news/unread-count';

import type { NewsSearchParameters } from '@/interfaces/news/Filter';
import type {
  SetArticleBookmarkedPayload,
  SetArticleLikedPayload,
  SetArticleReadPayload,
} from '@/interfaces/news/News';
import type { NewsState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const modules = {
  article,
  breaking,
  comments,
  home,
  random,
  search,
  top,
  topics,
  unreadCount,
};

const modulesWithArticles = {
  article,
  breaking,
  home,
  random,
  search,
  top,
};

const initialState = (): NewsState => ({
  currentArticleHash: undefined,
  overviewLayout: NewsOverviewLayoutOption.grid,
  searchParameters: {},
});

const NewsModule: Module<NewsState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    currentArticleHash(state: NewsState): string | undefined {
      return state.currentArticleHash;
    },

    overviewLayout(state: NewsState): NewsOverviewLayoutOption {
      return state.overviewLayout;
    },

    searchParameters(state: NewsState): NewsSearchParameters {
      return state.searchParameters;
    },

    hasSearchParameters({ searchParameters }: NewsState): boolean {
      return !!searchParameters.search
        || !!searchParameters.date?.from
        || !!searchParameters.date?.to
        || !!searchParameters.topic
        || !!searchParameters.category
        || !!searchParameters.bookmarked
        || !!searchParameters.unread
        || !!searchParameters.tags;
    },
  },

  mutations: {
    clear(state: NewsState): void {
      Object.assign(state, initialState());
    },

    setCurrentNewsHash(state: NewsState, hash: string): void {
      state.currentArticleHash = hash;
    },

    setOverviewLayout(state: NewsState, layout: NewsOverviewLayoutOption): void {
      state.overviewLayout = layout;
    },

    setSearchParameters(
      state: NewsState,
      searchParameters: NewsSearchParameters | undefined,
    ): void {
      state.searchParameters = {
        ...searchParameters,
      };
    },
  },

  actions: {
    async clear({ commit, dispatch }: ActionContext<NewsState, RootState>): Promise<void> {
      commit('clear');

      Object.keys(modules).forEach((module) => (
        dispatch(`${module}/clear`)
      ));
    },

    async setArticleBookmarked(
      { commit }: ActionContext<NewsState, RootState>,
      payload: SetArticleBookmarkedPayload,
    ): Promise<void> {
      Object.keys(modulesWithArticles).forEach((module) => (
        commit(`${module}/setArticleBookmarked`, payload)
      ));
    },

    async setArticleLiked(
      { commit }: ActionContext<NewsState, RootState>,
      payload: SetArticleLikedPayload,
    ): Promise<void> {
      Object.keys(modulesWithArticles).forEach((module) => (
        commit(`${module}/setArticleLiked`, payload)
      ));
    },

    async setArticleRead(
      { commit }: ActionContext<NewsState, RootState>,
      payload: SetArticleReadPayload,
    ): Promise<void> {
      Object.keys(modulesWithArticles).forEach((module) => (
        commit(`${module}/setArticleRead`, payload)
      ));
    },

    async setSearchParameters(
      { state, commit, dispatch }: ActionContext<NewsState, RootState>,
      searchParameters: NewsSearchParameters | undefined,
    ) {
      commit('setSearchParameters', searchParameters);

      await dispatch('search/loadArticles', {
        resetPaging: true,
        updateHasMore: true,
        parameters: state.searchParameters,
      });
    },
  },

  modules,
};

export default NewsModule;
