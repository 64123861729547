
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import {
  SubmenuItemType,
} from '@/interfaces/menu/Menu';

import type { RoomUser } from '@/interfaces/chat/Room';
import type {
  Menu,
  MenuArea,
  MenuItem,
} from '@/interfaces/menu/Menu';

@Component
export default class HasMenu extends Vue {
  @Getter('currentUser', { namespace: 'user' }) public menuUser?: RoomUser;

  @Getter('menu', { namespace: 'menu' }) public menu!: Menu;

  @Getter('isLoading', { namespace: 'menu' }) public menuIsLoading!: boolean;

  @Getter('hasError', { namespace: 'menu' }) public menuHasError!: boolean;

  @Action('load', { namespace: 'menu' }) public loadMenu!: () => Promise<void>;

  @Action('clear', { namespace: 'menu' }) public clearMenu!: () => Promise<void>;

  @Action('reset', { namespace: 'menu' }) public resetMenu!: () => Promise<void>;

  @Watch('menuUser', { immediate: true })
  public onCurrentUserChanged(user?: RoomUser): void {
    if (user) {
      this.loadMenu();
    } else {
      this.clearMenu();
    }
  }

  protected removeUnsupportedSubmenus(area: MenuArea): MenuItem[] {
    return (this.menu[area] || [])
      .filter((item): item is MenuItem => item.type !== SubmenuItemType.submenu);
  }
}
