
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import AvatarBadge from '@/components/shared/badge/AvatarBadge.vue';
import UserBadge from '@/components/shared/badge/UserBadge.vue';
import {
  RoomType,
} from '@/interfaces/chat/Room';
import RoomFormatter from '@/utils/room-formatter';

import type {
  Room,
  RoomUser,
  TeamRoom,
} from '@/interfaces/chat/Room';
import type { MaybeAvatar } from '@/interfaces/shared/Avatar';
import type { Status } from '@/interfaces/shared/User';

@Component({
  components: {
    AvatarBadge,
    UserBadge,
  },
})
export default class RoomBadge extends Vue {
  @Prop() public room?: Room;

  @Prop({ default: 0 }) public notifications!: number;

  @Getter('roomAvatar', { namespace: 'avatar' }) public getRoomAvatar!: (room: TeamRoom) => MaybeAvatar;

  @Getter('userAvatar', { namespace: 'avatar' }) public getUserAvatar!: (user: RoomUser) => MaybeAvatar;

  @Action('loadRoomAvatar', { namespace: 'avatar' }) public loadRoomAvatar!: (room: TeamRoom) => Promise<MaybeAvatar>;

  @Action('loadUserAvatar', { namespace: 'avatar' }) public loadUserAvatar!: (user: RoomUser) => Promise<MaybeAvatar>;

  @Watch('room', { deep: true, immediate: true })
  public onRoomChanged(): void {
    if (!this.room) {
      return;
    }

    if (this.room.type !== RoomType.team) {
      RoomFormatter.getOtherUsers(this.room).forEach((user) => {
        this.loadUserAvatar(user);
      });
    } else {
      this.loadRoomAvatar(this.room);
    }
  }

  public get singleRoomUser(): RoomUser | undefined {
    if (!this.room || this.room.type !== RoomType.single) {
      return undefined;
    }

    return RoomFormatter.getOtherUsers(this.room).pop();
  }

  public get singleRoomStatus(): Status | undefined {
    return this.singleRoomUser?.status;
  }

  public get avatars(): MaybeAvatar[] {
    if (!this.room) {
      return [];
    }

    if (this.room.type !== RoomType.team) {
      return RoomFormatter.getOtherUsers(this.room)
        .map((user) => this.getUserAvatar(user));
    }

    return [this.getRoomAvatar(this.room)];
  }

  public get name(): string {
    if (!this.room) {
      return '';
    }

    return RoomFormatter.getName(this.room);
  }

  public get initials(): string[] {
    if (!this.room) {
      return [];
    }

    if (this.room.type === RoomType.team && this.room.initials) {
      return [this.room.initials];
    }

    return RoomFormatter.getUsers(this.room).map((user) => user.initials);
  }

  public get isSingleRoom(): boolean {
    return this.room?.type === RoomType.single;
  }
}
