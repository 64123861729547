
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { getAppBridge } from '@/bridge/app/BridgeProvider';
import Badge from '@/components/shared/badge/Badge.vue';
import VariableWatcher from '@/components/shared/VariableWatcher.vue';
import { MenuItemType } from '@/interfaces/menu/Menu';
import { mapToTarget } from '@/utils/navigation';

import type { MenuItem } from '@/interfaces/menu/Menu';

@Component({
  components: {
    Badge,
    VariableWatcher,
  },
})
export default class NavigationItem extends Vue {
  @Prop() public item!: MenuItem;

  @Prop({ default: 'text-secondary' }) public activeColor!: string;

  @Prop({ default: 'text-primary' }) public inactiveColor!: string;

  @Prop({ default: false }) public showChevrons!: boolean;

  @Prop({ default: false }) public isSubmenu!: boolean;

  @Getter('allNotifications', { namespace: 'chat/room' }) public chatNotifications!: number;

  @Getter('unreadCount', { namespace: 'jobs' }) public jobsNotifications!: number;

  @Getter('count', { namespace: 'news/unreadCount' }) public getNewsUnreadCount!: (categoryHash: string) => number | undefined;

  public get newsUnreadCount(): number {
    const { data } = this.item;

    if (this.item.type !== MenuItemType.news || !data.category || !data.showNewsBadge) {
      return 0;
    }

    return this.getNewsUnreadCount(data.category) || 0;
  }

  public mapLinkTarget = mapToTarget;

  public isChat = (item: MenuItem): boolean => (
    item.type === MenuItemType.chat
  );

  public isExternal = (item: MenuItem): boolean => (
    item.type === MenuItemType.external
  );

  public isInternal = (item: MenuItem): boolean => (
    item.type === MenuItemType.internal
  );

  public isJobcenter = (item: MenuItem): boolean => (
    item.type === MenuItemType.jobcenter
  );

  public isNews = (item: MenuItem): boolean => (
    item.type === MenuItemType.news
  );

  public updateActiveState(active: boolean): void {
    this.item.active = active;
    this.$emit('activeChange', active);
  }

  public onMenuItemClick(
    event: MouseEvent,
    item: MenuItem,
    navigate: (event: MouseEvent) => boolean | undefined,
  ): boolean | undefined {
    if (this.isInternal(item)) {
      window.location.href = item.data.link || '';

      return false;
    }

    if (this.isExternal(item)) {
      const link = item.data.link || '';

      if (link) {
        getAppBridge().open(link);
      }

      return true;
    }

    return navigate(event);
  }
}
