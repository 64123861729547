import Vue from 'vue';
import Vuex from 'vuex';

import avatar from '@/store/avatar';
import chat from '@/store/chat';
import drag from '@/store/drag';
import jobs from '@/store/jobs';
import layout from '@/store/layout';
import menu from '@/store/menu';
import modal from '@/store/modal';
import news from '@/store/news';
import socket from '@/store/socket';
import toast from '@/store/toast';
import user from '@/store/user';

import type { RootState } from '@/interfaces/Store';

Vue.use(Vuex);

const modules = {
  avatar,
  chat,
  drag,
  jobs,
  layout,
  menu,
  modal,
  news,
  socket,
  toast,
  user,
};

const initialState = (): RootState => ({
  version: '6.7.1',
});

export default new Vuex.Store<RootState>({
  state: initialState,

  getters: {
    version(state): string {
      return state.version;
    },
  },

  mutations: {
    clear(state): void {
      Object.assign(state, initialState());
    },
  },

  actions: {
    async clearAllStates({ dispatch, commit }): Promise<void> {
      commit('clear');

      Object.keys(modules).forEach((module) => (
        dispatch(`${module}/clear`)
      ));
    },
  },
  modules,
});
