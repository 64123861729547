import type { Tag } from '@/interfaces/shared/Tag';

const adapter = {
  tagsFromServer: (tags?: Partial<Tag>[]): Tag[] => (
    Array.isArray(tags)
      ? tags.map((tag) => adapter.tagFromServer(tag))
      : []
  ),

  tagFromServer: (tag?: Partial<Tag>): Tag => ({
    hash: tag?.hash || '',
    name: tag?.name || '',
  }),
};

export default adapter;
