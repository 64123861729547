import adaptNews from '@/adapter/news';
import http from '@/http/news/list';

import type { NewsArticlesLoadOptions, NewsSearchOptions } from '@/interfaces/news/Filter';
import type {
  NewsArticleListItem,
  SetArticleBookmarkedPayload,
  SetArticleLikedPayload,
  SetArticleReadPayload,
} from '@/interfaces/news/News';
import type { NewsListState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

type Factory = (options?: NewsSearchOptions) => Module<NewsListState, RootState>;

const NewsListModuleFactory: Factory = (options?: NewsSearchOptions) => {
  const defaultOffset = 0;
  const defaultLimit = 6;

  const initialState = (): NewsListState => ({
    isLoading: false,
    hasError: false,
    hasMore: false,
    articles: {},
    offset: options?.offset || defaultOffset,
    limit: options?.limit || defaultLimit,
  });

  return {
    namespaced: true,
    state: initialState,

    getters: {
      isLoading: (state: NewsListState): boolean => state.isLoading,

      hasError: (state: NewsListState): boolean => state.hasError,

      hasMore: (state: NewsListState): boolean => state.hasMore,

      articles: (state: NewsListState): NewsArticleListItem[] => (
        Object.values(state.articles)
          .filter((article): article is NewsArticleListItem => !!article)
          .sort((article1, article2) => (
            article2.publishDate - article1.publishDate
          ))
      ),
    },

    mutations: {
      clear(state: NewsListState): void {
        Object.assign(state, initialState());
      },

      setArticleBookmarked(state: NewsListState, payload: SetArticleBookmarkedPayload): void {
        const article = state.articles[payload.hash];

        if (article) {
          state.articles = {
            ...state.articles,
            [article.hash]: {
              ...article,
              bookmarked: payload.bookmarked,
            },
          };
        }
      },

      setArticleLiked(state: NewsListState, payload: SetArticleLikedPayload): void {
        const article = state.articles[payload.hash];

        if (article) {
          state.articles = {
            ...state.articles,
            [article.hash]: {
              ...article,
              liked: payload.liked,
            },
          };
        }
      },

      setArticleRead(state: NewsListState, payload: SetArticleReadPayload): void {
        const article = state.articles[payload.hash];

        if (article) {
          state.articles = {
            ...state.articles,
            [article.hash]: {
              ...article,
              read: payload.read,
            },
          };
        }
      },

      setLoading(state: NewsListState, isLoading: boolean): void {
        state.isLoading = isLoading;
      },

      setHasError(state: NewsListState, hasError: boolean): void {
        state.hasError = hasError;
      },

      setHasMore(state: NewsListState, hasMore: boolean): void {
        state.hasMore = hasMore;
      },

      resetPaging(state: NewsListState): void {
        state.articles = {};
        state.offset = options?.offset || defaultOffset;
        state.limit = options?.limit || defaultLimit;
      },

      setNextPage(state: NewsListState): void {
        state.offset += state.limit;
      },

      addArticles(state: NewsListState, articles: NewsArticleListItem[]): void {
        articles.forEach((article) => {
          state.articles[article.hash] = article;
        });

        state.articles = {
          ...state.articles,
        };
      },
    },

    actions: {
      async clear({ commit }: ActionContext<NewsListState, RootState>): Promise<void> {
        commit('clear');
      },

      async loadArticles(
        { commit, state }: ActionContext<NewsListState, RootState>,
        parameters?: NewsArticlesLoadOptions,
      ): Promise<void> {
        if (state.isLoading) {
          return;
        }

        commit('setLoading', true);
        commit('setHasError', false);

        if (parameters?.updateHasMore) {
          commit('setHasMore', false);
        }

        if (parameters?.resetPaging) {
          commit('resetPaging');
        }

        await http.loadNews({
          ...options,
          offset: state.offset,
          limit: state.limit,
          ...parameters?.parameters,
        })
          .then((response) => {
            if (parameters?.updateHasMore) {
              commit('setHasMore', response.headers['x-pagination-hasmore'] === '1');
            }

            const articles = adaptNews.listFromServer(response.data);

            commit('addArticles', articles);

            if (articles.length) {
              commit('setNextPage');
            }
          })
          .catch(() => {
            commit('setHasError', true);
          })
          .finally(() => {
            commit('setLoading', false);
          });
      },
    },
  };
};

export default NewsListModuleFactory;
