import axios from 'axios';

import initInterceptors from '@/http/interceptors';

export default {
  initialize: (): void => {
    axios.defaults.withCredentials = true;

    initInterceptors();
  },
};
