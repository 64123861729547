
import { Component, Vue } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';

@Component
export default class DragState extends Vue {
  @Mutation('setDragging', { namespace: 'drag' }) public setDragging!: (dragging: boolean) => void;

  private dragCounter = 0;

  public onDragEnter(event: DragEvent): void {
    if (!DragState.isFileDrag(event)) {
      return;
    }

    this.dragCounter += 1;
    this.setDragging(true);
  }

  public onDragLeave(event: DragEvent): void {
    if (!DragState.isFileDrag(event)) {
      return;
    }

    this.dragCounter = Math.max(0, this.dragCounter - 1);

    if (this.dragCounter <= 0) {
      this.setDragging(false);
    }
  }

  public onDrop(): void {
    this.dragCounter = 0;
    this.setDragging(false);
  }

  private static isFileDrag(event: DragEvent): boolean {
    const types = event.dataTransfer?.types || [];

    return types.includes('Files');
  }
}
