import bridgeStorage from '@/bridge/app/BridgeStorage';
import store from '@/store';

export default async (): Promise<void> => {
  await bridgeStorage.load();

  if (store.getters['user/token']?.refreshToken) {
    try {
      await store.dispatch('user/refreshToken');
    } catch (error) {
      // Do nothing, will logout
    }
  }
};
