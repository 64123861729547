import axios from 'axios';

import store from '@/store';

import type { AxiosError } from 'axios';

export default (): void => {
  const authErrorStatus = [401];

  axios.interceptors.response.use(undefined, (error: AxiosError) => {
    const status = error.response?.status;

    if (status && authErrorStatus.includes(status)) {
      store.dispatch('user/logout', undefined, { root: true });
    }

    return Promise.reject(error);
  });
};
