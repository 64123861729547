import axios from 'axios';

import config from '@/config';

export default {
  loadUnreadCount: (categoryHash: string): Promise<number | undefined> => (
    axios.get<{ count: number }>(`${config.versionedServerUrl()}/news/category/${categoryHash}/unread-count`)
      .then((res) => res.data.count)
      .catch(() => undefined)
  ),
};
