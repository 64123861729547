const config = {
  server: {
    baseUrl: process.env.VUE_APP_SERVER_PATH,
    versionPath: process.env.VUE_APP_SERVER_VERSION,
    tokenExpireSeconds: parseInt(process.env.VUE_APP_TOKEN_EXPIRE_SECONDS || '3540', 10),
  },

  socket: {
    baseUrl: process.env.VUE_APP_SOCKET_PATH,
    versionPath: process.env.VUE_APP_SOCKET_VERSION,
  },

  socketApi: {
    baseUrl: process.env.VUE_APP_SOCKET_API_PATH,
    versionPath: process.env.VUE_APP_SOCKET_API_VERSION,
  },

  chat: {
    maxUploadBytes: parseInt(process.env.VUE_APP_CHAT_MAX_UPLOAD_BYTES || '0', 10),
    maxMessageLength: parseInt(process.env.VUE_APP_CHAT_MAX_MESSAGE_LENGTH || '65000', 10),

    maxChatTitleLength: parseInt(process.env.VUE_APP_CHAT_TITLE_LENGTH || '250', 10),

    editableSeconds: parseInt(process.env.VUE_APP_CHAT_EDITABLE_SECONDS || '60', 10),
    deletableSeconds: parseInt(process.env.VUE_APP_CHAT_DELETABLE_SECONDS || '60', 10),

    userPageSize: parseInt(process.env.VUE_APP_CHAT_USER_PAGE_SIZE || '20', 10),
    userSearchDebounceMillis: parseInt(process.env.VUE_APP_CHAT_USER_SEARCH_DEBOUNCE_MILLIS || '300', 10),

    typingActiveDuration: parseInt(process.env.VUE_APP_CHAT_TYPING_ACTIVE_MILLIS || '5000', 10),
    typingDebounceMillis: parseInt(process.env.VUE_APP_CHAT_TYPING_DEBOUNCE_MILLIS || '300', 10),

    tutorialShowCount: parseInt(process.env.VUE_APP_CHAT_TUTORIAL_SHOW_COUNT || '3', 10),

    filePageSize: parseInt(process.env.VUE_APP_CHAT_FILE_PAGE_SIZE || '20', 10),
    messagePageSize: parseInt(process.env.VUE_APP_CHAT_MESSAGE_PAGE_SIZE || '20', 10),

    topicPrefix: process.env.VUE_APP_CHAT_TOPIC_PREFIX || 'social-intranet',
  },

  news: {
    maxCommentLength: parseInt(process.env.VUE_APP_NEWS_MAX_COMMENT_LENGTH || '65000', 10),
    commentEditableSeconds: parseInt(process.env.VUE_APP_NEWS_COMMENT_EDITABLE_SECONDS || '60', 10),
    commentDeletableSeconds: parseInt(process.env.VUE_APP_NEWS_COMMENT_DELETABLE_SECONDS || '60', 10),
  },

  versionedServerUrl: (): string => `${config.server.baseUrl}${config.server.versionPath}`,

  versionedSocketUrl: (): string => `${config.socket.baseUrl}${config.socket.versionPath}`,

  versionedSocketApiUrl: (): string => `${config.socketApi.baseUrl}${config.socketApi.versionPath}`,
};

export default config;
