import type { MessageAttachment } from '@/interfaces/chat/Message';
import type { SocketMessageAttachment } from '@/interfaces/shared/Socket';

const adapter = {
  fromSocket: (attachment?: SocketMessageAttachment): MessageAttachment => ({
    id: attachment?.id || '',
    filename: attachment?.filename || '',
    hasPreview: attachment?.hasPreview || false,
    creationTime: attachment?.createTs || 0,
    mimeType: attachment?.mimeType || 'application/octet-stream',
  }),
};

export default adapter;
