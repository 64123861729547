import * as mdijs from '@mdi/js';
import VueSplide from '@splidejs/vue-splide';
import mdiVue from 'mdi-vue/v2';
import VueClickOutside from 'v-click-outside';
import Vue from 'vue';
import VueSocketIO from 'vue-socket.io-extended';

import App from '@/App.vue';
import '@/assets/fonts.css';
import '@/assets/global.css';
import '@/assets/tailwind.css';
import bootstrap from '@/bootstrap';
import '@/events';
import '@/filters';
import http from '@/http';
import router from '@/router';
import socket from '@/socket';
import store from '@/store';

(async (): Promise<void> => {
  await bootstrap();

  http.initialize();

  Vue.use(mdiVue, {
    icons: mdijs,
  });

  Vue.use(VueClickOutside);
  Vue.use(VueSplide);

  Vue.use(VueSocketIO, socket, {
    debug: process.env.NODE_ENV === 'development',
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_',
    eventToActionTransformer: (input: string) => input,
    eventToMutationTransformer: (input: string) => input,
  });

  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
})();
