import {
  MenuArea,
  MenuItemType,
  SubmenuItemType,
} from '@/interfaces/menu/Menu';

import type {
  Menu,
  MenuItem,
  ServerMenu,
  ServerMenuItem,
  ServerSubmenu,
  Submenu,
} from '@/interfaces/menu/Menu';

const adapter = {
  fromServer: (menu?: ServerMenu): Menu => ({
    [MenuArea.main]: adapter.areaFromServer(menu?.[MenuArea.main]),
    [MenuArea.footer]: adapter.areaFromServer(menu?.[MenuArea.footer]),
    [MenuArea.social]: adapter.areaFromServer(menu?.[MenuArea.social]),
  }),

  areaFromServer: (area?: ServerMenuItem[]): Array<MenuItem|Submenu> => {
    if (!Array.isArray(area)) {
      return [];
    }

    return area.map((item) => {
      if (item.type === SubmenuItemType.submenu) {
        return adapter.submenuFromServer(item);
      }

      return adapter.itemFromServer(item);
    });
  },

  submenuFromServer: (item?: ServerSubmenu): Submenu => ({
    title: item?.title || '',
    type: SubmenuItemType.submenu,
    active: false,
    items: item?.items?.map(adapter.itemFromServer) || [],
  }),

  itemFromServer: (item?: ServerMenuItem): MenuItem => ({
    icon: item?.icon || undefined,
    title: item?.title || '',
    type: adapter.typeFromString(item?.type),
    active: false,
    data: item?.data || {},
  }),

  typeFromString: (type?: string): MenuItemType => (
    MenuItemType[<keyof typeof MenuItemType> type] || MenuItemType.home
  ),
};

export default adapter;
