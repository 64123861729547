import adaptRoom from '@/adapter/room';
import config from '@/config';
import { NewMessageEventBus } from '@/events/message';
import http from '@/http/chat/room';
import {
  RoomType,
  RoomView,
} from '@/interfaces/chat/Room';
import {
  SocketChatEmission,
  SocketChatEvent,
} from '@/interfaces/shared/Socket';
import socket from '@/socket';

import type { Message } from '@/interfaces/chat/Message';
import type {
  GroupRoom,
  GroupRoomCreated,
  GroupRoomEdited,
  GroupRoomInvited,
  Room,
  RoomTypingEnd,
  RoomTypingStart,
  SingleRoom,
  SingleRoomCreated,
  TeamRoom,
} from '@/interfaces/chat/Room';
import type {
  SocketRoom,
  SocketRoomReceiptPayload,
  SocketRoomTyping,
} from '@/interfaces/shared/Socket';
import type { RoomGetters, RoomState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): RoomState => ({
  currentRoomId: undefined,
  currentRoomView: RoomView.messages,
  creatingRoom: undefined,
  rooms: {},
  typing: {},
});

const RoomModule: Module<RoomState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    currentRoomId(state: RoomState): string | undefined {
      return state.currentRoomId;
    },

    currentRoom(state: RoomState, getter: RoomGetters): Room | undefined {
      if (!getter.currentRoomId) {
        return undefined;
      }

      return state.rooms[getter.currentRoomId];
    },

    currentRoomView(state: RoomState): RoomView {
      return state.currentRoomView;
    },

    creatingRoom(state: RoomState): RoomType | undefined {
      return state.creatingRoom;
    },

    rooms(state: RoomState): Room[] {
      return Object.values(state.rooms)
        .filter((room): room is Room => !!room)
        .sort((room1, room2) => (room2.lastMessageTime || 0) - (room1.lastMessageTime || 0));
    },

    chats(state: RoomState, getters: RoomGetters): SingleRoom[] {
      return getters.rooms.filter((room): room is SingleRoom => room.type === RoomType.single);
    },

    groups(state: RoomState, getters: RoomGetters): GroupRoom[] {
      return getters.rooms.filter((room): room is GroupRoom => room.type === RoomType.group);
    },

    teams(state: RoomState, getters: RoomGetters): TeamRoom[] {
      return getters.rooms.filter((room): room is TeamRoom => room.type === RoomType.team);
    },

    userIdsTypingInRoom(state: RoomState): (roomId: string) => string[] {
      return (roomId: string) => Object.keys(state.typing[roomId] || {});
    },

    chatsNotifications(state: RoomState, getters: RoomGetters): number {
      return getters.chats.reduce(
        (notifications, chat) => notifications + (chat.notifications || 0),
        0,
      );
    },

    groupsNotifications(state: RoomState, getters: RoomGetters): number {
      return getters.groups.reduce(
        (notifications, chat) => notifications + (chat.notifications || 0),
        0,
      );
    },

    teamsNotifications(state: RoomState, getters: RoomGetters): number {
      return getters.teams.reduce(
        (notifications, chat) => notifications + (chat.notifications || 0),
        0,
      );
    },

    allNotifications(state: RoomState, getters: RoomGetters): number {
      return getters.chatsNotifications + getters.groupsNotifications + getters.teamsNotifications;
    },

    hasNotifications(state: RoomState, getters: RoomGetters): boolean {
      return getters.allNotifications > 0;
    },
  },

  mutations: {
    clear(state: RoomState): void {
      Object.assign(state, initialState());
    },

    setCurrentRoomId(state: RoomState, roomId?: string): void {
      state.currentRoomId = roomId;
    },

    setCurrentRoomView(state: RoomState, roomView?: RoomView): void {
      state.currentRoomView = roomView || RoomView.messages;
    },

    setCreatingRoom(state: RoomState, roomType?: RoomType): void {
      state.creatingRoom = roomType;
    },

    updateRoom(state: RoomState, room: Room): void {
      const newRoom = { ...room };
      const oldRoom = state.rooms[room.id];

      if (oldRoom) {
        if (newRoom.notifications === undefined) {
          newRoom.notifications = oldRoom.notifications;
        }

        if (newRoom.lastReadMessageTime === undefined) {
          newRoom.lastReadMessageTime = oldRoom.lastReadMessageTime;
        }
      }

      state.rooms = {
        ...state.rooms,
        [newRoom.id]: newRoom,
      };
    },

    clearRoomNotifications(state: RoomState, roomId: string): void {
      const oldRoom = state.rooms[roomId];

      if (oldRoom) {
        state.rooms = {
          ...state.rooms,
          [oldRoom.id]: {
            ...oldRoom,
            notifications: 0,
          },
        };
      }
    },

    setRoomMessagesRead(state: RoomState, payload: { roomId: string; timestamp: number }): void {
      const oldRoom = state.rooms[payload.roomId];

      if (oldRoom) {
        state.rooms = {
          ...state.rooms,
          [oldRoom.id]: {
            ...oldRoom,
            lastReadMessageTime: Math.max(oldRoom.lastReadMessageTime || 0, payload.timestamp),
            notifications: 0,
          },
        };
      }
    },

    deleteRoomById(state: RoomState, roomId: string): void {
      delete state.rooms[roomId];

      state.rooms = {
        ...state.rooms,
      };
    },

    setUserTyping(state: RoomState, payload: RoomTypingStart): void {
      const typingState = state.typing[payload.roomId] || {};

      typingState[payload.userId] = payload.timeout;

      state.typing = {
        ...state.typing,
        [payload.roomId]: typingState,
      };
    },

    unsetUserTyping(state: RoomState, payload: RoomTypingEnd): void {
      const typingState = state.typing[payload.roomId];

      if (!typingState) {
        return;
      }

      delete typingState[payload.userId];

      state.typing = {
        ...state.typing,
        [payload.roomId]: typingState,
      };
    },

    addNotification(state: RoomState, payload: { message: Message; amount: number }): void {
      const oldRoom = state.rooms[payload.message.roomId];

      if (oldRoom) {
        state.rooms = {
          ...state.rooms,
          [oldRoom.id]: {
            ...oldRoom,
            notifications: (oldRoom.notifications || 0) + payload.amount,
          },
        };

        NewMessageEventBus.$emit('new-message', {
          message: payload.message,
          room: oldRoom,
        });
      }
    },
  },

  actions: {
    async clear(
      { commit }: ActionContext<RoomState, RootState>,
    ): Promise<void> {
      commit('clear');
    },

    async sendTyping(
      context: ActionContext<RoomState, RootState>,
      roomId: string,
    ): Promise<void> {
      socket.emit(SocketChatEmission.typing, roomId);
    },

    async createSingleRoom(
      { commit }: ActionContext<RoomState, RootState>,
      payload: SingleRoomCreated,
    ): Promise<Room> {
      return http.createSingleRoom(payload)
        .then((response) => adaptRoom.fromSocket(response))
        .then((room) => {
          if (!room.id) {
            throw new Error('Could not adapt single room');
          }

          commit('updateRoom', room);

          return room;
        });
    },

    async createGroupRoom(
      { commit }: ActionContext<RoomState, RootState>,
      payload: GroupRoomCreated,
    ): Promise<Room> {
      return http.createGroupRoom(payload)
        .then((response) => adaptRoom.fromSocket(response))
        .then((room) => {
          if (!room.id) {
            throw new Error('Could not adapt group room');
          }

          commit('updateRoom', room);

          return room;
        });
    },

    async editGroupRoom(
      context: ActionContext<RoomState, RootState>,
      payload: GroupRoomEdited,
    ): Promise<void> {
      return http.editGroupRoom(payload)
        .then(() => undefined);
    },

    async inviteToGroupRoom(
      context: ActionContext<RoomState, RootState>,
      payload: GroupRoomInvited,
    ): Promise<void> {
      return http.inviteToGroupRoom(payload)
        .then(() => undefined);
    },

    async leaveGroupRoom(
      context: ActionContext<RoomState, RootState>,
      room: GroupRoom,
    ): Promise<void> {
      return http.leaveGroupRoom(room.id)
        .then(() => undefined);
    },

    async closeGroupRoom(
      context: ActionContext<RoomState, RootState>,
      room: GroupRoom,
    ): Promise<void> {
      return http.closeGroupRoom(room.id)
        .then(() => undefined);
    },

    async sendRoomReceipt(
      context: ActionContext<RoomState, RootState>,
      payload: SocketRoomReceiptPayload,
    ): Promise<void> {
      return http.sendRoomReceipt(payload)
        .then(() => undefined);
    },

    async [SocketChatEvent.roomTyping](
      { commit, state }: ActionContext<RoomState, RootState>,
      payload?: SocketRoomTyping,
    ): Promise<void> {
      if (!payload || typeof payload !== 'object') {
        return;
      }

      if (typeof payload.userId !== 'string' || typeof payload.chatId !== 'string') {
        return;
      }

      const typing = state.typing[payload.chatId];

      if (typing?.timeout) {
        clearTimeout(typing.timeout);
      }

      const timeout = setTimeout(() => {
        commit('unsetUserTyping', {
          roomId: payload.chatId,
          userId: payload.userId,
        });
      }, config.chat.typingActiveDuration);

      commit('setUserTyping', {
        roomId: payload.chatId,
        userId: payload.userId,
        timeout,
      });
    },

    async [SocketChatEvent.roomDelete](
      { commit }: ActionContext<RoomState, RootState>,
      roomId?: unknown,
    ): Promise<void> {
      if (typeof roomId === 'string') {
        commit('deleteRoomById', roomId);
      }
    },

    async [SocketChatEvent.roomUpdate](
      { commit, dispatch }: ActionContext<RoomState, RootState>,
      payload?: SocketRoom,
    ): Promise<void> {
      const room = adaptRoom.fromSocket(payload);

      if (room.id) {
        commit('updateRoom', room);
        dispatch('avatar/refreshRoom', room, {
          root: true,
        });
      }
    },

    async [SocketChatEvent.roomsUpdate](
      { dispatch }: ActionContext<RoomState, RootState>,
      payload?: SocketRoom[],
    ): Promise<void> {
      if (Array.isArray(payload)) {
        payload.forEach((room) => {
          dispatch(SocketChatEvent.roomUpdate, room);
        });
      }
    },
  },
};

export default RoomModule;
