import axios from 'axios';

import config from '@/config';

import type {
  GroupRoomCreated,
  GroupRoomEdited,
  GroupRoomInvited,
  SingleRoomCreated,
} from '@/interfaces/chat/Room';
import type { SocketRoom, SocketRoomReceiptPayload } from '@/interfaces/shared/Socket';

export default {
  createSingleRoom: (payload: SingleRoomCreated):
  Promise<SocketRoom | undefined> => (
    axios.post<SocketRoom | undefined>(`${config.versionedSocketApiUrl()}/chat/single`, payload)
      .then((res) => res.data)
  ),

  createGroupRoom: (payload: GroupRoomCreated): Promise<SocketRoom | undefined> => (
    axios.post<SocketRoom | undefined>(`${config.versionedSocketApiUrl()}/chat/group`, payload)
      .then((res) => res.data)
  ),

  editGroupRoom: (payload: GroupRoomEdited): Promise<void> => (
    axios.put<void>(`${config.versionedSocketApiUrl()}/chat/group/${payload.id}`, {
      title: payload.title,
    }).then((res) => res.data)
  ),

  inviteToGroupRoom: (payload: GroupRoomInvited): Promise<void> => (
    axios.post<void>(`${config.versionedSocketApiUrl()}/chat/group/${payload.id}/invite`, {
      userIds: payload.userIds,
    }).then((res) => res.data)
  ),

  leaveGroupRoom: (roomId: string): Promise<void> => (
    axios.post<void>(`${config.versionedSocketApiUrl()}/chat/group/${roomId}/leave`)
      .then((res) => res.data)
  ),

  closeGroupRoom: (roomId: string): Promise<void> => (
    axios.post<void>(`${config.versionedSocketApiUrl()}/chat/group/${roomId}/close`)
      .then((res) => res.data)
  ),

  sendRoomReceipt: (payload: SocketRoomReceiptPayload): Promise<void> => (
    axios.post<void>(`${config.versionedSocketApiUrl()}/chat/receipt/${payload.roomId}`, {
      receiptTs: payload.receiptTs,
    }).then((res) => res.data)
  ),
};
