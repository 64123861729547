import contentAdapter from '@/adapter/content';

import type { JobBaseItem, JobDetailItem, JobListItem } from '@/interfaces/jobs/Jobs';

const adapter = {
  listFromServer: (list?: Partial<JobListItem>[]): JobListItem[] => (
    Array.isArray(list)
      ? list.map((item) => adapter.listItemFromServer(item))
      : []
  ),

  listItemFromServer: (item?: Partial<JobListItem>): JobListItem => ({
    ...adapter.baseItemFromServer(item),
    teaser: item?.teaser || '',
  }),

  detailsItemFromServer: (item?: Partial<JobDetailItem>): JobDetailItem => ({
    ...adapter.baseItemFromServer(item),
    contents: contentAdapter.contentsFromServer(item?.contents),
  }),

  baseItemFromServer: (item?: Partial<JobBaseItem>): JobBaseItem => ({
    hash: item?.hash || '',
    image: contentAdapter.imageFromServer(item?.image),
    publishDate: (item?.publishDate || 0) * 1000,
    read: item?.read || false,
    title: item?.title || '',
  }),
};

export default adapter;
