export enum MessageType {
  system = 'system',
  user = 'user',
}

export enum SystemMessageType {
  unknown = 'unknown',
  usersAddedOrRemoved = 'usersAddedOrRemoved',
  chatTitleChanged = 'chatTitleChanged',
  chatAvatarChanged = 'chatAvatarChanged',
  jobApprovalRejected = 'jobApprovalRejected',
  jobApprovalRequested = 'jobApprovalRequested',
  newsCommentAdded = 'newsCommentAdded',
}

export interface MessageBase {
  id: string;
  roomId: string;
  sent: number;
  received: number;
  sortTs: number;
  sortTsUpdate: number;
  edited: boolean;
  deleted: boolean;
  countsAsNotification: boolean;
}

export interface SystemMessageDataUsersAddedOrRemoved {
  type: SystemMessageType.usersAddedOrRemoved;
  addedUserIds: string[];
  removedUserIds: string[];
}

export interface SystemMessageDataAvatarChanged {
  type: SystemMessageType.chatAvatarChanged;
  hasAvatar: boolean;
}

export interface SystemMessageDataTitleChanged {
  type: SystemMessageType.chatTitleChanged;
  title: string;
}

export interface SystemMessageDataJobApprovalRejected {
  type: SystemMessageType.jobApprovalRejected;
  rejectingUserId: string;
  jobId: string;
}

export interface SystemMessageDataJobApprovalRequested {
  type: SystemMessageType.jobApprovalRequested;
  requestingUserId: string;
  jobId: string;
}

export interface SystemMessageDataNewsCommentAdded {
  type: SystemMessageType.newsCommentAdded;
  commentingUserId: string;
  news: string;
}

export interface SystemMessageDataUnknown {
  type: SystemMessageType.unknown;
}

export type SystemMessageData =
  SystemMessageDataUsersAddedOrRemoved
  | SystemMessageDataAvatarChanged
  | SystemMessageDataTitleChanged
  | SystemMessageDataJobApprovalRejected
  | SystemMessageDataJobApprovalRequested
  | SystemMessageDataNewsCommentAdded
  | SystemMessageDataUnknown;

export interface MessageFromSystem extends MessageBase {
  type: MessageType.system;
  data: SystemMessageData;
}

export interface MessageReactions {
  [emoji: string]: number | undefined;
}

export interface MessageAttachment {
  id: string;
  filename: string;
  hasPreview: boolean;
  creationTime: number;
  mimeType?: string;
}

export interface MessageFromUser extends MessageBase {
  type: MessageType.user;
  userId: string;
  parentId?: string;
  content: string;
  reactions: MessageReactions;
  answerCount?: number;
  attachments?: MessageAttachment[];
}

export type Message = MessageFromUser | MessageFromSystem;

export interface MessageList {
  [messageId: string]: Message;
}

export interface Messages {
  [roomOrMessageId: string]: MessageList;
}

export interface MessageReactionUpdate {
  messageId: string;
  reaction: string;
}

export interface MessageSent {
  id?: string;
  parentId?: string;
  chatId: string;
  content: string;
  sentTs: number;
  attachments?: string[];
}
