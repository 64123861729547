export interface LoginRequest {
  username: string;
  password: string;
  token?: string;
}

export interface Tokens {
  token: string;
  refreshToken: string;
}

export interface LoginResponse {
  id: string;
  token: string;
  refreshToken: string;
}

export enum LoginLocalState {
  refreshToken = 'state_login_refresh_token',
  token = 'state_login_token',
  userId = 'state_login_user_id',
}

export enum LoginError {
  credentials = 'credentials',
  twoFactorAuthRequired = 'twoFactorAuthRequired',
  twoFactorAuthInactive = 'twoFactorAuthInactive',
  other = 'other',
}

export enum Status {
  absent = 'absent',
  available = 'available',
  doNotDisturb = 'doNotDisturb',
  offline = 'offline',
}

export const statusList = [
  Status.absent,
  Status.available,
  Status.doNotDisturb,
  Status.offline,
];
