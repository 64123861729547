import contentAdapter from '@/adapter/content';
import tagAdapter from '@/adapter/tag';

import type {
  NewsArticleBaseItem,
  NewsArticleComment,
  NewsArticleDetailItem,
  NewsArticleListItem,
  NewsCategory,
  NewsTopic,
  NewsTopics,
} from '@/interfaces/news/News';

const adapter = {
  listFromServer: (list?: Partial<NewsArticleListItem>[]): NewsArticleListItem[] => (
    Array.isArray(list)
      ? list.map((item) => adapter.listItemFromServer(item))
      : []
  ),

  listItemFromServer: (item?: Partial<NewsArticleListItem>): NewsArticleListItem => ({
    ...adapter.baseItemFromServer(item),
    teaser: item?.teaser || '',
  }),

  detailsItemFromServer: (item?: Partial<NewsArticleDetailItem>): NewsArticleDetailItem => ({
    ...adapter.baseItemFromServer(item),
    contents: contentAdapter.contentsFromServer(item?.contents),
    likes: item?.likes || 0,
    comments: item?.comments || 0,
  }),

  baseItemFromServer: (item?: Partial<NewsArticleBaseItem>): NewsArticleBaseItem => ({
    authorId: item?.authorId || '',
    bookmarked: item?.bookmarked || false,
    topic: adapter.topicFromServer(item?.topic),
    category: adapter.categoryFromServer(item?.category),
    hash: item?.hash || '',
    image: contentAdapter.imageFromServer(item?.image),
    liked: item?.liked || false,
    publishDate: (item?.publishDate || 0) * 1000,
    weekNumber: item?.weekNumber || 0,
    read: item?.read || false,
    readingMinutes: item?.readingMinutes || 1,
    tags: tagAdapter.tagsFromServer(item?.tags),
    title: item?.title || '',
  }),

  commentsFromServer: (comments?: Partial<NewsArticleComment>[]): NewsArticleComment[] => (
    Array.isArray(comments)
      ? comments.map((category) => adapter.commentFromServer(category))
      : []
  ),

  commentFromServer: (comment?: Partial<NewsArticleComment>): NewsArticleComment => ({
    comment: comment?.comment || '',
    createdAt: (comment?.createdAt || 0) * 1000,
    hash: comment?.hash || '',
    userId: comment?.userId || '',
    isDeleted: comment?.isDeleted || false,
    isEdited: comment?.isEdited || false,
  }),

  topicsFromServer: (topics?: Partial<NewsTopic>[]): NewsTopics => {
    if (!Array.isArray(topics)) {
      return {};
    }

    return topics.reduce((data: NewsTopics, entry) => {
      const topic = adapter.topicFromServer(entry);

      return {
        ...data,
        [topic.hash]: topic,
      };
    }, {});
  },

  topicFromServer: (topic?: Partial<NewsTopic>): NewsTopic => ({
    hash: topic?.hash || '',
    title: topic?.title || '',
    categories: topic?.categories?.map(adapter.categoryFromServer) || [],
  }),

  categoryFromServer: (category?: Partial<NewsCategory>): NewsCategory => ({
    hash: category?.hash || '',
    isProtected: category?.isProtected || false,
    title: category?.title || '',
  }),
};

export default adapter;
