var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('router-link',{attrs:{"custom":"","to":_vm.mapLinkTarget(_vm.item)},scopedSlots:_vm._u([{key:"default",fn:function({ navigate, isActive }){return [_c('button',{staticClass:"relative overflow-hidden",class:{
      [_vm.activeColor]: isActive,
      [_vm.inactiveColor]: !isActive,
    },attrs:{"type":"button"},on:{"click":function($event){return _vm.onMenuItemClick($event, _vm.item, navigate)}}},[(_vm.isSubmenu)?_c('VariableWatcher',{attrs:{"variable":isActive},on:{"change":function($event){return _vm.updateActiveState($event)}}}):_vm._e(),(_vm.showChevrons)?_c('mdicon',{staticClass:"block w-6 h-6 mr-3",attrs:{"name":_vm.isExternal(_vm.item) ? 'openInNew' : 'chevronRight',"width":24,"height":24}}):_vm._e(),(_vm.isChat(_vm.item) && _vm.chatNotifications)?_c('Badge',{staticClass:"absolute right-0 top-1/2 -translate-y-1/2 mr-1 lg:-mt-2",class:{
        'lg:-mr-1 lg:translate-y-0': _vm.isSubmenu,
        'lg:-mr-2': !_vm.isSubmenu,
      },attrs:{"number":_vm.chatNotifications}}):_vm._e(),(_vm.isJobcenter(_vm.item) && _vm.jobsNotifications)?_c('Badge',{staticClass:"absolute right-0 top-1/2 -translate-y-1/2 mr-1 lg:-mt-2",class:{
        'lg:-mr-1 lg:translate-y-0': _vm.isSubmenu,
        'lg:-mr-2': !_vm.isSubmenu,
      },attrs:{"number":_vm.jobsNotifications}}):_vm._e(),(_vm.isNews(_vm.item) && _vm.newsUnreadCount)?_c('Badge',{staticClass:"absolute right-0 top-1/2 -translate-y-1/2 mr-1 lg:-mt-2",class:{
        'lg:-mr-1 lg:translate-y-0': _vm.isSubmenu,
        'lg:-mr-2': !_vm.isSubmenu,
      },attrs:{"number":_vm.newsUnreadCount}}):_vm._e(),_c('span',{staticClass:"relative inline-block break-words"},[_vm._v(_vm._s(_vm.item.title))])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }