import type { Bridge } from '@/bridge/app/Bridge';

declare global {
  interface Window {
    androidBridge: Bridge | undefined;
  }
}

export const isAndroid = (): boolean => !!window.androidBridge;

export const openAndroidBridge = (): Bridge => {
  const bridge = window.androidBridge;

  if (!bridge) {
    throw new Error('Expected android bridge to be defined');
  }

  return bridge;
};
