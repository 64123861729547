import axios from 'axios';

import config from '@/config';

import type { Content } from '@/interfaces/contents/Content';
import type { NewsTopic } from '@/interfaces/news/News';

export default {
  loadTopics: (): Promise<Partial<NewsTopic>[]> => (
    axios.get<Partial<NewsTopic>[]>(
      `${config.versionedServerUrl()}/news/topics/all`,
    ).then((res) => res.data)
  ),

  loadCategoryContents: (hash: string): Promise<Content[]> => (
    axios.get<{ contents?: Content[] }>(
      `${config.versionedServerUrl()}/news/category/${hash}`,
    ).then((res) => res.data?.contents || [])
  ),
};
