const adapter = {
  numberOrUndefined: (input: unknown): number | undefined => {
    const number = parseInt(`${input}`, 10);

    if (Number.isNaN(number)) {
      return undefined;
    }

    return number;
  },
};

export default adapter;
