import axios from 'axios';

import config from '@/config';

export default {
  getUnreadCount: (): Promise<number> => (
    axios.get<Partial<{ count: number }>>(`${config.versionedServerUrl()}/jobs/count/unread`)
      .then((res) => res.data.count || 0)
  ),

  markJobRead: (hash: string): Promise<void> => (
    axios.post(`${config.versionedServerUrl()}/jobs/posting/${hash}/read`)
  ),

  forceJobRead: (hash: string): Promise<void> => (
    axios.post(`${config.versionedServerUrl()}/jobs/posting/${hash}/read-marker`)
  ),

  forceJobUnread: (hash: string): Promise<void> => (
    axios.delete(`${config.versionedServerUrl()}/jobs/posting/${hash}/read-marker`)
  ),
};
