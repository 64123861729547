var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"fixed inset-0 flex items-center justify-center w-full h-full z-20",class:{
      'p-10': _vm.borderless,
      'p-4': !_vm.borderless,
    },on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeIfAllowed()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;$event.preventDefault();return _vm.closeIfAllowed()}}},[_c('span',{staticClass:"absolute inset-0 bg-gray-900 opacity-25 pointer-events-none",attrs:{"aria-hidden":"true"}}),_c('div',{staticClass:"relative flex max-h-full",class:{
        [_vm.width]: true,
        'w-full shadow-lg bg-white': !_vm.borderless,
      }},[(_vm.closable)?_c('button',{ref:"closeButton",staticClass:"absolute top-0 right-0 p-2",class:{ 'bg-white opacity-75 rounded-full -mr-5 -mt-5': _vm.borderless },attrs:{"type":"button","title":"Dialog schließen"},on:{"click":function($event){return _vm.close()}}},[_c('mdicon',{attrs:{"name":"close","width":24,"height":24}})],1):_vm._e(),_c('div',{staticClass:"max-h-full w-full overflow-y-auto scrolling-touch"},[(_vm.title)?_c('div',{staticClass:"pl-4 pr-10 py-2 border-b border-gray-400 bg-gray-300 font-semibold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{class:{
            'pt-8': !_vm.title && !_vm.borderless,
            'p-4': !_vm.borderless,
          }},[_vm._t("default")],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }