

import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Prop({ default: true }) public closable!: boolean;

  @Prop({ default: false }) public dismissable!: boolean;

  @Prop({ default: false }) public borderless!: boolean;

  @Prop({ default: '' }) public title!: string;

  @Prop({ default: 'max-w-2xl' }) public width!: string;

  public mounted(): void {
    document.documentElement.classList.add('overflow-hidden');

    if (this.$refs?.closeButton instanceof HTMLElement) {
      this.$refs.closeButton.focus();
    }

    const closeHandler = (event: KeyboardEvent): void => {
      if (this.closable && event.code === 'Escape') {
        this.close();
      }
    };

    document.addEventListener('keydown', closeHandler);

    this.$once('hook:destroyed', () => {
      document.documentElement.classList.remove('overflow-hidden');
      document.removeEventListener('keydown', closeHandler);
    });
  }

  public close(): void {
    this.$emit('close');
  }

  public closeIfAllowed(): void {
    if (this.closable && this.dismissable) {
      this.close();
    }
  }
}
