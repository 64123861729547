import { isAndroid, openAndroidBridge } from '@/bridge/app/AndroidBridge';
import { isIos, openIosBridge } from '@/bridge/app/IosBridge';
import { openWebBridge } from '@/bridge/app/WebBridge';

import type { Bridge } from '@/bridge/app/Bridge';

let bridge: Bridge | undefined;

export const getAppBridge = (): Bridge => {
  if (bridge) {
    return bridge;
  }

  if (isAndroid()) {
    return openAndroidBridge();
  }

  if (isIos()) {
    return openIosBridge();
  }

  return openWebBridge();
};

export default getAppBridge;
