import { Status } from '@/interfaces/shared/User';

import type { RoomUser } from '@/interfaces/chat/Room';
import type { SocketUser } from '@/interfaces/shared/Socket';
import type { LoginResponse } from '@/interfaces/shared/User';

const adapter = {
  fromSocket: (user?: SocketUser): RoomUser => ({
    id: user?.id || '',
    email: user?.email || undefined,
    name: adapter.nameFromUser(user),
    firstName: adapter.trimmedName(user?.firstName),
    lastName: adapter.trimmedName(user?.lastName),
    initials: adapter.initials(user),
    status: adapter.statusFromString(user?.status),
    hasAvatar: user?.hasAvatar || false,
    isDeleted: false,
    isDisabled: user?.isDisabled === true,
    isChatDisabled: user?.isChatDisabled || false,
    notifications: user?.notifications || 0,
  }),

  loginFromServer: (response?: Partial<LoginResponse>): LoginResponse | undefined => {
    if (!response?.id || !response?.token || !response?.refreshToken) {
      return undefined;
    }

    return {
      id: response.id,
      token: response.token,
      refreshToken: response.refreshToken,
    };
  },

  nameFromUser(user?: SocketUser): string {
    if (user) {
      if (user.displayName && user.displayName !== 'null') {
        return user.displayName;
      }

      if (user.firstName || user.lastName) {
        return `${user.firstName || ''} ${user.lastName || ''}`.trim();
      }
    }

    return 'Unbekannter Nutzer';
  },

  trimmedName: (name?: string): string => (
    (name || '').trim()
  ),

  initials: (user?: SocketUser): string => {
    if (!user) {
      return '';
    }

    if (user.firstName && user.lastName) {
      return `${adapter.initial(user.firstName)}${adapter.initial(user.lastName)}`;
    }

    if (user.displayName && user.displayName !== 'null') {
      const initials = user.displayName.match(/\b\w/g) || [];

      if (initials.length >= 2) {
        return `${adapter.initial(initials.shift())}${adapter.initial(initials.pop())}`;
      }
    }

    return '';
  },

  initial: (name?: string): string => {
    const initial = (name || '').split('').shift() || '';

    return initial.toUpperCase();
  },

  statusFromString: (status?: string): Status => (
    Status[<keyof typeof Status> status] || Status.offline
  ),
};

export default adapter;
