import adaptNews from '@/adapter/news';
import http from '@/http/news/list';

import type {
  NewsArticleListItem,
  SetArticleBookmarkedPayload,
  SetArticleLikedPayload,
  SetArticleReadPayload,
} from '@/interfaces/news/News';
import type { RootState, TopNewsState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): TopNewsState => ({
  isLoading: false,
  hasError: false,
  topNews: undefined,
});

const NewsTopNewsModule: Module<TopNewsState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    isLoading: (state: TopNewsState): boolean => state.isLoading,

    hasError: (state: TopNewsState): boolean => state.hasError,

    topNews: (state: TopNewsState): NewsArticleListItem | undefined => state.topNews,
  },

  mutations: {
    clear(state: TopNewsState): void {
      Object.assign(state, initialState());
    },

    setArticleBookmarked(state: TopNewsState, payload: SetArticleBookmarkedPayload): void {
      if (state.topNews?.hash === payload.hash) {
        state.topNews = {
          ...state.topNews,
          bookmarked: payload.bookmarked,
        };
      }
    },

    setArticleLiked(state: TopNewsState, payload: SetArticleLikedPayload): void {
      if (state.topNews?.hash === payload.hash) {
        state.topNews = {
          ...state.topNews,
          liked: payload.liked,
        };
      }
    },

    setArticleRead(state: TopNewsState, payload: SetArticleReadPayload): void {
      if (state.topNews?.hash === payload.hash) {
        state.topNews = {
          ...state.topNews,
          read: payload.read,
        };
      }
    },

    setLoading(state: TopNewsState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

    setHasError(state: TopNewsState, hasError: boolean): void {
      state.hasError = hasError;
    },

    setTopNews(state: TopNewsState, topNews: NewsArticleListItem | undefined): void {
      state.topNews = topNews;
    },
  },

  actions: {
    async clear({ commit }: ActionContext<TopNewsState, RootState>): Promise<void> {
      commit('clear');
    },

    async loadTopNews({ commit }: ActionContext<TopNewsState, RootState>): Promise<void> {
      commit('setLoading', true);
      commit('setHasError', false);
      commit('setTopNews', undefined);

      await http.loadTopNews()
        .then((response) => {
          commit('setTopNews', adaptNews.listItemFromServer(response));
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            commit('setHasError', true);
          }
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
  },
};

export default NewsTopNewsModule;
