
import { Component, Prop, Vue } from 'vue-property-decorator';

import RoomFormatter from '@/utils/room-formatter';

import type { Room } from '@/interfaces/chat/Room';

@Component
export default class RoomName extends Vue {
  @Prop() public room?: Room;

  public get title(): string {
    if (!this.room) {
      return '';
    }

    return RoomFormatter.getName(this.room);
  }

  public get name(): string {
    if (!this.room) {
      return '';
    }

    return RoomFormatter.getName(this.room, 2);
  }

  public get moreUsers(): number {
    if (!this.room) {
      return 0;
    }

    return RoomFormatter.getAdditionalUserCount(this.room);
  }

  public get showMoreUsers(): boolean {
    if (!this.room || this.room.title) {
      return false;
    }

    return this.moreUsers > 0;
  }
}
