import type {
  DraftFileUploadFailReason,
  Drafts,
  DraftUpload,
} from '@/interfaces/chat/Draft';

export default class DraftUpdate {
  private draftKey: string;

  private drafts: Drafts;

  private message = '';

  private uploads: DraftUpload[] = [];

  constructor(drafts: Drafts, draftKey: string) {
    if (!draftKey) {
      throw new Error('No draft key set');
    }

    this.draftKey = draftKey;
    this.drafts = drafts;
    this.message = drafts[this.draftKey]?.message || '';
    this.uploads = drafts[this.draftKey]?.uploads || [];
  }

  public setMessage(message?: string): DraftUpdate {
    if (message) {
      this.message = message;
    }

    return this;
  }

  public addFiles(files?: File[]): DraftUpdate {
    if (files) {
      const newUploads = files.map((file) => ({
        file,
        status: {
          progress: 0,
          failed: false,
        },
      }));

      this.uploads = [...this.uploads, ...newUploads];
    }

    return this;
  }

  public removeFiles(files?: File[]): DraftUpdate {
    if (files?.length) {
      this.uploads = this.uploads.filter((upload) => !files.includes(upload.file));
    }

    return this;
  }

  public setUploadProgress(file?: File, progress?: number): DraftUpdate {
    const upload = this.findFileInUploads(file);

    if (upload) {
      upload.status.progress = progress || 0;
    }

    return this;
  }

  public setUploadReference(file?: File, reference?: string): DraftUpdate {
    const upload = this.findFileInUploads(file);

    if (upload) {
      upload.status.reference = reference;
    }

    return this;
  }

  public setUploadFailed(file?: File, reason?: DraftFileUploadFailReason): DraftUpdate {
    const upload = this.findFileInUploads(file);

    if (upload) {
      upload.status.failed = true;
      upload.status.failReason = reason;
    }

    return this;
  }

  public resetUploads(files?: File[]): DraftUpdate {
    if (files) {
      files.forEach((file) => {
        const upload = this.findFileInUploads(file);

        if (upload && !upload.status.reference) {
          upload.status.failed = false;
          upload.status.progress = 0;
        }
      });
    }

    return this;
  }

  public build(): Drafts {
    return {
      ...this.drafts,
      [this.draftKey]: {
        uploads: this.uploads,
        message: this.message,
      },
    };
  }

  private findFileInUploads(file?: File): DraftUpload | undefined {
    if (file) {
      return this.uploads.find((entry) => file === entry.file);
    }

    return undefined;
  }
}
