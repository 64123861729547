import type { DragState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): DragState => ({
  dragging: false,
});

const DragModule: Module<DragState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    dragging(state: DragState): boolean {
      return state.dragging;
    },
  },

  mutations: {
    clear(state: DragState): void {
      Object.assign(state, initialState());
    },

    setDragging(state: DragState, dragging: boolean): void {
      state.dragging = dragging;
    },
  },

  actions: {
    async clear({ commit }: ActionContext<DragState, RootState>): Promise<void> {
      commit('clear');
    },
  },
};

export default DragModule;
