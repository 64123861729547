import { SocketChatEmission, SocketChatEvent } from '@/interfaces/shared/Socket';
import socket from '@/socket';
import attachment from '@/store/chat/attachment';
import draft from '@/store/chat/draft';
import message from '@/store/chat/message';
import room from '@/store/chat/room';
import emoji from '@/store/emoji';

import type { ChatView } from '@/interfaces/chat/Chat';
import type { ChatState, RootState } from '@/interfaces/Store';
import type { ActionContext, Module } from 'vuex';

const initialState = (): ChatState => ({
  currentChatView: undefined,
  showMobileSearch: false,
  tutorialShownCount: Infinity,
});

const modules = {
  attachment,
  draft,
  emoji,
  message,
  room,
};

const ChatModule: Module<ChatState, RootState> = {
  namespaced: true,
  state: initialState,

  getters: {
    currentChatView(state: ChatState): ChatView | undefined {
      return state.currentChatView;
    },

    showMobileSearch(state: ChatState): boolean {
      return state.showMobileSearch;
    },

    tutorialShownCount(state: ChatState): number {
      return state.tutorialShownCount;
    },
  },

  mutations: {
    clear(state: ChatState): void {
      Object.assign(state, initialState());
    },

    setCurrentChatView(state: ChatState, chatView?: ChatView): void {
      state.currentChatView = chatView;
    },

    setShowMobileSearch(state: ChatState, showMobileSearch: boolean): void {
      state.showMobileSearch = showMobileSearch;
    },

    setTutorialShownCount(state: ChatState, count: number): void {
      state.tutorialShownCount = count;
    },
  },

  actions: {
    async [SocketChatEvent.tutorialShowCount](
      { commit }: ActionContext<ChatState, RootState>,
      count?: unknown,
    ): Promise<void> {
      if (typeof count === 'number') {
        commit('setTutorialShownCount', count);
      }
    },

    async setTutorialShown(): Promise<void> {
      socket.emit(SocketChatEmission.tutorialShown);
    },

    async clear({ commit, dispatch }: ActionContext<ChatState, RootState>): Promise<void> {
      commit('clear');

      Object.keys(modules).forEach((module) => (
        dispatch(`${module}/clear`)
      ));
    },
  },

  modules,
};

export default ChatModule;
