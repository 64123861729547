
import { Component, Vue, Prop } from 'vue-property-decorator';

import AvatarFallback from '@/components/shared/badge/AvatarFallback.vue';
import Badge from '@/components/shared/badge/Badge.vue';
import StatusIcon from '@/components/shared/StatusIcon.vue';

import type { MaybeAvatar } from '@/interfaces/shared/Avatar';
import type { Status } from '@/interfaces/shared/User';

@Component({
  components: {
    AvatarFallback,
    Badge,
    StatusIcon,
  },
})
export default class AvatarBadge extends Vue {
  @Prop() public initials!: string[];

  @Prop() public title!: string;

  @Prop() public avatars!: MaybeAvatar[];

  @Prop() public status?: Status;

  @Prop({ default: 0 }) public notifications!: number;

  private maxImageCount = 3;

  public get loadingAvatars(): boolean {
    return this.avatars.some((avatar) => avatar === undefined);
  }

  public get displayAvatars(): MaybeAvatar[] {
    return this.avatars.slice(0, this.maxImageCount);
  }

  public get avatarInitials(): string[] {
    return this.initials
      .map((initial) => (initial.length ? initial : '×'));
  }
}
